import { memo } from "react"
import styled from "styled-components"
import COLORS from "../../utils/colors"
//import LoadingIcon from "../../assets/images/loading-icon.png"
import LoadingIcon from "../../assets/images/loading-icon.png"
import Image from "../image"

const Loading = (props) => {
  if (!props.show) {
    return null
  }
  return (
    <>
      <Element>
        <ImageWrapper className='rotate'>
          <Image width={"100px"} height={"100px"} src={window.location.origin + '/loading-icon.png'} />
        </ImageWrapper>
      </Element>
    </>
  )
}

const Element = styled.div`
  max-width: 100%;
  z-index: 1000000;
  position: fixed;
  display: flex;
  text-align: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.7;
  background: ${COLORS.grey};
`
const ImageWrapper = styled.div`
  max-width: 100%;
  z-index: 1000000;
  position: fixed;
  display: flex;
  text-align: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export default Loading
