import React from "react"
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import COLORS from "../../utils/colors"
import "../../assets/css/style.css"
import $ from 'jquery';
function Pagination(props) {
  let pageCount = Math.ceil(props.totalRecords / props.recordsPerPage)
  // $( ".PerPageSelect" ).change(function(e) {
	  // pageCount = Math.ceil(props.totalRecords / props.recordsPerPage)
    // props.onChangeRecordPerpage(e.target.value, pageCount)
	// });
  const onPageChange = ({ selected }) => {
    props.getPageData(selected + 1, props.recordsPerPage)
  }
  const onPageChange2 = (e) => {
	  props.onChangeRecordPerpage(e.target.value, pageCount)
  }
  return (
    <div>
      <Wrapper>
        <WrapperPagination>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </WrapperPagination>
        <WrapperSelect>
          <Para>Items Perpage:</Para>
          <Select className="PerPageSelect"
            onChange={(e)=>onPageChange2(e)}
            value={props.recordsPerPage ? props.recordsPerPage : 10}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </Select>
        </WrapperSelect>
      </Wrapper>
    </div>
  )
}

export default Pagination

const Wrapper = styled.div`
  position: relative;
  float:left;
  width:100%;

`
const WrapperPagination = styled.div`
margin-left:-20px;
`

const Select = styled.select`
border: 1px solid rgba(210, 210, 210, 1);
border-radius: 7px;
padding: 4px 18px 5px 9px;
font-size: 12px;
font-weight: 500;
color: rgba(136, 136, 136, 1);
margin: 0 15px;
margin-top: -5px;
`
const WrapperSelect = styled.div`
display: flex;
float: right;
margin-top: -67px;
margin-right:0px;
`
const Para = styled.div`
color: #868686;
font-size: 12px;
font-weight: 500;
`
