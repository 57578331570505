import React from "react"
import styled from "styled-components"
import { Link, useLocation } from "react-router-dom"
import COLORS from "../../utils/colors"

const SidebarItem = (props) => {
  const location = useLocation() 
  function onClickHandler(){
    if(props.onClick){
      props.onClick();
    }
  }
  return (
    <Wrapper >
      <Link className={`sidebar_item ${location.pathname === props.route ? 'active' : ''}`} onClick={onClickHandler} to={props.route}>
        <Icon>{props.source}</Icon>
        <Text>{props.name}</Text>
        <SelectedBorder className="selected" />
      </Link>
    </Wrapper>
  )
}

export default SidebarItem

const Icon = styled.svg`
  margin-top: 7px;
  fill: white;
  margin-left: 15px;
  margin-right: 5px;
  width: 40px;
`

const Text = styled.label`
  padding: 0.5rem 0;
  text-align: initial;
  font-size: 14px;
  width:100%;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  color: ${COLORS.white};
  font-size: 20px;
  margin-bottom: 10px;
`
const SelectedBorder = styled.span`
  width: 8px;
  height: 44px;
  background: ${COLORS.lightblue};
  border-radius: 5px 0px 0px 5px; ;
  display: none;
`
