import React, { useEffect, useState } from "react";
import { getManipulatorHistory } from "./../../../services/dataValidation";
import { getUserID } from "./../../../utils/helper";
import TopNav from "./../../../components/navbar/topNav";
import Table from "./../../../components/navbar/tableItems/history/table";
import styled from "styled-components";
import Search from "./../../../components/search";
import Pagination from "./../../../components/pagination";
import Loading from "./../../../components/loading";
import "./../../../assets/css/style.css";
import Modal from "./../../../components/lead-modal";
import FavIcon from "./../../../components/favicon";
import COLORS from "./../../../utils/colors";
import Button from "./../../../components/button";
import tw from "twin.macro";
import Select from "react-select";
import {useParams} from "react-router-dom";
import $ from 'jquery';
const History = (props) => {
  let { id } = useParams();
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([]);
  const [noOfPages, setNoofPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [getTotalExcelData, setTotalExcelData] = useState(false);
  const [show, setShow] = useState(false);
  const [filedata, setfiledata] = useState(false);

  useEffect(() => {
    ManipulatorHistory(noOfPages, recordsPerPage);
  }, []);

  const onFileChange = (event) => {
    setShow(true);
  };
  function ManipulatorHistory(noOfPages, recordsPerPage) {
    setisLoading(true);
    const user = getUserID();
    getManipulatorHistory(
      {
        file_id: id,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false);
        setTotalRecords(response.total_records);
        setFilteredUserExcelData(response.data);
        setTotalExcelData(response.data.length);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue);
    setNoofPages(pageCount);
    ManipulatorHistory(1, perPageValue);
  }

  
  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false);
        }}
        show={show}
        fields={filedata}
      /> 
      <Loading show={isLoading} />
      <TopNav />
	 
      <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={ManipulatorHistory}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}
        ></Pagination>
      </WrapperPagination>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const SearchWrapper = styled.div`
  margin-top: 2%;
`;
const WrapperPagination = styled.div`
  width: 98%;
`;

const Upload = styled.input`
  display: none;
  cursor: pointer;
`;
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
  float: left;
  padding: 10px 10px 0px 10px;
`;
const Label = styled.label`
  margin-left: 20px;
  font-size: 18px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: "Montserrat";
`;

const MainButton = styled.div`
  float: left;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 155px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 44px;
  cursor: pointer;
  margin-right: 10px;

  ${tw`
    shadow-lg
  `}
`;
const SearchIcon = styled.span`
  position: absolute;
  color: ${COLORS.grey};
  display: block;
  padding: 10px 0px 0px 10px;
  margin-left: 5px;
  color: ${COLORS.grey};
  min-width: 50px;
`;
const Input = styled.input`
  width: 85%;
  height: 45px;
  float: left;
  margin-right: 10px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 40px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`;
export default History;
