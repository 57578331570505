import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {getManipulatorContacts } from "../../services/dataValidation"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Component from './../creatable-select';
import $ from 'jquery';
import {useParams} from "react-router-dom";
const SaveFilter=(props)=> {
  const [updatePreset, setupdatePreset] = useState(false);
  const [downloadValue, setdownloadValue] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [PresetValue, setPresetValue] = useState(0);  
  let { fileID } = useParams();
  if(!props.show){ return null;}
function saveFilterCall(){ 
	   setisLoading(true);
		let Filter = {};
		 const FilterName = $(".fiterSearchInput").val();
		 let i  =0;
		$('.row.filters-2-main .autocomplete-wrapper').each(function() {
		const name = $(this).attr("data");	
		  if(name!=undefined){
             const ID = $(this).attr("id");
              const Condition = $('.'+ID+' :selected').val();
			  Filter[i] ={'name':name, condition:Condition, value:$(this).find("input").val()}; i++; }
		});

		$('.row.filters-2-main input[type="text"]').each(function() {
		const name = $(this).attr("name");
		  if(name!=undefined){
             const ID = $(this).attr("id");
              const Condition = $('.'+ID+' :selected').val();
			  Filter[i] ={'name':name, condition:Condition, value:$(this).val()}; i++; }
		});
		
		$('.row.filters-2-main .staticfields').each(function() {
		const name = $(this).attr("name");	
		  if(name!=undefined){
             const ID = $(this).attr("id");
             const Condition = $('.'+ID+' :selected').val();
			  Filter[i] ={'name':name, condition:Condition, value:$(this).val()}; i++; }
		});
		if(props.presetType==true) { let data = { file_id: fileID, search_type:'preset', filter_id: props.filterID, save_filter: 'NO', filter_name:FilterName, filters:Filter }
			getManipulatorContacts(data)
			.then(function (response) {
				setisLoading(false);
				toast.success("Filter Saved."); 
			   setTimeout(function () { window.location.reload(false); }, 1000);
			  })
		
		} else {  let data  = { file_id: fileID, search_type:'filter', save_filter: 'YES',filter_name:FilterName, filters:Filter}

       	   getManipulatorContacts(data)
			.then(function (response) {
				setisLoading(false);
				toast.success("Filter Saved."); 
				setTimeout(function () { window.location.reload(false); }, 1000); 
			  })
		} 

}

 const onInputchange =(event) => {
	 $("input.fiterSearchInput").val(event.target.value);
  } 
  //$("input.fiterSearchInput").val(props.filterName);
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="tag-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>
			Save Filter
			</Para2>
          </ModalTitle>
          <WrapperBody>
		    

		    <div className="lead-input-container">
				<input type="text" name="fiterSearchInput" defaultValue={props.filterName} className="fiterSearchInput" onChange={onInputchange}/>
			</div>
          </WrapperBody>
          <WrapperFooter>
			<ModalButtonSubmit onClick={(e) => saveFilterCall()}>Submit</ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default SaveFilter;

const WrapperModal=styled.div`
position:fixed;
width:367px;
min-height:200px;
left:535px;
top:40px;
z-index:1;
display:flex;
z-index:9999
`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width: 367px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 20px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;


`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const ModalButton = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
font-weight: bold;
    position: relative;
    width: 100%;
    top: 28px;
    text-align: center;
    max-width: 312px;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;

`;