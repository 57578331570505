import React, { useEffect, useState } from "react"
import styled from "styled-components"
import FavIcon from "../../favicon"
import Button from "../../../components/button"
import { useHistory } from "react-router"
import COLORS from "../../../utils/colors"
import { getUserNotifications, clearAllnotification } from "../../../services/dataValidation"
import { getUserID } from "../../../utils/helper"
import { Link } from "react-router-dom"
import Notification from "../../../components/download-notification"
import $ from 'jquery';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
const Profile = (props) => {
  const [notificationCount, setNotificationCount] = useState([])
  const [notificationmsg, setNotificationMsgt] = useState([])	  
  let history = useHistory()
  let displayNameobj = JSON.parse(localStorage.getItem("userData"));
  let displayName = displayNameobj.first_name+' '+displayNameobj.last_name;
  let show = true;
  const user = getUserID()
	  
  useEffect(() => {
    getNoticationFun()
  }, []);  
    function getNoticationFun (){
	  getUserNotifications({
		  user_id: user,
		  
		})
      .then(function (response) {
		  setNotificationCount(response.total_unread_notification);
		  setNotificationMsgt(response.data);
      })
      .catch((error) => {
        console.log("error", error)
      })
	}
  function onLogout() {
    localStorage.clear()
    history.push("/")
    window.location.reload()
  }
  

  const myNotification = [];
  let NotificationClass = "";
  if(notificationmsg!=null){
  for (let i = 0; i < notificationmsg.length; i++) { 
    if (i === 0) { NotificationClass = "even"; } else if (i % 2 === 0) { NotificationClass = "even"; } else { NotificationClass = "odd"; }
    myNotification.push(<Notification key={i} id={i} data={notificationmsg[i]} NotificationClass={NotificationClass}/>);
  }
  }

  var alertIcon;
  if(notificationCount>0){
	  alertIcon = <div class="alertIcon">{notificationCount}</div>
  } else {   alertIcon = "";  show =false;}
  
  const clearALL = (e) => {
	  clearAllnotification({
		  action: "clear-notification"
		})
      .then(function (response) {
		if(response.status==true) {toast.success(response.message); 
				  setNotificationCount(0);
		  setNotificationMsgt([]);
		} else { toast.error(response.message); } 
      })
      .catch((error) => {
        console.log("error", error)
      })	  
  }
  return (
    <Wrapper>
      <InnerWrapper>
        <Figer>
          <figure tabIndex='-1' className="notificationBell">
            <FaIcon>
              <FavIcon class='fas fa-bell' />{alertIcon}
            </FaIcon>
            
            <HiddenDiv className='menu notification'>
              <notificationBtn>
                <p className="noticationHead">Notifications { show && <button className="clear_notification" onClick={clearALL}>Clear All</button> }</p>
			   { show &&
				<div className="notification-container">
				  {myNotification.map(name => (
					<React.Fragment>
					  {name}
					</React.Fragment>
				  ))}	
			   </div>	}
					{ show && <div className="NotificationViewAll"><Link to ="/downloads"> All Downloads</Link></div>	 }
              </notificationBtn>
			 
            </HiddenDiv>
          </figure>
        </Figer>

        <Figer>
          <figure tabIndex='-1'>
            <Icon src={displayNameobj.profile_picture}  className="ProfileImg"/>

            <HiddenDiv className='menu profile_section'>
			  <Link to ="/profile" className="edit_profile_btn ProfileImg">Edit Profile</Link>
              <Btn>
                <Button style={"btn-primary"} onClick={onLogout}>
                  LOGOUT
                </Button>
              </Btn>
            </HiddenDiv>
          </figure>
        </Figer>
		
        <Figer>
          <figure tabIndex='-1'>
             <Para className="userName">{displayName}</Para>

            <HiddenDiv className='menu profile_section'>
			  <Link to ="/profile" className="edit_profile_btn ProfileImg">Edit Profile</Link>
              <Btn>
                <Button style={"btn-primary"} onClick={onLogout}>
                  LOGOUT
                </Button>
              </Btn>
            </HiddenDiv>
          </figure>
        </Figer>		

      
      </InnerWrapper>
    </Wrapper>
  )
}

export default Profile

const Wrapper = styled.div``

const notificationBtn = styled.div`
  margin: auto;
  padding-top: 15px;
`
const Btn = styled.div`
  margin: auto;
  padding-top: 15px;
  padding: 0px 20px;
`
const Figer = styled.figure`
  z-index: 1;
  margin-top: 20px;
  figure:active .menu,
  figure:focus .menu {
    visibility: visible;
  }
`
const HiddenDiv = styled.div`
  visibility: Hidden;
  width: 300px;
  position: absolute;
  right: 20px;
  background-color: rgb(252, 248, 248);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 20px;
`
const InnerWrapper = styled.div`
  display: flex;
  z-index:2;
  position: relative;
`
const FaIcon = styled.span`
  padding-top: 30px;
  color: ${COLORS.grey};
  font-size: 24px;
  margin-right: 35px;
  margin-top: 30px;
`

const Icon = styled.img`
  width: 53px;
  height: 53px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
`
const Para = styled.p`
  color: #3c3c3c;
  font-family: Roboto;
  font-weight: normal;
  margin-left: 10px;
  padding-top: 10px;
  font-size: 15px;
  font-weight:700;
  cursor: pointer;
  min-width:60.95px;
`
