import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { contactMappingPost, getAvailbleCreadit} from "./../../../services/dataValidation"
import TopNav from "./../../../components/navbar/topNav"
import FavIcon from "./../../../components/favicon"
import styled from "styled-components"
import Loading from "./../../../components/loading"
import "./../../../assets/css/style.css"
import RangeSlider from "./../../../components/rangslider"
import COLORS from "./../../../utils/colors"
import tw from "twin.macro"
import { getUserID } from "./../../../utils/helper"
import Button from "./../../../components/button";
import { useHistory } from "react-router-dom"
import Select from 'react-select'
import $ from 'jquery';
import {toast} from 'react-toastify';

const  MapContact= () => {

const [isLoading, setisLoading] = useState(false)
const [availableCreadit, setAvailableCreadit] = useState();
let history = useHistory()
  useEffect(() => {
    getAvailbleCreadit2()  }, []);
    function getAvailbleCreadit2() {
		const userID = getUserID()
		getAvailbleCreadit({ user_id: userID})
		  .then(function (response) {
			  setAvailableCreadit(state => (response.balance_credit));
		  })
		  .catch((error) => {
			alert("Some error occured")
			console.log("file upload error: ", error)
		  })
	}

	  
     function  MappingPhone(){
         setisLoading(true);	
           const MappedData =  JSON.parse(localStorage.getItem("phoneMapping"));
		   const phoneMappedFields =  JSON.parse(localStorage.getItem("phoneContactFields"));
			contactMappingPost(
				  {
					filelist_id: MappedData.filelist_id,
					contact_mapping:[phoneMappedFields]
				  }
				)
				  .then(function (response) {
					setisLoading(false);
					if(response.status==false){
					   toast.error(response.message);
					   setisLoading(false);
				  } else  {
					localStorage.setItem("reportData", JSON.stringify(response));
					history.push('revamp-report');	
					//setShow4(true);
				    //setmappingFields(response);	
				  }					
				  })
				  .catch((error) => {
					console.log("error", error)
				  })		
		  }

 
const ContactMap = JSON.parse(localStorage.getItem("phoneMapping"));
const phoneContactFields = JSON.parse(localStorage.getItem("phoneContactFields"));

let options = ContactMap.file_columns;
options.unshift({value: '', label: 'Select'});
let defaultOption ={}; 
for(let i= 0; i<options.length; i++){	
 	//defaultOption[options[i].value]="";
    if(phoneContactFields!=null){	
	if( phoneContactFields["Full Name"]==""  || phoneContactFields["Full Name"]==undefined){ defaultOption['Full Name']=""; } else {  defaultOption['Full Name']=phoneContactFields["Full Name"];}
	if( phoneContactFields["Combined Name"]==""  || phoneContactFields["Combined Name"]==undefined){ defaultOption['Combined Name']=""; } else {  defaultOption['Combined Name']=phoneContactFields["Combined Name"];} 
	
	if( phoneContactFields["Owner 1 First Name"]==""  || phoneContactFields["Owner 1 First Name"]==undefined){ defaultOption['Owner 1 First Name']=""; } else {  defaultOption['Owner 1 First Name']=phoneContactFields["Owner 1 First Name"];} 
	
	if( phoneContactFields["Owner 1 Last Name"]==""  || phoneContactFields["Owner 1 Last Name"]==undefined){ defaultOption["Owner 1 Last Name"]=""; } else {  defaultOption["Owner 1 Last Name"]=phoneContactFields["Owner 1 Last Name"];} 	
	
	if( phoneContactFields["Owner 2 First Name"]==""  || phoneContactFields["Owner 2 First Name"]==undefined){ defaultOption['Owner 2 First Name']=""; } else {  defaultOption['Owner 2 First Name']=phoneContactFields["Owner 2 First Name"];} 
	
	if( phoneContactFields["Owner 2 Last Name"]==""  || phoneContactFields["Owner 2 Last Name"]==undefined){ defaultOption["Owner 2 Last Name"]=""; } else {  defaultOption["Owner 2 Last Name"]=phoneContactFields["Owner 2 Last Name"];} 	
	}

 }
 localStorage.setItem("phoneContactFields", JSON.stringify(defaultOption)); 
 const handleInputChange = (selectedOption, Select) => {
	  const phoneMappedFields = JSON.parse(localStorage.getItem("phoneContactFields"));

	    let NewData = {}
	    
         for (const [key, value] of Object.entries(phoneMappedFields)) {			  
			  if(key==Select.name) { if(selectedOption.label=="Select") { NewData[key]= ""; }  else { NewData[key]= selectedOption.label; }} else {  NewData[key]= value; }
			};			
	  localStorage.setItem("phoneContactFields", JSON.stringify(NewData)); 

  }
 const Previous = () => {
	history.push("revamp-phone-mapping");
 }
 
  let Owner1FirstDefault = [{ label: "Select", value: "" }];
  let Owner1LastDefault = [{ label: "Select", value: "" }];
  let Owner2FirstDefault = [{ label: "Select", value: "" }]; 
  let Owner2LastDefault = [{ label: "Select", value: "" }];
  let FullNameDefault = [{ label: "Select", value: "" }];  
  let CombinedNameDefault = [{ label: "Select", value: "" }];
  if(phoneContactFields!=null) {
if(phoneContactFields['Owner 1 First Name']!=undefined){	  
 if( phoneContactFields['Owner 1 First Name']!=""){ 
    Owner1FirstDefault = [{ label: phoneContactFields['Owner 1 First Name'], value: phoneContactFields['Owner 1 First Name']}];
 }
}
 if( phoneContactFields['Owner 1 Last Name']!=undefined){ 
 if( phoneContactFields['Owner 1 Last Name']!=""){ 
    Owner1LastDefault = [{ label: phoneContactFields['Owner 1 Last Name'], value: phoneContactFields['Owner 1 Last Name']}];
 }
 }
 
 if( phoneContactFields['Owner 2 First Name']!=undefined){ 
 if( phoneContactFields['Owner 2 First Name']!=""){ 
    Owner2FirstDefault = [{ label: phoneContactFields['Owner 2 First Name'], value: phoneContactFields['Owner 2 First Name']}];
 }
 }
 if( phoneContactFields['Owner 2 Last Name']!=undefined){ 
 if( phoneContactFields['Owner 2 Last Name']!=""){ 
    Owner2LastDefault = [{ label: phoneContactFields['Owner 2 Last Name'], value: phoneContactFields['Owner 2 Last Name']}];
 }
 }
 if( phoneContactFields['Full Name']!=undefined){ 
 if( phoneContactFields['Full Name']!=""){ 
    FullNameDefault = [{ label: phoneContactFields['Full Name'], value: phoneContactFields['Full Name']}];
 } }
 if( phoneContactFields['Combined Name']!=undefined){ 
 if( phoneContactFields['Combined Name']!=""){ 
    CombinedNameDefault = [{ label: phoneContactFields['Combined Name'], value: phoneContactFields['Combined Name']}];
 } }
  }
  
 
 console.log("cssss", Owner1FirstDefault);
 const Owner1FirstSelect = () => (
  <Select
    name="Owner 1 First Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={Owner1FirstDefault}
	onChange={handleInputChange}
  />
  );
 
 const Owner1LastSelect = () => (
  <Select
    name="Owner 1 Last Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={Owner1LastDefault}
	onChange={handleInputChange}
  />
  );

 const Owner2FirstSelect = () => (
  <Select
    name="Owner 2 First Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={Owner2FirstDefault}
	onChange={handleInputChange}
  />
  );

 const Owner2LastSelect = () => (
  <Select
    name="Owner 2 Last Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={Owner2LastDefault}
	onChange={handleInputChange}
  />
  );
 const FullNameSelect = () => (
  <Select
    name="Full Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={FullNameDefault}
	onChange={handleInputChange}
  />
  );
 const CombinedNameSelect = () => (
  <Select
    name="Combined Name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	defaultValue={CombinedNameDefault}
	onChange={handleInputChange}
  />
  );
 
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav /> 
	  <div className="row revamp_upload top">
	    <div className="col-md-3">
		<div className="row left_side"> 
		<div className="col-md-8">
        <Link to='/upload-process-revamp'><span className="icon_heading"> Upload File </span></Link>
		<Link to='/revamp-phone-mapping'> <span className="icon_heading"> Map phone Number </span></Link>
        <Link to='/revamp-contact-mapping'> <span className="icon_heading"> Map Contact Names </span></Link>
        <Link to='/revamp-report'><span className="icon_heading"> Report & Confirmation  </span></Link>
		</div> <div className="col-md-4">
		   <div className="main_icons">
		    <span className="left_icons">
			<svg width="22" height="22" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.238 6.94117C14.4731 6.94117 14.6979 7.03753 14.8597 7.20729L20.8817 13.5161C21.4036 14.0621 21.0159 14.9706 20.26 14.9706L17.1056 14.9706L17.1056 21.5662C17.1056 22.3565 16.4621 23 15.6718 23L12.8041 23C12.0138 23 11.3703 22.3565 11.3703 21.5662L11.3703 14.9706L8.2159 14.9706C7.45999 14.9706 7.07229 14.0621 7.5942 13.5161L13.6163 7.20729C13.778 7.03753 14.0028 6.94117 14.238 6.94117Z" fill="#A0A0A0"/>
<path d="M2.47794 0.058735L25.9926 0.0587371C27.0996 0.0587372 28 0.959178 28 2.06609L28 2.63962C28 3.74653 27.0996 4.64697 25.9926 4.64697L2.47794 4.64697C1.37103 4.64697 0.470589 3.74653 0.470589 2.63962L0.470589 2.06609C0.470589 0.959176 1.37103 0.058735 2.47794 0.058735Z" fill="#A0A0A0"/>
</svg>


			
			</span>
			</div>
			<div className="icon_border" />
			 <div className="main_icons">
		    <span className="left_icons">
	<svg width="21" height="21" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.142 12.9464L14.6802 10.4846C13.8009 9.60541 12.3063 9.95713 11.9546 11.1001C11.6908 11.8914 10.8116 12.331 10.0203 12.1551C8.26189 11.7155 5.88801 9.42957 5.44841 7.58322C5.18464 6.79189 5.71217 5.91268 6.50346 5.64895C7.64644 5.29726 7.99813 3.8026 7.11891 2.92339L4.65711 0.461587C3.95374 -0.153862 2.89869 -0.153862 2.28324 0.461587L0.61273 2.13209C-1.05778 3.89052 0.788573 8.55035 4.92088 12.6827C9.05318 16.815 13.713 18.7493 15.4714 16.9908L17.142 15.3203C17.7574 14.6169 17.7574 13.5619 17.142 12.9464Z" fill="#A0A0A0"/>
</svg>


							
							
			</span></div>
			
             <div className="icon_border" />
			  <div className="main_icons">
		    <span className="left_icons active">
		<svg width="22" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.86449 10.3927C10.2923 10.3927 11.5286 9.88063 12.5388 8.87029C13.5489 7.86012 14.061 6.62412 14.061 5.19619C14.061 3.76875 13.5489 2.53258 12.5386 1.52208C11.5283 0.51208 10.2921 0 8.86449 0C7.43656 0 6.20055 0.51208 5.19038 1.52225C4.18021 2.53242 3.66797 3.76859 3.66797 5.19619C3.66797 6.62412 4.18021 7.86029 5.19055 8.87046C6.20088 9.88046 7.43705 10.3927 8.86449 10.3927Z" fill="white"/>
<path d="M17.9564 16.5899C17.9272 16.1695 17.8683 15.7109 17.7816 15.2266C17.694 14.7388 17.5812 14.2775 17.4463 13.856C17.3069 13.4203 17.1172 12.99 16.8828 12.5777C16.6396 12.1497 16.3538 11.7771 16.0332 11.4704C15.6979 11.1496 15.2873 10.8917 14.8126 10.7035C14.3396 10.5164 13.8153 10.4216 13.2545 10.4216C13.0343 10.4216 12.8213 10.5119 12.4099 10.7797C12.1568 10.9448 11.8606 11.1358 11.5301 11.3469C11.2475 11.527 10.8646 11.6957 10.3917 11.8485C9.93034 11.9978 9.46188 12.0735 8.99951 12.0735C8.53714 12.0735 8.06884 11.9978 7.60696 11.8485C7.13455 11.6959 6.75169 11.5272 6.46939 11.3471C6.142 11.1379 5.84571 10.947 5.58877 10.7796C5.17792 10.5118 4.96476 10.4214 4.74452 10.4214C4.18355 10.4214 3.65945 10.5164 3.18655 10.7037C2.71216 10.8915 2.30148 11.1494 1.96585 11.4706C1.64537 11.7774 1.35946 12.1499 1.1165 12.5777C0.882272 12.99 0.69265 13.4201 0.553066 13.8562C0.418256 14.2777 0.305503 14.7388 0.217934 15.2266C0.131189 15.7102 0.0722607 16.169 0.043126 16.5904C0.0144851 17.0032 0 17.4317 0 17.8644C0 18.9906 0.358012 19.9024 1.06399 20.5748C1.76125 21.2383 2.68385 21.5749 3.80579 21.5749H14.1942C15.3161 21.5749 16.2384 21.2384 16.9358 20.5748C17.642 19.9029 18 18.9909 18 17.8642C17.9998 17.4295 17.9852 17.0007 17.9564 16.5899Z" fill="white"/>
</svg>




			</span>	</div>		
			
           <div className="icon_border" />
			  <div className="main_icons">
		    <span className="left_icons">
			<svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3333 7.08333C10.5527 7.08333 9.91667 6.44725 9.91667 5.66667V0H1.41667C0.636083 0 0 0.636083 0 1.41667V21.25C0 22.032 0.636083 22.6667 1.41667 22.6667H15.5833C16.3653 22.6667 17 22.032 17 21.25V7.08333H11.3333ZM5.66667 19.8333H2.83333V15.5833H5.66667V19.8333ZM9.91667 19.8333H7.08333V12.75H9.91667V19.8333ZM14.1667 19.8333H11.3333V9.91667H14.1667V19.8333Z" fill="#A0A0A0"/>
</svg>

			</span>	</div>				
		</div>
		
		
		</div>
		
		
		
		</div>
		
	    <div className="col-md-9 right-column">
	    <span className="icon_heading_black"> Map Contact Name </span>
      <Credit>
        <Inner>
          <Icon>
            <FavIcon class='far fa-star'></FavIcon>
          </Icon>
          <AvailableCredit>Available Credit</AvailableCredit>
        </Inner>
        <Data>{availableCreadit}</Data>
      </Credit>		

        <div className="row form upload-process-revamp">				
		 <div className="col-md-6">  <div className="fields"> <label>Owner 1 First name</label> <Owner1FirstSelect/></div></div>
		 <div className="col-md-6">  <div className="fields"> <label>Owner 1 Last name</label> <Owner1LastSelect/></div></div>
		 <div className="col-md-6">  <div className="fields"> <label>Owner 2 First name</label> <Owner2FirstSelect/></div></div>
		 <div className="col-md-6">  <div className="fields"> <label>Owner 2 Last name</label> <Owner2LastSelect/></div></div>
		 <div className="col-md-6">  <div className="fields"> <label>Full Name</label> <FullNameSelect/></div></div>
		 <div className="col-md-6">  <div className="fields"> <label>Combined Name</label> <CombinedNameSelect/></div></div>

  	        <div className="submitBtnMain"> <Button text="Next" style="btn-primary back" onClick ={(e)=> Previous() }> Previous</Button> 
			<Button text="Next" style="btn-primary" onClick ={(e)=> MappingPhone() }> Next</Button> </div>
			<div></div>
		
		</div>
		</div>		
	  </div>
	  
    </Wrapper>
  )
}
const MainButton = styled.div`
  float: left;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 200px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 44px;
  cursor: pointer;
  margin-right: 10px;

  ${tw`
    shadow-lg
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
  float:left;
  padding: 10px 0px 0px 20px;
`
const Label = styled.label`
  margin-left: 10px;
  font-size: 18px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: 'Montserrat';
  float:left;
`
const Credit = styled.div`
  display: block;
  width:50%;
  float:left;
`
const Inner = styled.div`
  display: inline-flex;
  text-align:right;
  float:right;
`
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`
const Data = styled.h6`
  color: ${COLORS.blue};
  dispaly: block;
  text-align:center;
  font-weight: 800;
  font-family: Montserrat;
  font-size:24px;
  padding-left:15px;
  float:left;
  text-align:right;
  width:100%;
  padding-right: 32px;
`

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`
const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 172px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
` 
export default MapContact
