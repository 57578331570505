import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import Component from './../creatable-select';
import {getActionData } from "../../services/dataValidation";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import {useParams} from "react-router-dom";
const Modal=(props)=> {
   const [isLoading, setisLoading] = useState(false)
   const [price, setprice] = useState(false)
   let { fileID } = useParams();   
  if(!props.show){ return null; }
  function addTags() {
	  let record_ids =[];
	  $("input:checkbox[name=slectedCheckBox]:checked").each(function(){
			record_ids.push($(this).val());
		});
	  const tags = JSON.parse(localStorage.getItem('tags')); 
	   	   setisLoading(true);
			getActionData(
			  {
				file_id: fileID,
				action: 'add_tag',
				tags:tags,
				record_ids:record_ids
			  }
			)
			  .then(function (response) {
				setisLoading(false);
				if(response.status==false){
			     toast.error(response.message);
				}else {
				toast.success(response.message);  }
			  })
			  .catch((error) => {
				toast.error(error.message);
			  });
					  
  }
const Data = props.data ? props.data : []
const Data2 = props.data ? props.data : []
let NewData= Data.data ? Data.data:[]
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="manipulator-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
	  
        <WrapperHeader className="manipulator-phone-modal">
         <h1 className="popupModalHeader">Time Called</h1>
          <WrapperBody>
		    <div className="lead-input-container">

			</div>
          </WrapperBody>
           <WrapperFooter>
          </WrapperFooter>         
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:968px;
min-height:200px;
left:274px;
top:40px;
z-index:1;
display:flex;

`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width:968px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 0px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 968px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;
  height: 100px;
 

`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;
  top:28px;
  width:88%;
  text-align:center;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;

`;