import React from "react"
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
const Notification = (props) => {
const Item = props.data;
let downloadUrl ="/downloads";
if(Item.module=="DATA_MANIPULATOR") { downloadUrl ="/manipulator-downloads"; }
if(Item.module=="SKIP_TRACING") { downloadUrl ="/skip-Tracing-downloads"; }
const tooltipID = "tooltip_ID_"+props.id;
  return (
  
             <div className="notificatonMain" className={"notification "+props.NotificationClass}>
		     <ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
			  {Item.notification}
            </ReactTooltip>
	<Link  data-tip data-for={tooltipID} to = {downloadUrl}>{Item.notification}</Link></div>
  )
}

export default Notification
