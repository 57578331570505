import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import COLORS from "../../utils/colors"
import "../../assets/css/style.css"
import $ from 'jquery';
import Select from "react-select";
function Pagination(props) {
   const [selectedValue, setSelectedValue] = useState(0);	
  let totalRecords = props.totalRecords;
  if(totalRecords) { totalRecords = totalRecords.replace(/,/g, ''); } 
  
  let pageCount = Math.ceil(totalRecords / props.recordsPerPage)

  const onPageChange = ({ selected }) => {
    props.getPageData(selected + 1, props.recordsPerPage)
  }
  const onPageChange2 = (selectedOption, name) =>{
      const value = selectedOption.value;
	  props.onChangeRecordPerpage(value, pageCount);
	 // setSelectedValue(value);
  }	  
 const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };
  const PerPageOption = [
    { value: "100", label: "100" },
    { value: "150", label: "150" },	
    { value: "200", label: "200" },	
    { value: "250", label: "250" },
  ];
  const PerPageSelect = () => (
    <Select
      name="selectType"
      options={PerPageOption}
      className="adselect"
      classNamePrefix="select"
      styles={customStyles}
	  onChange={onPageChange2}
	  defaultValue={{ value: "100", label: "100" }}
	  value={PerPageOption.find(obj => obj.value === props.selectedValue)}
    />
  );

  return (
    <div>
      <Wrapper>
        <WrapperPagination>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={onPageChange}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </WrapperPagination>
        <WrapperSelect>
          <span className="filter_info perPage">
            <label>Per Page:</label> <PerPageSelect />
          </span>	
        </WrapperSelect>
      </Wrapper>
    </div>
  )
}

export default Pagination

const Wrapper = styled.div`
  position: relative;
  float:left;
  width:100%;

`
const WrapperPagination = styled.div`
margin-left:-20px;
`

const Select2 = styled.select`
border: 1px solid rgba(210, 210, 210, 1);
border-radius: 7px;
padding: 4px 18px 5px 9px;
font-size: 12px;
font-weight: 500;
color: rgba(136, 136, 136, 1);
margin: 0 15px;
margin-top: -5px;
`
const WrapperSelect = styled.div`
display: flex;
float: right;
margin-top: -67px;
margin-right:15px;
margin-top: -86px;
min-width: 150px;
`
const Para = styled.div`
color: #868686;
font-size: 12px;
font-weight: 500;
`
