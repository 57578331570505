export function getAuthorizationToken() {
  let userObject = localStorage.getItem("userData");
  if (userObject) {
    var obj = JSON.parse(userObject);
    console.log(obj['api_token'])
    let apiToken = "Bearer " + obj["api_token"];
    return apiToken
  }
  return null
}

export function getUserID() {
  var userObject = localStorage.getItem("userData");
  if (userObject) {
    var obj = JSON.parse(userObject);
    return obj.id
  }
  return null
}


