import React, { useState,useEffect } from "react"
import styled from "styled-components"
import COLORS from "../../../../utils/colors"

import Item from "./tableItem"
import { getUserID } from "../../../../utils/helper"
import $ from 'jquery';
const Table = (props) => {
  const files = props.files ? props.files : [];
  console.log("cehck dataaa", files);
  const items = []
  for (let i = 0; i < files.length; i++) {   
 
    items.push(
      <Item
        title={files[i].title}
		description={files[i].description}
		created_at={files[i].created_at}
        key={i}
		id ={files[i].id}
		addTask={props.addTask}
		addNote={props.addNote}
		counter={i}
		>
        </Item>
    )
  }
  return (
    <Wrapper>	
	
      <WrapperTitle>
      </WrapperTitle>
	  <div className="row activity-actions">	  	  
<div className=" col-md-6"><a href="javascript:void(0)" onClick={(e)=>props.addTask()}>+Add TASK</a></div>
<div className=" col-md-6"><a href="javascript:void(0)" onClick={(e)=>props.addNote()}>+Add NÖTE</a></div>
	  </div>
	  
      <div className="scroll-in-mobile dowloads">	
	  
      {items}
      </div>
    </Wrapper>
  )
}
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top:14px;
`
const HeadingDelete = styled.h2`
  color: ${COLORS.red};
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`
const View = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.lightblue};
`
export default Table
