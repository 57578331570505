import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {phonedownloadLeads } from "../../services/dataValidation"
import {QuickPay} from "../../services/paymentDeatil"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
const Modal=(props)=> {
   const [isLoading, setisLoading] = useState(false)
   const [price, setprice] = useState(false)
  if(!props.show)
  {
    return null;
	
  }  
 
  function DownloadhandleChange(e, Select){
	  localStorage.setItem("file_format", e.value)
	  props.submitFile(e.value);
  }
	const options = [
      { value: 'Call Tools', label: 'Call Tools' },
	  { value: 'Call Tools 2.0', label: 'Call Tools 2.0' },
	  { value: 'Xencall', label: 'Xencall' },
	  { value: 'Go High Level (REIReply)', label: 'Go High Level (REIReply)' },
	  { value: 'Mojo Dialer', label: 'Mojo Dialer' },
	  { value: 'REISift', label: 'REISift' },
	  { value: 'Batch Dialer', label: 'Batch Dialer' },
	  
	]; 

	const AutosuggetsDownloadSelect = () => (
	 <Select
		options={options}
		className="basic-multi-select-lead"
		onChange={DownloadhandleChange}
	  />
	) 
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="lead-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2><span className="low_credit"> Please Select Format</span></Para2>
          </ModalTitle>
          <WrapperBody>		    		
		    <div className="lead-input-container purhcase-credit">
				<AutosuggetsDownloadSelect/>
			
			</div>		    
          </WrapperBody>

        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:567px;
min-height:200px;
left:420px;
top:40px;
z-index:1;
display:flex;
z-index: 999999;
`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width: 520px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 20px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;


`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;
  top:28px;
  width:88%;
  text-align:center;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;

`;