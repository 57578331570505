import styled from "styled-components"

const Heading = (props) => {
  return (
    <Element color={props.color} fontSize={props.size}>
      {props.children}
    </Element>
  )
}

const Element = styled.h3`
  padding: 0.5rem 0;
  text-align: initial;
  font-weight: bold;
}
`
export default Heading
