import { BASE_URL, API_URL } from "../config"
import { getAuthorizationToken } from "../utils/helper"

export async function getCrmContactInfo(requestObj) {
  const url = BASE_URL + "crm"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getCrmActions(requestObj, page) {
  const url = BASE_URL + "crm-actions?page="+ page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}