import "./App.css"
import DataValidation from "./pages/dataValidation"
import DataManipulation from "./pages/dataManipulator"
import MappingFields from "./pages/dataManipulator/mappingFields"
import Contacts from "./pages/dataManipulator/contacts"
import History from "./pages/dataManipulator/history"
import Report from "./pages/dataManipulator/report"
import MergeCsv from "./pages/dataManipulator/contacts/merge-csv"
import Dashboard from "./pages/dashboard"
import Services from "./pages/services"
import Files from "./pages/files"
import NoComponent from "./pages/noComponent"
import Payments from "./pages/payments"
import Profile from "./pages/profile"
import Tools from "./pages/tools"
import Downloads from "./pages/downloads"
import ManipulatorDownloads from "./pages/downloads/manipulator-download"
import Skipdownloads from "./pages/downloads/skip-tracing-download"
import RevampUpload from "./pages/revampUpload"
import revampReport from "./pages/revampUpload/report"
import mapPhone from "./pages/revampUpload/mapPhone"
import mapContact from "./pages/revampUpload/mapContact"
import Credits from "./pages/credits"
import CreditPaymet from "./pages/creditPayment/index"
import CreditsTwo from "./pages/credits/creditsTwo"
import CRMActivity from "./pages/crm"
import CRMPhone from "./pages/crm/phones"
import CRMAddresses from "./pages/crm/addresses"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom"
import { Fragment, useEffect } from "react"
import SideBar from "./components/navbar/sidebar"
import { getUserID } from "../src/utils/helper"

function App() {
  let history = useHistory()
  useEffect(() => {
    const user = getUserID()
    if (!user) {
      history.push("/")
    }
  }, [])

  return (
    <Fragment>
      <Router>
        <SideBar />
        <Switch>
          <Route path='/phone-validation' component={DataValidation} />
          <Route path='/data-manipulator' component={DataManipulation} />
		  <Route path='/mapping-fields' component={MappingFields} />
		  <Route path='/data-manipulator-contacts/:fileID' component={Contacts} />
		  <Route path='/data-manipulator-contacts-merge-csv/:fileID' component={MergeCsv} />
		  <Route path='/upload-process-revamp' component={RevampUpload} />
		  <Route path='/revamp-phone-mapping' component={mapPhone} />
		  <Route path='/revamp-contact-mapping' component={mapContact} />
		   <Route path='/revamp-report' component={revampReport} />
		  <Route path='/data-manipulator-history/:id' component={History} />
		  <Route path='/report' component={Report} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/services' component={Services} />
          <Route path='/payments' component={Payments} />
		  <Route path='/profile' component={Profile} />
		  <Route path='/tools' component={Tools} />
		  <Route path='/downloads' component={Downloads} />
		  <Route path='/manipulator-downloads' component={ManipulatorDownloads} />
		  <Route path='/skip-Tracing-downloads' component={Skipdownloads} />
          <Route path='/files' component={Files} />
          <Route path='/credits' component={Credits} />
          <Route path='/add-payment' component={CreditPaymet} />
          <Route path='/creditstwo' component={CreditsTwo} />
		  <Route path='/crm/:contactID' component={CRMActivity} />
		  <Route path='/crm-phone/:contactID' component={CRMPhone} />
		  <Route path='/crm-addresses/:contactID' component={CRMAddresses} />
		  

		  
          <Route path='*' exact component={NoComponent} />
        </Switch>
      </Router>
    </Fragment>
  )
}

export default App
