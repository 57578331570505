import React, { useEffect, useState } from "react";
import Autosuggest from 'react-autosuggest';
import FilterComponent from "./../filter";
import $ from 'jquery';
localStorage.removeItem("filterParam");
// Imagine you have a list of languages that you'd like to autosuggest.
const Filters = [ { label: 'Owner 1'}, { label: 'Owner 2'}, { label: 'Mailing Address'}, { label: 'Mailing City'}, { label: 'Mailing State'}, { label: 'Mailing Zip'}, { label: 'Property Address'}, { label: 'Phone Number'}, { label: 'Phone Position'}, { label: 'Caller ID Name'}, { label: 'Caller ID Match'}, { label: 'Carrier'}, { label: 'Carrier'}, { label: 'Line Type'}, { label: 'Connected'}, { label: 'Disposition '}, { label: 'Times Called'}, { label: 'Tag'},{ label: 'Email'}, {label:'Custom Fields'}, {label:'Property Address (Total)'}, {label:'Times Called (Total)'}, {label:'Phone Number (Total)'}];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : Filters.filter(lang =>
    lang.label.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.label;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.label}
  </div>
);

class FilterAutosuggest extends React.Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
	  tableListFields:[],
	  newItem:false
    };
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
	 let Paramargs = [];
     Paramargs.push({value: suggestionValue, label: suggestionValue});	 
	 if(localStorage.getItem("filterParam")==null){ localStorage.setItem("filterParam",JSON.stringify(Paramargs))} else {
		 let Paramargs = JSON.parse(localStorage.getItem("filterParam"));
		 Paramargs.push({  value: suggestionValue, label: suggestionValue});
		 localStorage.setItem("filterParam", JSON.stringify(Paramargs));
	 }
	 this.setState({newItem:true});
	this.setState({tableListFields:JSON.parse(localStorage.getItem("filterParam"))}); 
  }  
  render() {
	$( ".row.filters-2-main .col-md-5 .filtervalue label" ).on( "click", function( event, salutation, name ) {
	  var className=$(this).attr("class");
	  $('.'+className).remove();
	});	  
    const { value, suggestions, tableListFields, newItem } = this.state;
 const fileID= localStorage.getItem( "file_id");
 let tableListFieldsItems = tableListFields.length ? tableListFields: [];
  if(this.props.presetType==true && newItem==false) {
	tableListFieldsItems = this.props.data;
 }
 const TableFields = [];
  for (let i = 0; i < tableListFieldsItems.length; i++) { 
    TableFields.push(
      <FilterComponent key={i} id={i} data={tableListFieldsItems[i]} presetType = {this.props.presetType} Owner1fnOption={this.props.Owner1fnOption} newItem={newItem}> </FilterComponent>
    )
  } 
 
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Enter Filter Name',
      value,
      onChange: this.onChange
    };
    // Finally, render it!
    return (
      <div><Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
		onSuggestionSelected = {this.onSuggestionSelected}
        inputProps={inputProps}
		
      />
	  <div className="row filters-2-main">
	  <div className="col-md-3"><label>Column Name</label></div><div className="col-md-4"><label>  Filter </label></div> <div className="col-md-5"><label> Value</label></div>
	  {TableFields} 
	  </div></div>
    );
  }
}

export default FilterAutosuggest;