import React, { useEffect, useState } from "react"
import { downloadSkipTracinglisting, DeleteDownloadsFiles } from "../../../services/dataValidation"
import { getUserID } from "../../../utils/helper"
import TopNav from "../../../components/navbar/topNav"
import Table from "../../../components/navbar/tableItems/download/table"
import styled from "styled-components"
import Pagination from "../../../components/pagination"
import Loading from "../../../components/loading"
import "../../../assets/css/style.css"
import Modal from "../../../components/lead-modal"
import FavIcon from "../../../components/favicon"
import COLORS from "../../../utils/colors"
import tw from "twin.macro"
import { Link } from "react-router-dom"
import Search from "./../../../components/downoad-search"
import $ from 'jquery';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
const Skipdownloads = () => {
  const [userExcelData, setExcelUserData] = useState([])
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [DownloadFiles, setDownloadsFiles] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [getTotalExcelData, setTotalExcelData] = useState(false)
  const [show, setShow] = useState(false)
  const [filedata, setfiledata] = useState(false)
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);
  const [selectedExceldata,setSelectedExceldata] = useState([]); 
  const [emptyDataMsg,setemptyDataMsg] = useState("");
 
  document.title = "Downloads | AnalyzeREI"
  useEffect(() => {
    downloadLeadsData(noOfPages, recordsPerPage)
  }, [])  

useEffect(() => {
  const interval = setInterval(() => {
   downloadLeadsData2(noOfPages, recordsPerPage)
  }, 5000);
  return () => clearInterval(interval);
}, []);
  
    const onFileChange = (event) => {
       setShow(true);
  }
  function downloadLeadsData(noOfPages, recordsPerPage) {
    setisLoading(true)
    const user = getUserID()
    downloadSkipTracinglisting(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
		setemptyDataMsg("No downloads available");
      })
      .catch((error) => {
        console.log("error", error)
      })
  }  

  function downloadLeadsData2(noOfPages, recordsPerPage) {
     noOfPages = $("li.paginationActive a").text();
	 let search = $("input.download.search").val();
	 recordsPerPage = $('select.PerPageSelect :selected').val();
	 if(noOfPages==""){ noOfPages=1; }
    const user = getUserID()
    downloadSkipTracinglisting(
      {
        user_id: user,
        per_page: recordsPerPage,
		search:search
      },
      noOfPages
    )
      .then(function (response) {
        //setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length);
		setemptyDataMsg("No downloads available");
      })
      .catch((error) => {
        console.log("error", error)
      })
  }    
  
  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    downloadLeadsData(1, perPageValue)
  }
  
  function searchdata(noOfPages, recordsPerPage) {
    setisLoading(true)
	noOfPages = $("li.paginationActive a").text();
	let search = $("input.download.search").val();
    const user = getUserID()
    downloadSkipTracinglisting(
      {
        user_id: user,
        per_page: recordsPerPage,
		search:search
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
		setemptyDataMsg("No Data Found");
      })
      .catch((error) => {
        console.log("error", error)
      })
  }  
 
  const bulkDelete = () => {
	  var checkboxes = document.getElementsByName('slectedCheckBox[]')
      for(var i in checkboxes){
        if(checkboxes[i].checked == true){
          var inputElems = document.getElementsByName('slectedCheckBox[]'),
          row = [];
          for (var i=0; i<inputElems.length; i++) {
            if (inputElems[i].type === "checkbox" && inputElems[i].checked === true){
              row.push(inputElems[i].value);
            }
          }
		 
        }
      }
	  setisLoading(true);
	   if(row==undefined){ alert("Please Select altest one Item"); return false; }	  
	   var rowIds = row.join(',');
		  DeleteDownloadsFiles({
		  file_ids: rowIds,
		  action:"skip-tracing"
		})
		  .then(function (response) {   
              setisLoading(false);	
               $("input.deleteCheckbox.downloads").prop("checked", false);					  
			   if(response.status==true){
                  downloadLeadsData2(noOfPages, recordsPerPage)
			   } else { toast.error(response.message); }
		  })
		  .catch((error) => {
			console.log("error", error)
		  })	  
  } 
  //select all checkboxes
  function checkAllCheckbox(event){
    const allCheckboxChecked = event.target.checked
    var checkboxes = document.getElementsByName('slectedCheckBox[]')
    if(allCheckboxChecked){
      setslectedBoxTrue(true);
      for(var i in checkboxes){
        if(checkboxes[i].checked == false){
          checkboxes[i].checked = true;
          var inputElems = document.getElementsByName('slectedCheckBox[]'),
          count = 0;
          for (var i=0; i<inputElems.length; i++) {
            if (inputElems[i].type === "checkbox" && inputElems[i].checked === true){
              count++;
              // alert(count);             
              setSelectedExceldata(count);
            }
			$(".DeleteAction").show();
          }
        }
      }
    }else{ $(".DeleteAction").hide();
      setslectedBoxTrue(false);
      for(var i in checkboxes){
        if(checkboxes[i].checked == true){
          count--;
          setSelectedExceldata(null);
          checkboxes[i].checked = false;
        }
      }
    }
  }  
 
  return (
    <Wrapper>
	      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
		fields={filedata}
      />
      <Loading show={isLoading} />
      <TopNav />
	  <div className="MainDownloadTabs">
	  <ul><li><Link to='/downloads'>Phone Validation</Link></li> <li> <Link to='/manipulator-downloads'> Data Manipulator</Link></li> <li className="active"><Link to='/skip-Tracing-downloads'>Skip Tracing</Link></li></ul>
	  </div>
	    {getTotalExcelData >0 &&
	   <SearchWrapper className="download search">
        <Search search={searchdata} />
		</SearchWrapper>	}	  
	 
      {getTotalExcelData <1 && 
	    <div className="nodata">{emptyDataMsg}</div>
	  }	  
	  {DownloadFiles}

      <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={downloadLeadsData}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}>
          </Pagination>
      </WrapperPagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
  text-decoration:none;
  display:none;
  cursor:pointer;
`
const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
`
const Label = styled.label`
  margin-left: 20px;
  font-size: 18px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: 'Montserrat';
`

const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 155px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
`
export default Skipdownloads
