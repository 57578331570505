import React, { useState } from "react"
import styled from "styled-components"
import FileIcon from "../../../../assets/images/file.png"
import DownloadIcon from "../../../../assets/images/download-icon.png"
import ProgressBar from "../../../progressbar"
import COLORS from "../../../../utils/colors"
import FavIcon from "../../../../components/favicon"
import Modal from "../../../../components/lead-modal"
import tw from "twin.macro"
import { deleteSelectedFile, downloads } from "./../../../../services/dataValidation";
import { getUserID } from "./../../../../utils/helper"
import ReactTooltip from "react-tooltip";
const Item = (props) => {
  
  const [selectedExceldata,setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [userExcelData, setExcelUserData] = useState([])
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [getTotalExcelData, setTotalExcelData] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [show, setShow] = useState(0)
  function formatDate(date) {
    const getDateSuffix = (date) => {
      const thExpections = (date) =>
        [11, 12, 13].some((exception) => exception === date)
      const lastDigit = date % 10
      if (thExpections(date) || lastDigit === 0 || lastDigit > 3)
        return `${date}th`
      if (lastDigit === 1) return `${date}st`
      if (lastDigit === 2) return `${date}nd`
      return `${date}rd`
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const dateMonth = new Date(date)
    const dateAndMonth = ` ${monthNames[dateMonth.getMonth()]} ${getDateSuffix(
      dateMonth.getDate()
    )} `
    const today = new Date(date)
    const time = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(today)
    return dateAndMonth + time
  } 
  function DownloadPopup(id){
	  localStorage.setItem("file_id", id);
	  setShow(true);
  }
const tooltipID = "tooltip_ID_"+props.id;
  return (
    
    <div>

      <div>
        {/* <input type="checkbox" onChange={checkAllCheckbox} />Select All */}
      </div>
	  
      <Wrapper>    
	 <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
		
      />	  
        <ItemWrapper>
          {/* <Checkbox type='checkbox' name="slectedCheckBox[]"  onChange={checkboxes} /> */}
		   <Mobile>
            <ParaTwo data-tip data-for={tooltipID} className="itemHeading">File</ParaTwo>
            <Para>{props.name}</Para>
			<ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
			  {props.name}
            </ReactTooltip>
          </Mobile>
          <Mobile>
            <ParaTwo className="itemHeading">Created at</ParaTwo>
            <Para>{props.created_at}</Para>
          </Mobile>
          <DownloadIconWrapper> 
             <a href={props.download_url} target="_blank"><Icon src={DownloadIcon} width='34px' height='34px' onClick={()=>DownloadPopup(props.id)}/></a>
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
    </div>
  )
}

export default Item

const Icon = styled.img`
  margin-left: 0px;
  padding-top: 5px;
  cursor: pointer;
`

const BookIcon = styled.img`
 
  cursor: pointer;
  padding-top:9px;
`
const Wrapper = styled.div`
  z-index: 0;
  display: flex;
  height: 80px;
  background-color: ${COLORS.white};
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  ${tw`
    shadow-lg
  `}
`

const ItemWrapper = styled.div`
  position: relative;
  top: 10%;
  display: flex;
  width: 100%;
  justify-content: space-around;
  
`

const InnerWrapper = styled.span`
  display: inline-block;
  font-size: 10px;
`

const InnerWrapperIcon = styled.span`
  display: inline-block;
  font-size: 10px;
  margin-left:-20px
`

const DownloadIconWrapper = styled.span`
  margin-top: 10px;

`

const Heading = styled.label`
  margin-top: 0px;
  color: ${COLORS.username};
  font-family: Montserrat;
  overflow: hidden;
  text-overflow: ellipse;
  whitespace: nowrap;
  font-size: 13px;

`

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const Mobile = styled.label`
  margin-top: 10px;
  color: ${COLORS.blue};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const Num = styled.p`
  color: #5B5B5B;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
`
const Checkbox = styled.input`
  margin-top: 25px;
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;
  margin-right:-20px;
  float: left;
    margin-left: -16px;
    font-size: 15px;
`
const DeleteIcon = styled.span`
  margin-top: 20px;\
  cursor: pointer;
  color: ${COLORS.red};
`
const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  padding-top
  word-break: break-all;
`


















