import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {phonedownloadLeads } from "../../services/dataValidation"
import {QuickPay} from "../../services/paymentDeatil"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
const Modal=(props)=> {
   const [isLoading, setisLoading] = useState(false)
   const [price, setprice] = useState(false)
  if(!props.show)
  {
    return null;
	
  }
const Data = props.data ? props.data : []
const Data2 = props.data ? props.data : []
let NewData= Data.data ? Data.data:[]
console.log("check data", Data);
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="manipulator-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
	  
        <WrapperHeader className="manipulator-phone-modal">
         <h1 className="popupModalHeader">Phone Numbers</h1>
		 <h3 className="popupModalHeader">Mailing Address:<span className="mailing_address"> {Data.mailing_address}</span></h3>
		 <h3 className="popupModalHeader">Owner 1: <span className="ownerName">{Data.owner_1}</span></h3>
		 <h3 className="popupModalHeader">Owner 2: <span className="ownerName">{Data.owner_2}</span></h3>
          <WrapperBody>
		    <table className="popupTablelisting">
	 <tr className="heading">
		<th className="frist">Phone Number</th>
		<th>Phone Position</th>
		<th>Carrier</th>
		<th>Caller ID Name</th>
		<th>Caller ID Match</th>
		<th>Line Type</th>
		<th>Connected</th>
		<th>Disposition</th>
		<th>Time Called</th>
	</tr>
	{NewData.length>0 && NewData.map(phoneData => (
	  <React.Fragment>
	 <tr>
		<td className="frist">{phoneData.phone}</td>
		<td>{phoneData.phone_position}</td>
		<td>{phoneData.carrier}</td>
		<td>{phoneData.caller_id_name}</td>
		<td>{phoneData.caller_id_match}</td>
		<td>{phoneData.line_type}</td>
		<td>{phoneData.connected}</td>
		<td>{phoneData.last_disposition}</td>
		<td>{phoneData.times_called}</td>		
	</tr></React.Fragment>
	)) }
	  </table> 
          </WrapperBody>
          
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:968px;
min-height:200px;
left:280px;
top:40px;
z-index:1;
display:flex;
z-index:9999;
`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width:968px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 0px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 968px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 5px;
  height: 325px;
  overflow-y: auto;
  border-radius:10px;
  margin: 1% 2%;  

`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;
  top:28px;
  width:88%;
  text-align:center;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-0right:30px;
background-color:white;
border-radius:5px;

`;