import { BASE_URL } from "../config"
import { getAuthorizationToken } from "../utils/helper"

// submit = async (ev) => {
//     ev.preventDefault();
//     const { currentTarget } = ev;
//     const fD = new FormData(currentTarget);
//     const cardInfo = {
//       name: fD.get('name'),
//     };
//     let { token } = await this.props.stripe.createToken(
//       { name: cardInfo.name, }
//     );
//     console.log(token)
//     let response = await fetch(`https://appv1.analyzerei.com/api/addStripeCard`, {
//       method: "POST",
//       headers: { "Content-Type": "text/plain" },
//       body: token.id
//     });

//     if (response.ok) console.log("Purchase Complete!")
//   }

  // export async function setDefaultCardAPI(requestObj) {
  //   const url = BASE_URL + "setStripeDefaultCard"
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { 
  //       'Authorization': `Bearer ${apitoken}`,
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(requestObj),
  //   }
  //   // return fetch(url, requestOptions)
  //   // .then((response) => response.json())
  //   // .catch(function (error) {
  //   //   console.log('error: ', error);
  //   //   return error;
  //   // })
  // }
  export async  function submit(ev){
    ev.preventDefault();
    const { currentTarget } = ev;
    const fD = new FormData(currentTarget);
    const cardInfo = {
      name: fD.get('name'),
    };
    let { token } = await this.props.stripe.createToken(
      { name: cardInfo.name, }
    );
    console.log(token)
    let response = await fetch(`https://appv1.analyzerei.com/api/addStripeCard`, {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      body: token.id
    });

    if (response.ok) console.log("Purchase Complete!")
      
  }

  //get Trasaction History API
  export async function getUsersTransactionData(requestObj) {
    const url = BASE_URL + "getUserTransactions"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }

  //get User Credit Cards 
  export async function getUserCreditCardsAPI(requestObj) {
    const url = BASE_URL + "getUserCreditCards"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }

  //Add New Card API
  export async function AddNewCardAPI(requestObj) {
    const url = BASE_URL + "addStripeCard"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }

  //make Default Card API
  export async function makeStripeDefultCard(requestObj) {
    const url = BASE_URL + "setStripeDefaultCard"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }

  //Delete User Stripe Card
  export async function deleteStripeCardAPI(requestObj) {
    const url = BASE_URL + "deleteStripeCard"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }


  //add payment page pay button
  export async function paybutton(requestObj) {
    const url = BASE_URL + "chargeByCredit"
    const token = getAuthorizationToken()
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(requestObj),
    }
    return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
  }

export async function QuickPay(requestObj) {
  const url = BASE_URL + "quick-pay"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function CheckRequiredCreditCall(requestObj) {
  const url = BASE_URL + "check-required-credits"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}