import { memo } from "react"
import styled from "styled-components"
import { ProgressBar as Bar } from "react-bootstrap"
import COLORS from "../../utils/colors"
const ProgressBar = (props) => {
	console.log("[roagaga", props.percentage);
  if(props.percentage=="100") {
	
      return (
    <Wrapper>
      <Text className="ready_status">Ready</Text>
    </Wrapper>
  )	
  }
  else {
  
  return (
    <Wrapper>
      <Text>Status : {props.percentage}%</Text>
      <Bar
        className='status-bar'
        now={props.percentage !== undefined ? props.percentage : 0}
        height={"5px"}
      />
    </Wrapper>
  )
}
}

const Wrapper = styled.div`
  margin-top: 10px;
  width: 100%;
`

const Text = styled.label`
  font-size: 12px;
  color: ${COLORS.grey};
`

export default memo(ProgressBar)
