import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {downloadLeads } from "../../services/dataValidation"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
const Modal=(props)=> {
  const [LineDefault, setLineDefault] = useState([]);
  const [lselected, setlselected] = useState(false);
  const [LineValue, setLineValue] = useState(false);
  const [CallerValue, setCallerValue] = useState(false);
  const [downloadValue, setdownloadValue] = useState(false);
  const [selected, setselected] = useState(false);
  const [DownloadDefault, setDownloadDefault] = useState(false);
  const [CallerDefault, setCallerDefault] = useState(false);
  if(!props.show)
  {
    return null;
	
  }
  //const handleChange = (selectedOption) => {
    //setselected({ selectedOption }); 
  //};
  
  const linehandleChange = (selectedOption) => {
    setlselected({ selectedOption }); 
  };  
  

  function DownloadsFiles(){
    const cb = document.getElementById('Checkboxall');  
	var LineValue = localStorage.getItem("LineValue");
	var DownloadValue = localStorage.getItem("DownloadValue");
	let recordsID = $('input[name="slectedCheckBox"]:checked').map(function () {  
        return this.value;
        }).get().join(",");	
	if(recordsID=="") {
		recordsID = $("#fileIds").val();
	}	
	var fileID = localStorage.getItem("file_id");
	var CallerValue = localStorage.getItem("CallerValue");
	var download_title=document.getElementById("download_title").value;
	if(cb.checked) { var allData ="yes"; } else { var allData =""; }
    downloadLeads({file_id:fileID, line_type:LineValue, caller_id_match:CallerValue, all_data:allData, download_title:download_title, format:DownloadValue, records_id:recordsID})
      .then(function (response) {
		  if(response.status==true) {toast.success(response.message); } else { toast.error(response.message); } 
		  $("button.lead-close").trigger("click");
		  
      })
      .catch((error) => {
		  toast.error("We are getting errors");    
        //console.log("error", error)
      })
  }  
  
	  const LineValuehandleChange = (selectedOption) => {
		  var newArray = []
		  for (var i=0, l=selectedOption.length; i<l; i++){
				newArray.push(selectedOption[i].value);
			}  
			localStorage.setItem("LineValue", newArray);
	  }
	  
	 const DownloadhandleChange = (selectedOption) =>{ 
	 		  var newArray = []
			  for (var i=0, l=selectedOption.length; i<l; i++){
					newArray.push(selectedOption[i].value);
				}  
			localStorage.setItem("DownloadValue", newArray);
	  }
	  
	 const CallerhandleChange = (selectedOption) => {
	 		  var newArray = []
			  for (var i=0, l=selectedOption.length; i<l; i++){
					newArray.push(selectedOption[i].value);
				}  		 
			localStorage.setItem("CallerValue", newArray);
	  }		  
	  
	const options = [
      { value: 'Call Tools', label: 'Call Tools' },
	  { value: 'Call Tools 2.0', label: 'Call Tools 2.0' },
	  { value: 'Xencall', label: 'Xencall' },
	  { value: 'Go High Level (REIReply)', label: 'Go High Level (REIReply)' },
	  { value: 'Mojo Dialer', label: 'Mojo Dialer' },
	  { value: 'REISift', label: 'REISift' },
	  { value: 'Batch Dialer', label: 'Batch Dialer' },
	  
	];  

	const Coptions = [
	  { value: 'ALL', label: 'All' },
	  { value: 'YES', label: 'Yes' },
	  { value: 'NO', label: 'No' },
	]; 
	const AutosuggetsDownloadSelect = () => (
	 <Select
		defaultValue={DownloadDefault}
		options={options}
		className="basic-multi-select-lead"
		onChange={DownloadhandleChange}
		isMulti
	  />
	)

	const LineOptions = [
	  { value: 'landline', label: 'Valid Land Line' },
	  { value: 'mobile', label: 'Valid mobile' },
	  { value: 'disconnected', label: 'Disconnected' },
	];  
	
	const AutosuggetsLineSelect = () => (
	 <Select
		defaultValue={LineDefault}
		options={LineOptions}
		className="basic-multi-select-lead"
		onChange={LineValuehandleChange}
		isMulti
	  />
	)

	const AutosuggetsCallerSelect = () => (
	 <Select
		defaultValue={CallerDefault}
		options={Coptions}
		className="basic-multi-select-lead"
		onChange={CallerhandleChange}
		isMulti
		
	  />
	)

    function checkAll(){
		if ($("#Checkboxall").is(":checked")) {
			setLineDefault(LineOptions);
			setDownloadDefault(options)
			setCallerDefault(Coptions);
			localStorage.setItem("LineValue", "landline,mobile,disconnected");
			localStorage.setItem("DownloadValue", "Call Tools,Call Tools 2.0,Xencall,Go High Level (REIReply),Mojo Dialer,REISift,Batch Dialer");
			localStorage.setItem("CallerValue", "Yes,No");
		} else { setLineDefault([]); setDownloadDefault([]); setCallerDefault([]) 
		localStorage.removeItem("LineValue");
		localStorage.removeItem("DownloadValue");
		localStorage.removeItem("CallerValue");
		}
	}	
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="lead-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2><span className="lead_title"><svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50054 11.0833C9.33824 11.0833 9.18308 11.0168 9.07145 10.8997L4.9152 6.5455C4.55499 6.16867 4.82258 5.54167 5.34429 5.54167H7.52137V0.989583C7.52137 0.444125 7.96549 0 8.51095 0H10.4901C11.0356 0 11.4797 0.444125 11.4797 0.989583V5.54167H13.6568C14.1785 5.54167 14.4461 6.16867 14.0859 6.5455L9.92962 10.8997C9.81799 11.0168 9.66283 11.0833 9.50054 11.0833Z" fill="#047CFF"/>
<path d="M17.6146 15.8332H1.38542C0.621458 15.8332 0 15.2117 0 14.4478V14.0519C0 13.288 0.621458 12.6665 1.38542 12.6665H17.6146C18.3785 12.6665 19 13.288 19 14.0519V14.4478C19 15.2117 18.3785 15.8332 17.6146 15.8332Z" fill="#047CFF"/>
</svg>
 Export Leads </span><span className="lead-all"><input type="Checkbox" name="all" value="all" id="Checkboxall" onClick={checkAll}/> All data</span></Para2>
          </ModalTitle>
          <WrapperBody>
		    <div className="lead-input-container">
				<label>Line Type</label>
				<AutosuggetsLineSelect/>
			</div>
		    <div className="lead-input-container">
				<label>Caller Id Match</label>
				<AutosuggetsCallerSelect/>
			</div>

		    <div className="lead-input-container">
				<label>Download Title</label>
				<input type="text" name="download_title" id="download_title"/>
			</div>

		    <div className="lead-input-container">
				<label>Download Format</label>
				<AutosuggetsDownloadSelect/>
			</div>			
		    
          </WrapperBody>
          <WrapperFooter>
            <ModalButton onClick={props.onClose}>Cancel</ModalButton>
			<ModalButtonSubmit onClick={DownloadsFiles}>Download</ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:567px;
min-height:200px;
left:420px;
top:40px;
z-index:1;
display:flex;

`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width: 520px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 20px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 520px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;


`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;

  top:28px;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;

`;