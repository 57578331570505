import React , { useEffect, useState  } from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { getUserID } from "../../utils/helper"
import { AddNewCardAPI } from "../../services/paymentDeatil"
import CardSection from "./CardSection";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import axios from 'axios';
import Loading from "../../components/loading"
import { useHistory } from "react-router"
import {paybutton} from "../../services/paymentDeatil"

const CheckoutForm = (props) => {
  let history = useHistory()
  let userObject = localStorage.getItem("purchasePrice");
  var obj = JSON.parse(userObject);
  let getPurchasePrice = obj;
  const amount = Math.round(getPurchasePrice*100);

  const [isLoading, setisLoading] = useState(false)

  //add card Payment
  const handleSubmit = async (event) => {
    setisLoading(true)
    event.preventDefault();
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    // setCardDat(result.token.card['last4']);
    if (result.error) {
      console.log(result.error.message);
      toast.error(result.error.message)
      setisLoading(false)
    } else {  
        console.log(result.token);
        const cardToken = result.token['id'];
        const userId = getUserID()
        AddNewCardAPI({
            user_id: userId,
            stripeToken : cardToken
        })
        .then(function (response) {
          if(response.status === true){
            console.log(response)
            // toast.success(response['message'])  
            setisLoading(true)
            const userId = getUserID()
            console.log({
              card_id: result.token.card['id'], 
              user_id : userId,
              credit : amount,
              save_card: getCheckBoxValue,
          })
            paybutton({
                card_id: result.token.card['id'], 
                user_id : userId,
                credit : amount,
                save_card: getCheckBoxValue,
            })
            .then(function (response) {
                if(response.status === true){
                    setisLoading(false)
                    console.log(response)
                    toast.success(response['message']) 
                    history.push('/data-validation') 
                  }else{
                    setisLoading(false)
                    console.log(response)
                    toast.error(response['errors'])    
                  }  
            })
            .catch((error) => {
                console.log("error", error)
            })
          }else{
            console.log(response)
            toast.error(response['message'])    
          } 
        })
        .catch((error) => {
            setisLoading(false)
            console.log("error", error)
        })
    }
  };
  const[getCheckBox,setCheckBox] = useState(false);
  let getCheckBoxValue ;
  const checkboxes = (e) => {
    if(e.target.checked){
      console.log("true")
      getCheckBoxValue = "no"
    }else{
      console.log("false")
      getCheckBoxValue = "no"
    }
  }
 
    return (
      <div>
        <Loading show={isLoading} />
        <form onSubmit={handleSubmit}>
          <CardSection />
          <div className="button-in-credit-two position-relative mar-top-50">
          <button disabled={!props.stripe} className="btn-pay">
            Pay
          </button>
            <div className="checkbox-in-credit-two">
                <input type="checkbox" id="checkbox-credit2" name="checkbox-credit2" value="checkbox-credit" onChange={checkboxes}/>
                <label for="checkbox-credit2">Save card for future use</label>
            </div>
          </div>
        </form>
      </div>
    );
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}


