
import React from 'react'
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {mappingFileData, getUploadedFileDetails } from "../../services/dataValidation"
import { getUserID } from "../../utils/helper"
const Modal=(props)=> {
  if(!props.show)
  {
    return null;
  }

  const files = props.fields.columns ? props.fields.columns : []
  const items = []
  for (let i = 0; i < files.length; i++) {  
    items.push({ value: files[i], label: files[i]});
  }

  const handleChange = (selectedOption) => {

	  var newArray = []
	  for (var i=0, l=selectedOption.length; i<l; i++){
			newArray.push(selectedOption[i].value);
		}
		var mapping= newArray.join(",");
		var headerMapping= props.fields.columns.join(",");
		document.getElementById("mapping").value = mapping;
		document.getElementById("file_id").value = props.fields[0].id;; 
		 const userID = getUserID()
	    getUploadedFileDetails({ filelist_id: props.fields[0].id,mapping:mapping,user_id:userID })
		  .then(function (response) {
				if (document.contains(document.getElementById("phone_credit"))) {
							document.getElementById("phone_credit").remove();
				}
			if(response.total_phone === undefined ) {  var total_phone = 0;} else { var total_phone = response.total_phone;}
			if(response.total_duplicate_phone === undefined ) {  var total_duplicate_phone = 0;} else { var total_duplicate_phone = response.total_duplicate_phone;}
			
			var d1 = document.getElementById('phone_number');
			d1.insertAdjacentHTML('beforeend','<p id="phone_credit"><strong _ngcontent-ghb-c78="" class="small-font"> Total Phone = '+total_phone+' | Duplicates found = '+total_duplicate_phone+' | Credits Needed = '+total_phone+' </strong></p>');
             			
			
		  })
		  .catch((error) => {
			alert("Some error occured")
			console.log("file upload error: ", error)
		  })		
	
  };
  
  const saveButton = (e) => {
    const mapping_fields = document.getElementById("mapping").value;
	const filelist_id = document.getElementById("file_id").value;
	var headerMapping= props.fields.columns.join(",");
		
	    mappingFileData({ filelist_id: filelist_id, headerMapping: headerMapping,mapping:mapping_fields })
		  .then(function (response) {
			window.location.reload();
			
		  })
		  .catch((error) => {
			alert("Some error occured")
			console.log("file upload error: ", error)
		  })	
	

  }
  
const AutosuggetsSelect = () => (
  <Select
    isMulti
    name="fields"
    options={items}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={handleChange}
  />
)
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="close"  onClick={props.onClose}>×</button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para>Excel File</Para>
            <Para2>Merge Field</Para2>
          </ModalTitle>
          <WrapperBody>
            <Para3 id="phone_number">Phone Number</Para3>
		    <AutosuggetsSelect/>
			<input type="hidden" id="mapping" value=""/>
			<input type="hidden" id="file_id" value=""/>
          </WrapperBody>
          <WrapperFooter>
            <ModalButton onClick={props.onClose}>Cancel</ModalButton>
			<ModalButton onClick={saveButton}>Submit</ModalButton>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:768px;
min-height:200px;
left:420px;
top:40px;
z-index:1;
display:flex;
border:2px solid ${COLORS.black};
`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
  width: 1098px;
  background-color: ${COLORS.white};
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 764px;
  height: 60px;
  background-color: ${COLORS.lightblue};
`
const WrapperBody = styled.div`
  padding: 10px;
  display:flex;
  justify-content:space-between;

`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`
const ModalButton = styled.button`
  width: 250px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  &:hover {
    background-color: ${COLORS.lightblue};
    color: ${COLORS.white};
  }
  border: 1px solid #3486eb;
  border-radius: 5px;
  margin-left: 10px;
`
const Para = styled.p`
  color: white;
  font-weight: bold;
  font:size:10px;
  position: relative;
 left:20px;
  top: 15px;
`
const Para2 = styled.p`
  color: white;
  font:size:10px;
  font-weight: bold;
  position:relative;
  right:164px;
  top:15px;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;
border:1px solid black
`;