import React, { useEffect, useState } from "react"
import {getCrmContactInfo, getCrmActions } from "../../../services/crm"

import { getUserID } from "../../../utils/helper"
import TopNav from "../../../components/navbar/topNav"
import Table from "../../../components/navbar/tableItems/crm-address/table"
import styled from "styled-components"
import Pagination from "../../../components/pagination"
import Loading from "../../../components/loading"
import "../../../assets/css/style.css"

import FavIcon from ".././../../components/favicon"
import COLORS from "../../../utils/colors"
import tw from "twin.macro"
import { Link, useParams, useHistory } from "react-router-dom"
import $ from 'jquery';
const CRMAddresses = () => {

  const [AddresesData, setAddresesData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [emptyDataMsg,setemptyDataMsg] = useState("");  
  const [ContactData,setContactData] = useState([]);  
  document.title = "Downloads | AnalyzeREI";
  let { contactID } = useParams();
  useEffect(() => {
    getContactInfo(noOfPages, recordsPerPage);
  }, [])  

  function getContactInfo(noOfPages, recordsPerPage){
	setisLoading(true);
    getCrmContactInfo(
      {
        contactID: contactID,
      },
	   noOfPages
    )
      .then(function (response) {
		setContactData(response.data[0]);
		getAddresesData(noOfPages, recordsPerPage, response.data[0].mailing_address);
		setisLoading(false);
      })
      .catch((error) => {
        console.log("error", error)
      })	  
  }

  function getAddresesData(noOfPages, recordsPerPage, mailing_address){
	setisLoading(true);  
    getCrmActions(
      {
        contactID: contactID,
		action:'get-address',
		mailing_address:mailing_address
      },
	   noOfPages
    )
      .then(function (response) {
        setTotalRecords(response.total_records)
        setAddresesData(response.data)
		setisLoading(false);
		setemptyDataMsg("No Data Found");	
console.log("addreses data", response.data);		
      })
      .catch((error) => {
        console.log("error", error)
      })	  
  }
  
  return (
    <Wrapper>
<Loading show={isLoading} />
      <TopNav />
	  <div className="crm row top">
	   <div className="col-md-12 top_content">
	   <div className="crm_main_heading">{ContactData.owner_1}</div>
	   <div className="crm_main_subheading">Owner 1</div>
	   </div>
	   
		  <div className="col-md-3 contactinfo">
		   <span className="icon">
		   <svg width="20" height="40" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.75393 6.74592C6.68069 6.74592 7.4832 6.41353 8.1389 5.75772C8.79461 5.10202 9.127 4.29973 9.127 3.37286C9.127 2.4463 8.79461 1.6439 8.1388 0.987988C7.48299 0.332392 6.68059 0 5.75393 0C4.82706 0 4.02476 0.332392 3.36906 0.988095C2.71336 1.6438 2.38086 2.4462 2.38086 3.37286C2.38086 4.29973 2.71336 5.10213 3.36917 5.75783C4.02498 6.41343 4.82738 6.74592 5.75393 6.74592Z" fill="#0083E3"/>
				<path d="M11.6555 10.7662C11.6366 10.4933 11.5984 10.1956 11.542 9.88131C11.4852 9.56462 11.412 9.26524 11.3244 8.99162C11.2339 8.7088 11.1108 8.42951 10.9587 8.16186C10.8008 7.88407 10.6153 7.64218 10.4072 7.44312C10.1895 7.23489 9.92304 7.06746 9.6149 6.94534C9.30783 6.82386 8.96753 6.76231 8.60352 6.76231C8.46056 6.76231 8.3223 6.82097 8.0553 6.99481C7.89097 7.10197 7.69876 7.22591 7.48422 7.36299C7.30077 7.47988 7.05225 7.58939 6.74528 7.68855C6.4458 7.78545 6.14172 7.8346 5.8416 7.8346C5.54147 7.8346 5.2375 7.78545 4.93769 7.68855C4.63105 7.5895 4.38253 7.47999 4.19929 7.3631C3.98678 7.2273 3.79446 7.10336 3.62768 6.9947C3.361 6.82086 3.22263 6.76221 3.07967 6.76221C2.71555 6.76221 2.37536 6.82386 2.0684 6.94544C1.76047 7.06735 1.49389 7.23478 1.27604 7.44323C1.06801 7.64239 0.882426 7.88418 0.724724 8.16186C0.572684 8.42951 0.4496 8.70869 0.358996 8.99172C0.271491 9.26535 0.198303 9.56462 0.141462 9.88131C0.0851548 10.1952 0.0469046 10.493 0.0279932 10.7665C0.00940228 11.0345 0 11.3126 0 11.5935C0 12.3245 0.232386 12.9163 0.69064 13.3528C1.14323 13.7835 1.74209 14.002 2.47034 14.002H9.21349C9.94174 14.002 10.5404 13.7836 10.9931 13.3528C11.4514 12.9166 11.6838 12.3247 11.6838 11.5934C11.6837 11.3112 11.6742 11.0329 11.6555 10.7662Z" fill="#0083E3"/>
				</svg>

		   </span>
		   <span className="crm_content"><p className="crm_heading">Owner 1</p> <p className="crm_subheading">{ContactData.owner_1_first_name} {ContactData.owner_1_last_name}</p></span>
		  </div>
		  
		  <div className="col-md-3 contactinfo">
		   <span className="icon">
		   <svg width="20" height="40" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.75393 6.74592C6.68069 6.74592 7.4832 6.41353 8.1389 5.75772C8.79461 5.10202 9.127 4.29973 9.127 3.37286C9.127 2.4463 8.79461 1.6439 8.1388 0.987988C7.48299 0.332392 6.68059 0 5.75393 0C4.82706 0 4.02476 0.332392 3.36906 0.988095C2.71336 1.6438 2.38086 2.4462 2.38086 3.37286C2.38086 4.29973 2.71336 5.10213 3.36917 5.75783C4.02498 6.41343 4.82738 6.74592 5.75393 6.74592Z" fill="#0083E3"/>
				<path d="M11.6555 10.7662C11.6366 10.4933 11.5984 10.1956 11.542 9.88131C11.4852 9.56462 11.412 9.26524 11.3244 8.99162C11.2339 8.7088 11.1108 8.42951 10.9587 8.16186C10.8008 7.88407 10.6153 7.64218 10.4072 7.44312C10.1895 7.23489 9.92304 7.06746 9.6149 6.94534C9.30783 6.82386 8.96753 6.76231 8.60352 6.76231C8.46056 6.76231 8.3223 6.82097 8.0553 6.99481C7.89097 7.10197 7.69876 7.22591 7.48422 7.36299C7.30077 7.47988 7.05225 7.58939 6.74528 7.68855C6.4458 7.78545 6.14172 7.8346 5.8416 7.8346C5.54147 7.8346 5.2375 7.78545 4.93769 7.68855C4.63105 7.5895 4.38253 7.47999 4.19929 7.3631C3.98678 7.2273 3.79446 7.10336 3.62768 6.9947C3.361 6.82086 3.22263 6.76221 3.07967 6.76221C2.71555 6.76221 2.37536 6.82386 2.0684 6.94544C1.76047 7.06735 1.49389 7.23478 1.27604 7.44323C1.06801 7.64239 0.882426 7.88418 0.724724 8.16186C0.572684 8.42951 0.4496 8.70869 0.358996 8.99172C0.271491 9.26535 0.198303 9.56462 0.141462 9.88131C0.0851548 10.1952 0.0469046 10.493 0.0279932 10.7665C0.00940228 11.0345 0 11.3126 0 11.5935C0 12.3245 0.232386 12.9163 0.69064 13.3528C1.14323 13.7835 1.74209 14.002 2.47034 14.002H9.21349C9.94174 14.002 10.5404 13.7836 10.9931 13.3528C11.4514 12.9166 11.6838 12.3247 11.6838 11.5934C11.6837 11.3112 11.6742 11.0329 11.6555 10.7662Z" fill="#0083E3"/>
				</svg>

		   </span>
		   <span className="crm_content"><p className="crm_heading">Owner 2</p> <p className="crm_subheading">{ContactData.owner_2_first_name} {ContactData.owner_2_last_name}</p></span>
		  </div>
		  

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
<svg width="20" height="40" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.94006 6.48653C6.81613 6.56399 6.67671 6.59497 6.55278 6.59497C6.42885 6.59497 6.28943 6.56399 6.1655 6.48653L0 2.72217V7.72583C0 8.79472 0.867508 9.66223 1.9364 9.66223H11.1847C12.2535 9.66223 13.1211 8.79472 13.1211 7.72583V2.72217L6.94006 6.48653Z" fill="#047CFF"/>
<path d="M11.1852 0H1.93694C1.02295 0 0.248394 0.650631 0.0625 1.51814L6.56881 5.48389L13.0596 1.51814C12.8737 0.650631 12.0992 0 11.1852 0Z" fill="#047CFF"/>
</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">Mailing address</p> <p className="crm_subheading">{ContactData.mailing_address}</p></span>
		  </div>
		  


		  <div className="col-md-3 contactinfo">
		   <span className="icon">
<svg width="20" height="40" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.25586 7.54004L6.99944 8.99293L8.74302 7.54004H5.25586Z" fill="#0083E3"/>
<path d="M7.34549 10.1048C7.14637 10.2714 6.85539 10.2714 6.65627 10.1048L4.30859 8.14844V10.7681H9.6932V8.14844L7.34549 10.1048Z" fill="#0083E3"/>
<path d="M6.99995 0C3.14029 0 0 3.14016 0 6.99995V15.9743C0 16.2717 0.241209 16.5127 0.53847 16.5127H3.76923C4.06649 16.5127 4.3077 16.2717 4.3077 15.9743V13.9999H9.6923V15.9743C9.6923 16.2717 9.93351 16.5127 10.2308 16.5127H13.4615C13.7588 16.5127 14 16.2717 14 15.9743V6.99995C13.9999 3.14016 10.8596 0 6.99995 0ZM10.7692 11.3076C10.7692 11.6051 10.528 11.8461 10.2307 11.8461H3.76919C3.47193 11.8461 3.23072 11.6051 3.23072 11.3076V6.99995C3.23072 6.70485 3.47648 6.46148 3.76919 6.46148H10.2307C10.5256 6.46148 10.7692 6.70746 10.7692 6.99995V11.3076ZM10.2307 5.38461H3.76919C3.47193 5.38461 3.23072 5.14359 3.23072 4.84614C3.23072 4.54868 3.47193 4.30767 3.76919 4.30767H10.2307C10.528 4.30767 10.7692 4.54868 10.7692 4.84614C10.7692 5.14359 10.528 5.38461 10.2307 5.38461Z" fill="#0083E3"/>
</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">Mailing city</p> <p className="crm_subheading">{ContactData.mailing_city}</p></span>
		  </div>

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
			<svg width="20" height="40" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.7658 10.4155H5.2872C4.97957 10.4155 4.73047 10.665 4.73047 10.9728C4.73047 11.2804 4.97957 11.53 5.2872 11.53H5.7658C6.07376 11.53 6.32303 11.2804 6.32303 10.9728C6.32303 10.665 6.07376 10.4155 5.7658 10.4155Z" fill="#047CFF"/>
			<path d="M11.2656 2.8028L8.58348 0.120678C8.50616 0.0433584 8.40148 0 8.29201 0H4.81542V0.305487H5.52828V1.20019H4.81542V1.57063H4.31227V0H0.412152C0.184479 0 0 0.184479 0 0.412152V15.578C0 15.8057 0.184479 15.9902 0.412152 15.9902H10.9741C11.2018 15.9902 11.3863 15.8057 11.3863 15.578V3.09427C11.3863 2.98497 11.3429 2.88012 11.2656 2.8028ZM5.52828 1.61926H6.24114V1.24882H6.7443V2.88408H6.24114V2.51363H5.52828V1.61926ZM5.52828 7.7821V6.88772H6.24114V6.51728H6.7443V8.15237H6.24114V7.78193L5.52828 7.7821ZM6.24114 5.52696V5.15668H5.52828V4.26198H6.24114V3.89171H6.7443V5.5268L6.24114 5.52696ZM4.31227 2.57826H4.81542V2.94854H5.52828V3.84307H4.81542V4.21352H4.31227V2.57826ZM4.31227 5.20351H4.81542V5.57395H5.52828V6.46832H4.81542V6.83876H4.31227V5.20351ZM5.52828 11.8967C4.82152 11.8967 4.24863 11.349 4.24863 10.5227C4.24863 8.39406 4.82152 8.41599 5.52828 8.41599C6.23504 8.41599 6.80793 8.39422 6.80793 10.5227C6.80793 11.349 6.23504 11.8967 5.52828 11.8967ZM7.92783 3.68332C7.87227 3.68332 7.81935 3.66123 7.78012 3.62199C7.74104 3.58276 7.71879 3.52951 7.71879 3.47411L7.71912 0.980428L10.422 3.68332H7.92783Z" fill="#047CFF"/>
			</svg>

		   </span>
		   <span className="crm_content"><p className="crm_heading">Mailing Zip</p> <p className="crm_subheading">{ContactData.mailing_zip}</p></span>
		  </div>

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
			<svg width="20" height="40" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.3386 8.56344L9.71021 6.93507C9.12866 6.35352 8.14001 6.58616 7.90739 7.34217C7.73292 7.86559 7.15136 8.15637 6.62796 8.04004C5.46484 7.74926 3.89464 6.23721 3.60386 5.01593C3.42939 4.49251 3.77833 3.91095 4.30173 3.73651C5.05775 3.50388 5.29038 2.51524 4.70882 1.93368L3.08046 0.305318C2.61521 -0.101773 1.91734 -0.101773 1.51025 0.305318L0.405292 1.41028C-0.699668 2.57339 0.521603 5.65565 3.25492 8.38897C5.98825 11.1223 9.0705 12.4017 10.2336 11.2386L11.3386 10.1336C11.7457 9.6684 11.7457 8.97053 11.3386 8.56344Z" fill="#047CFF"/>
			</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">Phone Numbers</p> <p className="crm_subheading">{ContactData.phone_number}</p></span>
		  </div>

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
			<svg width="20" height="40" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.95703 0V1.70022H7.65725V2.55031C8.25231 2.46531 8.76237 2.46531 9.35747 2.55031V1.70022H11.0577V0H5.95703Z" fill="#108BE5"/>
			<path d="M14.2885 5.61246L14.8836 5.0174L15.5637 5.69749L16.7538 4.50734L14.2035 1.95703L13.0134 3.14719L13.6934 3.82724L12.9284 4.59233C8.84786 2.12702 3.57721 3.40218 1.19694 7.48267C-1.18333 11.5632 0.0917822 16.6638 4.08728 19.1291C8.08277 21.5944 13.3534 20.3192 15.7337 16.2388C17.8589 12.7533 17.2639 8.3328 14.2885 5.61246ZM8.50779 17.7689C5.1924 17.7689 2.55709 15.1336 2.55709 11.8182C2.55709 8.50283 5.1924 5.86749 8.50779 5.86749V11.8182H14.4585C14.4585 15.1336 11.8232 17.7689 8.50779 17.7689Z" fill="#108BE5"/>
			</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">times called</p> <p className="crm_subheading">{ContactData.times_called}</p></span>
		  </div>

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
				<svg width="20" height="40" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.16667 0C3.21494 0 0 3.21494 0 7.16667C0 9.08631 1.16301 11.5515 3.45671 14.4934C4.50756 15.8378 5.64557 17.1117 6.8634 18.3069C6.94505 18.385 7.05368 18.4286 7.16667 18.4286C7.27965 18.4286 7.38828 18.385 7.46993 18.3069C8.68776 17.1117 9.82577 15.8378 10.8766 14.4934C13.1703 11.5515 14.3333 9.08631 14.3333 7.16667C14.3333 3.21494 11.1184 0 7.16667 0ZM7.16667 12.2857C4.34388 12.2857 2.04762 9.98945 2.04762 7.16667C2.04762 4.34388 4.34388 2.04762 7.16667 2.04762C9.98945 2.04762 12.2857 4.34388 12.2857 7.16667C12.2857 9.98945 9.98945 12.2857 7.16667 12.2857Z" fill="#0083E3"/>
				<path d="M10.6791 6.55197L7.4614 3.6268C7.38063 3.55338 7.2754 3.5127 7.16625 3.5127C7.0571 3.5127 6.95187 3.55338 6.8711 3.6268L3.65341 6.55197C3.57845 6.62014 3.52938 6.71214 3.51451 6.81237C3.49965 6.91259 3.51992 7.01488 3.57188 7.10187C3.61287 7.16809 3.67032 7.22257 3.73863 7.26C3.80694 7.29742 3.88377 7.31651 3.96165 7.3154H4.38734V10.0943C4.38734 10.2107 4.43357 10.3223 4.51585 10.4046C4.59814 10.4869 4.70974 10.5331 4.82612 10.5331H5.84992C5.96629 10.5331 6.0779 10.4869 6.16019 10.4046C6.24247 10.3223 6.2887 10.2107 6.2887 10.0943V8.48547C6.2887 8.3691 6.33493 8.2575 6.41721 8.17521C6.4995 8.09292 6.6111 8.0467 6.72748 8.0467H7.60503C7.7214 8.0467 7.833 8.09292 7.91529 8.17521C7.99757 8.2575 8.0438 8.3691 8.0438 8.48547V10.0943C8.0438 10.2107 8.09003 10.3223 8.17232 10.4046C8.2546 10.4869 8.36621 10.5331 8.48258 10.5331H9.50639C9.62276 10.5331 9.73436 10.4869 9.81665 10.4046C9.89893 10.3223 9.94516 10.2107 9.94516 10.0943V7.3154H10.3708C10.4487 7.31651 10.5256 7.29742 10.5939 7.26C10.6622 7.22257 10.7196 7.16809 10.7606 7.10187C10.8126 7.01488 10.8328 6.91259 10.818 6.81237C10.8031 6.71214 10.7541 6.62014 10.6791 6.55197Z" fill="#0083E3"/>
				</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">Property Address</p> <p className="crm_subheading">{ContactData.addreses}</p></span>
		  </div>
          <div className="col-md-3 contactinfo emptyDIv"></div>
		  <div className="col-md-3 contactinfo">
		   <span className="icon">
				<svg width="20" height="40" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.1886 2.41357H10.1488C9.66469 2.41357 9.20967 2.60208 8.8674 2.94438L1.73979 10.072C1.39749 10.4143 1.20898 10.8694 1.20898 11.3534C1.20898 11.8374 1.39749 12.2925 1.73979 12.6348L6.77952 17.6744C7.13277 18.0276 7.59683 18.2042 8.06086 18.2042C8.52488 18.2042 8.98894 18.0276 9.3422 17.6743L16.4699 10.5466C16.8122 10.2043 17.0007 9.74928 17.0007 9.26524V4.22565C17.0007 3.22648 16.1878 2.41357 15.1886 2.41357ZM12.7724 8.45397C11.7732 8.45397 10.9603 7.64106 10.9603 6.64186C10.9603 5.64265 11.7732 4.82975 12.7724 4.82975C13.7716 4.82975 14.5845 5.64265 14.5845 6.64186C14.5845 7.64106 13.7716 8.45397 12.7724 8.45397Z" fill="#108BE5"/>
				<path d="M13.9796 0H8.93979C8.45571 0 8.00068 0.188504 7.65841 0.530807L0.530807 7.65845C0.188504 8.00072 0 8.45578 0 8.93983C0 9.31843 0.115784 9.67905 0.329856 9.98209C0.472061 9.70437 0.657685 9.44727 0.884701 9.22032L8.01227 2.09268C8.58269 1.52226 9.34112 1.20809 10.1479 1.20809H15.1876C15.3633 1.20809 15.5354 1.22402 15.703 1.253C15.4668 0.526646 14.7837 0 13.9796 0Z" fill="#108BE5"/>
				</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">tages</p> <p className="crm_subheading">0</p></span>
		  </div>

		  <div className="col-md-3 contactinfo">
		   <span className="icon">
				<svg width="20" height="40" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M12.535 6.0295H5.04092C4.78916 5.04031 3.78315 4.4425 2.79393 4.69426C2.13791 4.86123 1.62566 5.37348 1.45869 6.0295H0.464267C0.207855 6.0295 0 6.23736 0 6.49377C0 6.75018 0.207855 6.95801 0.464267 6.95801H1.45871C1.71047 7.94721 2.71648 8.54501 3.70571 8.29325C4.36172 8.12628 4.87398 7.61403 5.04095 6.95801H12.535C12.7914 6.95801 12.9992 6.75015 12.9992 6.49374C12.9992 6.23733 12.7914 6.0295 12.535 6.0295Z" fill="#108BE5"/>
				<path d="M12.535 1.39278H11.0763C10.8245 0.403587 9.81852 -0.194218 8.82929 0.0575427C8.17328 0.224512 7.66102 0.736765 7.49405 1.39278H0.464267C0.207855 1.39278 0 1.60064 0 1.85705C0 2.11346 0.207855 2.32132 0.464267 2.32132H7.49405C7.74581 3.31051 8.75182 3.90832 9.74104 3.65656C10.3971 3.48959 10.9093 2.97734 11.0763 2.32132H12.535C12.7914 2.32132 12.9992 2.11346 12.9992 1.85705C12.9992 1.60064 12.7914 1.39278 12.535 1.39278Z" fill="#108BE5"/>
				<path d="M12.535 10.3928H10.1478C9.89599 9.40359 8.88998 8.80578 7.90076 9.05754C7.24474 9.22451 6.73249 9.73676 6.56552 10.3928H0.464267C0.207855 10.3928 0 10.6006 0 10.857C0 11.1134 0.207855 11.3213 0.464267 11.3213H6.56555C6.81731 12.3105 7.82331 12.9083 8.81254 12.6565C9.46856 12.4896 9.98081 11.9773 10.1478 11.3213H12.535C12.7914 11.3213 12.9992 11.1134 12.9992 10.857C12.9992 10.6006 12.7914 10.3928 12.535 10.3928Z" fill="#108BE5"/>
				</svg>


		   </span>
		   <span className="crm_content"><p className="crm_heading">Custom Fields</p> <p className="crm_subheading">1</p></span>
		  </div>		  
	  </div><div className="row crm addresses">
	  <div className="CRM_container">
  <ul><li><Link to='#'>Activity</Link></li> <li> <Link to={'/crm-phone/'+contactID}>Phone Numbers</Link></li> <li className="active"><Link to={'/crm-addresses/'+contactID}>Addresses</Link></li><li><Link to='#'>Custom Fields</Link></li> <li><Link to='#'>Related Contacts</Link></li><li><Link to='#'>Bucke Newt</Link></li></ul>
	  </div>	
	  
      <Table files={AddresesData}></Table>
	  
      <WrapperPagination>
        <Pagination
          getPageData={ContactData}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
         >
          </Pagination>
      </WrapperPagination> </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
  text-decoration:none;
  display:none;
  cursor:pointer;
`
const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
`
const Label = styled.label`
  margin-left: 20px;
  font-size: 18px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: 'Montserrat';
`

const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 155px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
`
export default CRMAddresses
