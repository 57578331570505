import styled from "styled-components"

const Button = (props) => {
  return (
    <Element onClick={props.onClick} className={props.style} disabled={props.disabled}>
      {props.children}
    </Element>
  )
}

const Element = styled.button`
  display: inline-block;
  border-radius: 5px;
  padding: 0.5rem 0;
  margin: 0.5rem 1rem;
  width: 11rem;Ï
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
`
export default Button
