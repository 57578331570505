import React, { useEffect, useState } from "react";
import TopNav from "../../components/navbar/topNav";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Loading from "../../components/loading";
import { useHistory } from "react-router-dom"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Upgradecomponent from "../../components/upgrade-plan";
const Tools = (props) => { 
let history = useHistory()
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(false)  
  document.title = "Tools | AnalyzeREI"  
   const user = JSON.parse(localStorage.getItem('userData'));
   const capability = user.capability;
   const PhoneValidation = capability.includes('Phone Validation');
   const DataManipulator = capability.includes('Data Manipulator');
   const ToolsMenu =capability.includes('Tools');    
   if(ToolsMenu==false) {
	  return (<Upgradecomponent/>); 
   } 
   function validateUser(e, tools){
	   if(tools=="phone-validation" && PhoneValidation==false ){  e.preventDefault();  toast.error("This feature is unavailable for your plan. Please upgrade"); }
	   if(tools=="data-manipulator" && DataManipulator==false ){  e.preventDefault(); toast.error("This feature is unavailable for your plan. Please upgrade"); }
	   return false;
   }
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
      <div className="payments-method-page width-inpayment-set">
        <div className="wrapper-payments-method-page">
          <div className="container-fluid">
                    <div className="row">
                        <div className="headding-payment">
                           <h1>Tools</h1>
                        </div>   
						
                        <div className="col-md-4 tools">
                          <div className="my-radio-button-payment">
                            <Link to='/phone-validation' onClick={(e) => validateUser(e,"phone-validation")}>	
                            <div className="card tools">
							  <div className="icon">
							  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>
							  </div>
							  <div className="tool_content"><span className="tools_heading">Phone Validation</span>
							  <p className="tools_intro">Verify mobile, landline and disconnected phone numbers. Also match caller ID to contact names for higher accuracy</p></div>
                             </div> </Link>
                          </div>

						  
                        </div>
						
						
                        <div className="col-md-4 tools">
                          <div className="my-radio-button-payment">
                            <Link to='/data-manipulator' onClick={(e) => validateUser(e,"data-manipulator")}>
                            <div className="card tools">
							  <div className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-fill" viewBox="0 0 16 16">
  <path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z"/>
</svg>
								</div>
							  <div className="tool_content"><span className="tools_heading">Data Manipulator</span>
							  <p className="tools_intro">Parse your data from your CSV into an easily to understand organized format. Use advanced search, apply tags, filters and presets to fit your needs.</p></div>
                             </div></Link>
                          </div>

						  
                        </div>						
							

                    </div>
                  </div>
                </div>
              </div>
         
   
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export default Tools;
