import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { useHistory } from "react-router"

import TextInput from "../../components/textInput"
import Button from "../../components/button"
import Text from "../../components/text"
import Heading from "../../components/heading"
import Image from "../../components/image"
import Logo from "../../assets/images/p-11.png"
import { signup } from "../../services/auth"
import COLORS from "../../utils/colors"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
const Signup = () => {
  let history = useHistory()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [fullName, setfullName] = useState("")
  document.title = "Signup | AnalyzeREI"
  function onChange(event) {
    if (event.target.name === "email") {
      setEmail(event.target.value)
    }
    if (event.target.name === "password") {
      setPassword(event.target.value)
    }
    if (event.target.name === "name") {
      setfullName(event.target.value)
    }
  }

  function gotoLogin() {
    history.push("/login")
  }

  function gotoInstagram(){
    history.push("/data-validation")
  }

  function onSubmit() {
    let registerData = {
      email: email,
      password: password,
	  full_name:fullName
    }
    signup(registerData)
      .then(function (response) {
		if(response.status==true) {toast.success(response.message); 
		history.push("/");
		} else { toast.error(response.message); }
      })
      .catch((error) => {
        alert("Some error occured")
        console.log("registor error: ", error)
      })
  }

  return (
    <Wrapper>
      <LeftSide>
        <LogoWrapper>
          <Image src={Logo} width={"75px"} height={"75px"} />
        </LogoWrapper>
        <MediaWrapper>
          <MediaButton onClick={gotoInstagram} className='btn btn-primary'>
            Follow us on Instagram
          </MediaButton>
          <ContactLink onClick={gotoInstagram}>Visit Our Page</ContactLink>
        </MediaWrapper>
        <Heading color={"#ffffff"} size={"24px"}>
          Data To enrich your online business
        </Heading>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat
        </Text>

        <LogoWrapper>
          <ClientImageWrapper>
            <Image src={Logo} width={"100px"} height={"100px"} />
          </ClientImageWrapper>
          <ClientImageWrapper>
            <Image src={Logo} width={"100px"} height={"100px"} />
          </ClientImageWrapper>
          <ClientImageWrapper>
            <Image src={Logo} width={"100px"} height={"100px"} />
          </ClientImageWrapper>
        </LogoWrapper>
      </LeftSide>
      <RigthSide>
        <ButtonWrapper>
          <Button onClick={gotoLogin} style='btn btn-primary'>
            Login
          </Button>
        </ButtonWrapper>
        <FormWrapper>
          <Text>Get ready to super charge your data!</Text>
          <TextInput
            label={"Full Name"}
            value={fullName}
            onChange={onChange}
            name='name'
          />
          <TextInput
            label={"Email"}
            value={email}
            onChange={onChange}
            name='email'
          />

          <TextInput
            type={"password"}
            label={"Password"}
            value={password}
            onChange={onChange}
            name='password'
          />

          <Button text='Signup' onClick={onSubmit} style='btn-primary'>
            Signup
          </Button>
        </FormWrapper>
      </RigthSide>
    </Wrapper>
  )
}

export default Signup

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  background: black;
`

const LeftSide = styled.div`
  width: 50%;
  height 100%;
  padding-left: 15%;
  color: ${COLORS.white};
  padding-right: 5%;

`

const LogoWrapper = styled.div`
  display: flex;
  margin-top: 10%;
  float: left;
`

const ClientImageWrapper = styled.div`
  padding-left: 20px;
`

const MediaWrapper = styled.div`
  margin-right: 10%;
  border-radius: 50px;
  background: #424242;
  height: 60px;
  margin-top: 40%;
`
const MediaButton = styled.button`
  height: 50px;
  margin-top: 5px;
  margin--bottom: 5px;
  margin-left: 5px;
  font-weight: bold;
  border-radius: 50px;
`

const RigthSide = styled.div`
  width: 50%;
  height 100%;
  padding-left: 0%;

`

const ContactLink = styled.span`
  float: right;
  padding-right: 15px;
  font-weight: bold;
  padding-top: 20px;
`

const FormWrapper = styled.div`
  ${tw`
        w-3/5
        rounded
        shadow-2xl
        border-2
    `}
  float left;
  background: white;
  padding: 30px 50px 50px;
  margin: auto;
  margin-top: 20%;
  text-align: center;
`

const ButtonWrapper = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  float: right;
`
