import React, { useState } from "react"
import styled from "styled-components"
import DownloadIcon from "../../../../assets/images/download-icon.png"
import ProgressBar from "../../../progressbar"
import COLORS from "../../../../utils/colors"
import FavIcon from "../../../../components/favicon"
import tw from "twin.macro"
import { deleteSelectedFile, downloads } from "./../../../../services/dataValidation";
import { getUserID } from "./../../../../utils/helper"
import ReactTooltip from "react-tooltip";
import $ from 'jquery';
const Item = (props) => {
  
  const [selectedExceldata,setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [userExcelData, setExcelUserData] = useState([])
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [getTotalExcelData, setTotalExcelData] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [show, setShow] = useState(0)
  function formatDate(date) {
    const getDateSuffix = (date) => {
      const thExpections = (date) =>
        [11, 12, 13].some((exception) => exception === date)
      const lastDigit = date % 10
      if (thExpections(date) || lastDigit === 0 || lastDigit > 3)
        return `${date}th`
      if (lastDigit === 1) return `${date}st`
      if (lastDigit === 2) return `${date}nd`
      return `${date}rd`
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const dateMonth = new Date(date)
    const dateAndMonth = ` ${monthNames[dateMonth.getMonth()]} ${getDateSuffix(
      dateMonth.getDate()
    )} `
    const today = new Date(date)
    const time = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(today)
    return dateAndMonth + time
  } 


  let disabled = false;
  if(props.percentage==100) {
	  disabled=true  
  }
const tooltipID = "tooltip_ID_"+props.id;
function onChange(event){
    const allCheckboxChecked = event.target.checked
	var checkedNum = $('input[name="slectedCheckBox[]"]:checked').length;
	if (!checkedNum) {	$(".DeleteAction").hide(); } else { $(".DeleteAction").show(); }
}
  return (
   <React.Fragment>
    <tr>
	<td><input type="checkbox" name="slectedCheckBox[]" className="deleteCheckbox downloads" value={props.id} className="selectAllCheckbox" onChange={onChange} /> <ParaTwo data-tip data-for={tooltipID} className="itemHeading downloads">{props.name}</ParaTwo> 
	<ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>{props.name} </ReactTooltip></td>
	<td> <Para>{props.category}</Para> </td>
	<td> <span>{props.line_type}</span> <span className="total_row">{props.total_rows}</span></td>
	<td><Para>{props.match}</Para></td>
	<td> <InnerWrapper className="status_progress"> <ProgressBar percentage={props.percentage} /></InnerWrapper></td>
	<td>{props.updated_at}</td>
	<td> <DownloadIconWrapper className="download-icon-validation table-cols"> { disabled  && <a href={props.download_url} target="_blank" className="download-icon"><Icon src={DownloadIcon} width='34px' height='34px'/></a> } </DownloadIconWrapper></td>
	</tr>
	<tr className="emptyrow">&nbsp;</tr>
	</React.Fragment>
    /*
    <div>

      <div>
       
      </div>
	  
      <Wrapper>    	  
        <ItemWrapper>
          
          <Heading className="table-cols selectCheck"><input type="checkbox" name="slectedCheckBox[]" className="deleteCheckbox downloads" value={props.id} className="selectAllCheckbox" onChange={onChange}/></Heading>
          <Heading className="table-cols filename">
		   
		    <ParaTwo data-tip data-for={tooltipID} className="itemHeading downloads fineName">{props.name}</ParaTwo>
            
			<ReactTooltip id={tooltipID} aria-haspopup='true' role='example'>
			  {props.name}
            </ReactTooltip>
            <Para>{props.size}</Para>
          </Heading>
          <Mobile className="format_col table-cols">
            <Para>{props.category}</Para>
          </Mobile>
          <Mobile  className="line_type_col table-cols">
            <span className="Linetype">{props.line_type}</span>
			<span className="total_row lintype">{props.total_rows}</span>
          </Mobile>
		  
          <Mobile className="table-cols">
            <Para>{props.match}</Para>
          </Mobile>
		  
          <InnerWrapper className="status_progress table-cols">
            <ProgressBar percentage={props.percentage} />
          </InnerWrapper>		  
          <Mobile className="table-cols">
            <Para>{props.updated_at}</Para>
          </Mobile>
          <DownloadIconWrapper className="download-icon-validation table-cols"> 
		  { disabled  &&
		  <a href={props.download_url} target="_blank" className="download-icon"><Icon src={DownloadIcon} width='34px' height='34px'/></a> }
          </DownloadIconWrapper>
        </ItemWrapper>
      </Wrapper>
    </div>*/
  )
  
}

export default Item

const Icon = styled.img`
  margin-left: 0px;
  padding-top: 5px;
  cursor: pointer;
`

const BookIcon = styled.img`
 
  cursor: pointer;
  padding-top:9px;
`
const Wrapper = styled.div`
  z-index: 0;
  display: flex;
  height: 60px;
  background-color: ${COLORS.white};
  border-radius: 19px;
  margin: auto;
  margin-left: 3%;
  margin-right: 3%;
  margin-bottom: 10px;
  ${tw`
    shadow-lg
  `}
`

const ItemWrapper = styled.div`
  position: relative;
  top: 10%;
  display: block;
  width: 100%;
  
`

const InnerWrapper = styled.span`
  display: inline-block;
  font-size: 10px;
`

const InnerWrapperIcon = styled.span`
  display: inline-block;
  font-size: 10px;
  margin-left:0px
`

const DownloadIconWrapper = styled.span`
  margin-top: 10px;

`

const Heading = styled.label`
  margin-top: 0px;
  color: ${COLORS.username};
  font-family: Montserrat;
  overflow: hidden;
  text-overflow: ellipse;
  whitespace: nowrap;
  font-size: 13px;
  width:135px;
  padding-left:10px;
`

const Para = styled.p`
  color: ${COLORS.createdat};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const Mobile = styled.label`
  margin-top: 10px;
  color: ${COLORS.blue};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

const Num = styled.p`
  color: #5B5B5B;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
`
const Checkbox = styled.input`
  margin-top: 25px;
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;
  margin-right:-20px;
  float: left;
    margin-left: -16px;
    font-size: 15px;
`
const DeleteIcon = styled.span`
  margin-top: 20px;\
  cursor: pointer;
  color: ${COLORS.red};
`
const ParaTwo = styled.p`
  color: ${COLORS.username};
  font-family: Montserrat;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  padding-top
  word-break: break-all;
`


















