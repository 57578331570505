import React, { useState } from "react"
import styled from "styled-components"
import FavIcon from "../favicon"
import COLORS from "../../utils/colors"
import { uploadExcelFile, getAvailbleCreadit } from "../../services/dataValidation"
import { getUserID } from "../../utils/helper"
import Modal from "../modal"
import tw from "twin.macro"
import { useHistory } from "react-router-dom"
const Search = (props) => {
     const userID = getUserID();
	 let history = useHistory()
	  const [show, setShow] = useState(false)
	  const [filedata, setfiledata] = useState(false)
  const onFileChange = (event) => {
	  history.push("upload-process-revamp");
  }
  const searchItem = (e) => {
    props.search(e.target.value)
  }
  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false)
        }}
        show={show}
		fields={filedata}
      />
      <WrapperInput>
        <SearchIcon>
          <FavIcon class='fas fa-search'></FavIcon>
        </SearchIcon>
        <Input type='text' placeholder='Search' onChange={searchItem}></Input>
      </WrapperInput>
      <Credit>
        <Inner>
          <Icon>
            <FavIcon class='far fa-star'></FavIcon>
          </Icon>
          <AvailableCredit>Available Credit</AvailableCredit>
        </Inner>
        <Data>{props.availableCreadit}</Data>
      </Credit>
      <RightButton onClick={onFileChange}>
        <UploadIcon>
          <FavIcon class='fas fa-upload'></FavIcon>
        </UploadIcon>
        <Label>
          Upload CSV
          <Upload
            id='file-selecter'
            type='Button'
            size='80'
          />
        </Label>
      </RightButton>
    </Wrapper>
  )
}

export default Search

const Wrapper = styled.div`
  display: flex;
  margin-left: 0%;
  margin-right: 2%;
  justify-content: space-between;
`
const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
`
const Label = styled.label`
  margin-left: 20px;
  font-size: 20px;
  display: table;
  cursor: pointer;
  color: #fff;
`
const Input = styled.input`
  width: 100%;
  height: 50px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 40px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
   
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`
const Credit = styled.div`
  display: block;
`

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`
const RightButton = styled.div`
  display: flex;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  margin-right:13px;
  cursor: pointer;

  ${tw`
    shadow-lg
  `}
`
const Inner = styled.div`
  display: flex;
`
const Data = styled.h6`
  color: ${COLORS.blue};
  dispaly: block;
  text-align:center;
  font-weight: 800;
  font-family: Montserrat;
  font-size:24px;
  padding-left:15px;
`
const SearchIcon = styled.span`
  position: absolute;
  color: ${COLORS.grey};
  display: block;
  padding: 10px;
  margin-left: 5px;
  padding-top: 13px;
  color: ${COLORS.grey};
  min-width: 50px;
`
const WrapperInput = styled.div`
  width: 50%;
  margin-bottom: 10px;
  margin-left: 35px;
`
