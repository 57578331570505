import { BASE_URL, API_URL } from "../config"

export async function login(requestObj) {
  const url = BASE_URL + "login"
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log('error: ', error);
      return error;
    })
}

export async function register(requestObj) {
  const url = BASE_URL + "register"
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log('error: ', error);
      return error;
    })
}


export async function signup(requestObj) {
  const url = API_URL + "signup"
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log('error: ', error);
      return error;
    })
}

export async function ActiveAccount(requestObj) {
  const url = BASE_URL + "active-account"
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log('error: ', error);
      return error;
    })
}
