import { BASE_URL } from "../config"
import { getAuthorizationToken } from "../utils/helper"
export async function getUserByIdCall(id) {
const url = BASE_URL + "getUserById/"+id;
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    }
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function upddateProfileinfoCall(requestObj, id) {
  const url = BASE_URL + "updateProfile/"+id;
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function upddateProfileImageCall(requestObj, id) {
  const url = BASE_URL + "updateProfilePicture/"+id;
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function ResetPassword(requestObj, page) {
  const url = BASE_URL + "reset-profile-password";
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}