import "./App.css"
import Login from "./pages/login"
import Signup from "./pages/signup"
import NoComponent from "./pages/noComponent"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import NavbarRoutes from "./Router"
import { getUserID } from  "../src/utils/helper"
import Payments from "./pages/payments/index"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function App() {
  const userID = getUserID()
  const loggedIn = userID > 0 ? true : false
  return (
    <Router>
      <Switch>
        <Route
          exact
          path='/'
          render={() =>
            loggedIn ? (
              <Redirect to='/data-validation' />
            ) : (
              <Redirect to='/login' />
            )
          }
        />
        <Route path='/login' component={Login} />
        <Route path='/signup' component={Signup} />
        {/* <Route path='/payments' component={Payments} /> */}
        <NavbarRoutes />
        <Redirect to='/' />
        <Route path='*' exact component={NoComponent} />
      </Switch>
    </Router>
  )
}

export default App
