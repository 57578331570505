import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { mnipulatorMappingFields } from "./../../../services/dataValidation"
import { getUserID } from "./../../../utils/helper"
import TopNav from "./../../../components/navbar/topNav"
import Table from "./../../../components/navbar/tableItems/data-manipulator/table"
import styled from "styled-components"
import Search from "./../../../components/search"
import Pagination from "./../../../components/pagination"
import Loading from "./../../../components/loading"
import "./../../../assets/css/style.css"
import Modal from "./../../../components/lead-modal"
import FavIcon from "./../../../components/favicon"
import COLORS from "./../../../utils/colors"
import tw from "twin.macro"
import Select from 'react-select'
import Button from "./../../../components/button";
import { useHistory } from "react-router-dom"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
const MappingFields = () => {
  
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [totalMailingAddress, settotalMailingAddress] = useState(false)
  const [uniqueMmailingAddress, setuniqueMmailingAddress] = useState(false)
  const [duplicateMmailingAddress, setduplicateMmailingAddress] = useState(false)
  const [oldMailing, setoldMailing] = useState(false)
  const [outofnew, setoutofnew] = useState(false)   
  const [phoneFound, setphoneFound] = useState(false)  
  const [addressesFound, setaddressesFound] = useState(false)  
  const [matchrows, setmatchrows] = useState(false)  
  const [newrows, setnewrows] = useState(false)   
  const [mailingAddress, setmailingAddress] = useState(false)  
  const [TotalMatchRows, setTotalMatchRows] = useState(false)
  const [NewRowsFound, setNewRowsFound] = useState(false)    
  const [MailingAlreadyExists, setMailingAlreadyExists] = useState(false)  
  const [NewPhoneNumberFound, setNewPhoneNumberFound] = useState(false) 
  const [Newaddressfound, setNewaddressfound] = useState(false)   
  const [mergeCsv, setmergeCsv] = useState(false)     
  let history = useHistory()
  const backBtn = () => {
	  let BackUrl="/data-manipulator";
if(localStorage.getItem('mergeCsv')!=null  || localStorage.getItem('mergeCsv')==''){ BackUrl =  "/data-manipulator-contacts-merge-csv/"+localStorage.getItem('mergeCsv'); }
	  history.push(BackUrl);
  }
    
  useEffect(() => {
    mnipulatorData()
  }, [])  
  
  function mnipulatorData() {
  const mappingFields = JSON.parse(localStorage.getItem('mappingData'));
  settotalMailingAddress(mappingFields.totalMmailingAddress);
  setuniqueMmailingAddress(mappingFields.uniqueMmailingAddress);	
  setduplicateMmailingAddress(mappingFields.duplicateMmailingAddress)
  setoldMailing(mappingFields.oldMailing )
  setoutofnew(mappingFields.outofnew)
  setmatchrows(mappingFields.matchrows)
  setphoneFound(mappingFields.phoneFound)
  setnewrows(mappingFields.newrows);
  setaddressesFound(mappingFields.addressesFound);
  setmailingAddress(mappingFields.mailingAddress);
  setTotalMatchRows(mappingFields.matchrows);
  setNewRowsFound(mappingFields.newrows);
  setMailingAlreadyExists(mappingFields.oldMailing);  
  setNewPhoneNumberFound(mappingFields.phoneFound); 
  setNewaddressfound(mappingFields.outofnew);	
  setmergeCsv(localStorage.getItem('mergeCsv'));
if(JSON.parse(localStorage.getItem("mappedData"))=="" || JSON.parse(localStorage.getItem("mappedData"))==null){
	localStorage.setItem("mappedData", JSON.stringify(mappingFields.mappedValues));
  }  }
  
  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    mnipulatorData(1, perPageValue)
  }
  
 
  let selectedData =[];
  let options = JSON.parse(localStorage.getItem('mappingFields'));
  options.unshift({label: "Select", value: ""});

   let mappedData1 = JSON.parse(localStorage.getItem("mappedData"));
  
	const SelectChangeHandle = (selectedOption, name) => {
		const value = selectedOption.value;
		var option_name = name.name;
		selectedData.push({[option_name]: value});
		mappedData1[option_name]=value;
		localStorage.setItem("mappedData", JSON.stringify(mappedData1));	
	}
	
 function saveMappingData() {
   const mappingFields = JSON.parse(localStorage.getItem('mappingData'));
   const mappedData = JSON.parse(localStorage.getItem('mappedData'));
   const action = mappingFields.action;
   const version_id = mappingFields.version_id;
   const filelist_id = mappingFields.filelist_id;  
   let action1 = 'insert';
   if(localStorage.getItem("mergeCsv")!=null){
	    action1 = 'update';
   }
 		 var i = 0;
		  if (i == 0) {
			i = 1;
			var elem = document.getElementById("UploadBar");
			var width = 1;
			var id = setInterval(frame, 10);
			function frame() {
			  if (width >= 80) {
				clearInterval(id);
				i = 0;
			  } else {
			  if(width<=80){
				width++;
				elem.style.width = width + "%";
				}
				$("#progresscount").html(width+"%");
			  }
			}
		  }  
	mnipulatorMappingFields({
		action:action1,
		file_id:filelist_id,
		file_version_id:version_id,
		update_column_match:'mailing_address',
		update_column_behavior:'not_blank',
		mapping:[mappedData]
	}) .then(function (response) { 		  
						var j = 1;
						var elem2 = document.getElementById("UploadBar");
						var width1 = 81;
						var id = setInterval(frame2, 100);
						function frame2() {
						  if (width1 >= 100) { $("#count").html("100%");
							clearInterval(id);
							j = 0;
					 if(response.status!=false) { 
					 localStorage.setItem("reportData", JSON.stringify(response.reporLog));
		             history.push("/report");	
					 }  else { toast.error(response.message);	 } 							
							
						  } else {
							width1++;
							elem2.style.width = width1 + "%";
							$("#progresscount").html(width1+"%"); }
						  
						}
								
				  })
	  
      .catch((error) => {
		 setisLoading(false);
        //console.log("error", error)
      })
 }
 let Owner1firstNameDefaultSelect =  [{  label:"Select", value:""}];
 let Owner1lastNameDefaultSelect =  [{  label:"Select", value:""}];
 let Owner2firstNameDefaultSelect =  [{  label:"Select", value:""}];
 let Owner2lastNameDefaultSelect = [{  label:"Select", value:""}];
 let OwnerfullNameDefaultSelect = [{  label:"Select", value:""}];
 let MailingAddressDefaultSelect =  [{  label:"Select", value:""}];
 let MailingcityDefaultSelect =[{  label:"Select", value:""}];
 let MailingstateDefaultSelect =  [{  label:"Select", value:""}]; 
 let MailingzipDefaultSelect =  [{  label:"Select", value:""}]; 
 let AddressDefaultSelect = [{  label:"Select", value:""}];  
 let CityDefaultSelect = [{  label:"Select", value:""}];   
 let StateDefaultSelect =  [{  label:"Select", value:""}];
 let ZipDefaultSelect =  [{  label:"Select", value:""}]; 
 let PhoneDefaultSelect =  [{  label:"Select", value:""}]; 
 let PhonePositionDefaultSelect =  [{  label:"Select", value:""}];
 let CarrierSelectDefaultSelect =  [{  label:"Select", value:""}]; 
 let CallerIDDefaultSelect =  [{  label:"Select", value:""}]; 
 let CallerIDMatchDefaultSelect =  [{  label:"Select", value:""}]; 
 let LineTypeDefaultSelect =  [{  label:"Select", value:""}];
 let ConnectedDefaultSelect =  [{  label:"Select", value:""}];  
 let LastDisposistionDefaultSelect =  [{  label:"Select", value:""}]; 
 let TimecalledDefaultSelect =  [{  label:"Select", value:""}]; 
 let TagDefaultSelect =  [{  label:"Select", value:""}]; 
	if(mappedData1!=null){
		if(mappedData1.owner_1_first_name!="") {  Owner1firstNameDefaultSelect = [{  label: mappedData1.owner_1_first_name, value:  mappedData1.owner_1_first_name}]; }
		if(mappedData1.owner_1_last_name!="") {  Owner1lastNameDefaultSelect = [{  label: mappedData1.owner_1_last_name, value:  mappedData1.owner_1_last_name}]; }
		if(mappedData1.owner_2_first_name!="") {  Owner2firstNameDefaultSelect = [{  label: mappedData1.owner_2_first_name, value:  mappedData1.owner_2_first_name}]; }
		if(mappedData1.owner_2_last_name!="") {  Owner2lastNameDefaultSelect = [{  label: mappedData1.owner_2_last_name, value:  mappedData1.owner_2_last_name}]; }
		if(mappedData1.full_name_of_owner!="") {  OwnerfullNameDefaultSelect = [{  label: mappedData1.full_name_of_owner, value:  mappedData1.full_name_of_owner}]; }
		if(mappedData1.mailing_address!="") {  MailingAddressDefaultSelect = [{  label: mappedData1.mailing_address, value:  mappedData1.mailing_address}]; }
		if(mappedData1.mailing_city!="") {  MailingcityDefaultSelect = [{  label: mappedData1.mailing_city, value:  mappedData1.mailing_city}]; }
		if(mappedData1.mailing_state!="") {  MailingstateDefaultSelect = [{  label: mappedData1.mailing_state, value:  mappedData1.mailing_state}]; }
		if(mappedData1.mailing_zip!="") {  MailingzipDefaultSelect = [{  label: mappedData1.mailing_zip, value:  mappedData1.mailing_zip}]; }
		if(mappedData1.address!="") {  AddressDefaultSelect = [{  label: mappedData1.address, value:  mappedData1.address}]; }
		if(mappedData1.city!="") {  CityDefaultSelect = [{  label: mappedData1.city, value:  mappedData1.city}]; }
		if(mappedData1.state!="") {  StateDefaultSelect = [{  label: mappedData1.state, value:  mappedData1.state}]; }
		if(mappedData1.zip!="") {  ZipDefaultSelect = [{  label: mappedData1.zip, value:  mappedData1.zip}]; }
		if(mappedData1.phone!="") {  PhoneDefaultSelect = [{  label: mappedData1.phone, value:  mappedData1.phone}]; }
		if(mappedData1.phone_position!="") {  PhonePositionDefaultSelect = [{  label: mappedData1.phone_position, value:  mappedData1.phone_position}]; }
		if(mappedData1.carrier!="") {  CarrierSelectDefaultSelect = [{  label: mappedData1.carrier, value:  mappedData1.carrier}]; }
		if(mappedData1.caller_id_name!="") {  CallerIDDefaultSelect = [{  label: mappedData1.caller_id_name, value:  mappedData1.caller_id_name}]; }
		if(mappedData1.caller_id_match!="") {  CallerIDMatchDefaultSelect = [{  label: mappedData1.caller_id_match, value:  mappedData1.caller_id_match}]; }
		if(mappedData1.line_type!="") {  LineTypeDefaultSelect = [{  label: mappedData1.line_type, value:  mappedData1.line_type}]; }
		if(mappedData1.connected!="") {  ConnectedDefaultSelect = [{  label: mappedData1.connected, value:  mappedData1.connected}]; }
		if(mappedData1.last_disposition!="") {  LastDisposistionDefaultSelect = [{  label: mappedData1.last_disposition, value:  mappedData1.last_disposition}]; }
		if(mappedData1.times_called!="") {  TimecalledDefaultSelect = [{  label: mappedData1.times_called, value:  mappedData1.times_called}]; }
		if(mappedData1.tag!="") {  TagDefaultSelect = [{  label: mappedData1.tag, value:  mappedData1.tag}]; }
	}

 const Owner1firstNameSelect = () => (
  <Select
    name="owner_1_first_name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle} 
	defaultValue={Owner1firstNameDefaultSelect}
  />
  );
  
 const Owner1lastNameSelect = () => (
  <Select
    name="owner_1_last_name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={Owner1lastNameDefaultSelect}
  />   
) 
 const Owner2firstNameSelect = () => (
  <Select
    name="owner_2_first_name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={Owner2firstNameDefaultSelect}
  />   
)

 const Owner2lastNameSelect = () => (
  <Select
    name="owner_2_last_name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={Owner2lastNameDefaultSelect}
  />   
)

 const OwnerfullNameSelect  = () => (
  <Select
    name="full_name_of_owner"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue ={OwnerfullNameDefaultSelect}
	
	
  />   
)

 const MailingAddressSelect = () => (
  <Select
    name="mailing_address"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={MailingAddressDefaultSelect}
  />   
)

 const MailingcitySelect  = () => (
  <Select
    name="mailing_city"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={MailingcityDefaultSelect}
  />   
)

 const MailingstateSelect = () => (
  <Select
    name="mailing_state"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={MailingstateDefaultSelect}
  />   
)

 const MailingzipSelect  = () => (
  <Select
    name="mailing_zip"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={MailingzipDefaultSelect}
  />   
)

 const AddressSelect = () => (
  <Select
    name="address"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={AddressDefaultSelect}
  />   
)

 const CitySelect  = () => (
  <Select
    name="city"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={CityDefaultSelect}
  />   
)

 const StateSelect = () => (
  <Select
    name="state"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={StateDefaultSelect}
  />   
)

 const ZipSelect = () => (
  <Select
    name="zip"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={ZipDefaultSelect}
  />   
)

 const PhoneSelect = () => (
  <Select
    name="phone"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={PhoneDefaultSelect}
  />   
)

 const PhonePositionSelect = () => (
  <Select
    name="phone_position"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={PhonePositionDefaultSelect}
  />   
)

 const CarrierSelect = () => (
  <Select
    name="carrier"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={CarrierSelectDefaultSelect}	
	

  />   
)

 const CallerIDSelect = () => (
  <Select
    name="caller_id_name"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={CallerIDDefaultSelect}	
  />   
)
 const CallerIDMatchSelect = () => (
  <Select
    name="caller_id_match"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={CallerIDMatchDefaultSelect}
  />   
)

 const LineTypeSelect = () => (
  <Select
    name="line_type"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={LineTypeDefaultSelect}	
  />   
) 
 const ConnectedSelect = () => (
  <Select
    name="connected"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={ConnectedDefaultSelect}
  />   
)

 const LastDisposistionSelect = () => (
  <Select
    name="last_disposition"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={LastDisposistionDefaultSelect}
  />   
)

 const TimecalledSelect = () => (
  <Select
    name="times_called"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={TimecalledDefaultSelect}
  />   
)

 const TagSelect = () => (
  <Select
    name="tag"
    options={options}
    className="basic-multi-select"
    classNamePrefix="select"
	onChange={SelectChangeHandle}
	defaultValue={TagDefaultSelect}
  />   
)

const FileName = localStorage.getItem("fileName");

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
	  
	  <div className="row mapping_fields">
	  <HeadingFiles>{FileName}</HeadingFiles>
	     <svg width="1016" height="1" viewBox="0 0 1016 1" fill="none" xmlns="http://www.w3.org/2000/svg"><line opacity="0.05" y1="0.5" x2="1016" y2="0.5" stroke="black"/></svg>
	  </div>
	 
	  
	  <div className="row mapping_fields top">
	    <div className="col-md-3">
		<div className="row left_side"> 
		<div className="col-md-8">
        <Link to='/data-manipulator'><span className="icon_heading"> Upload File </span></Link>
        <Link to='/mapping-fields'> <span className="icon_heading"> Mapping Fields </span></Link>
        <Link to='/report'><span className="icon_heading"> Report </span></Link>
		</div> <div className="col-md-4">
		   <div className="main_icons">
		    <span className="left_icons">
			<svg width="23" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.764 6.94118C13.5288 6.94118 13.304 7.03753 13.1423 7.20729L7.12022 13.5161C6.59831 14.0621 6.98602 14.9706 7.74193 14.9706H10.8963V21.5662C10.8963 22.3565 11.5398 23 12.3302 23H15.1978C15.9881 23 16.6316 22.3565 16.6316 21.5662V14.9706H19.786C20.542 14.9706 20.9297 14.0621 20.4078 13.5161L14.3857 7.20729C14.224 7.03753 13.9991 6.94118 13.764 6.94118Z" fill="#A0A0A0"/>
			<path d="M25.5221 0.0587373H2.00735C0.900441 0.0587373 0 0.959178 0 2.06609V2.63962C0 3.74653 0.900441 4.64697 2.00735 4.64697H25.5221C26.629 4.64697 27.5294 3.74653 27.5294 2.63962V2.06609C27.5294 0.959178 26.629 0.0587373 25.5221 0.0587373Z" fill="#A0A0A0"/>
			</svg>
			
			</span>
			</div>
			<div className="icon_border" />
			 <div className="main_icons">
		    <span className="left_icons active">
				<svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M21.002 0.749023H6.00728C5.17915 0.749023 4.50781 1.42037 4.50781 2.2485C4.50781 3.07663 5.17915 3.74797 6.00728 3.74797H21.002C21.8301 3.74797 22.5015 3.07663 22.5015 2.2485C22.5015 1.42037 21.8301 0.749023 21.002 0.749023Z" fill="white"/>
				<path d="M3.00734 2.24853C3.00566 1.8515 2.84657 1.47133 2.565 1.1914C1.98016 0.610028 1.03559 0.610028 0.450744 1.1914C0.169124 1.47133 0.0100394 1.8515 0.00839939 2.24853C-0.0027998 2.34567 -0.0027998 2.44379 0.00839939 2.54093C0.0253622 2.63863 0.0530087 2.73417 0.0908704 2.82583C0.131122 2.91462 0.178777 2.99991 0.23332 3.08074C0.287161 3.1648 0.349998 3.24273 0.420754 3.31316C0.48898 3.3811 0.564376 3.44145 0.645675 3.49309C0.724632 3.55059 0.810242 3.59843 0.900586 3.63554C0.999973 3.68709 1.10592 3.72495 1.21547 3.748C1.31261 3.75887 1.41073 3.75887 1.50787 3.748C1.90383 3.74833 2.28385 3.59201 2.565 3.31316C2.63576 3.24273 2.69859 3.1648 2.75243 3.08074C2.80698 2.99991 2.85463 2.91462 2.89488 2.82583C2.94273 2.7353 2.98045 2.63975 3.00734 2.54093C3.01854 2.44379 3.01854 2.34567 3.00734 2.24853Z" fill="white"/>
				<path d="M3.00773 8.99609C3.01889 8.89895 3.01889 8.80083 3.00773 8.70369C2.98182 8.60693 2.94405 8.51377 2.89527 8.42629C2.85666 8.33431 2.80891 8.2464 2.75282 8.16388C2.70086 8.08075 2.63779 8.00508 2.56539 7.93896C1.98055 7.35759 1.03597 7.35759 0.451133 7.93896C0.169514 8.21889 0.0104291 8.59906 0.00878906 8.99609C0.0116943 9.19369 0.0497903 9.38914 0.121249 9.57339C0.15883 9.66209 0.204002 9.74737 0.256202 9.8283C0.313229 9.90978 0.37855 9.98518 0.451133 10.0532C0.517391 10.1255 0.593021 10.1885 0.676054 10.2407C0.755011 10.2982 0.840575 10.346 0.930965 10.3831C1.02239 10.4216 1.11802 10.4493 1.21586 10.4656C1.31178 10.4871 1.40995 10.4972 1.50826 10.4956C1.6054 10.5068 1.70352 10.5068 1.80066 10.4956C1.89606 10.4792 1.98922 10.4515 2.07806 10.4131C2.17089 10.3763 2.25898 10.3284 2.34047 10.2706C2.4235 10.2185 2.49913 10.1555 2.56539 10.0832C2.63765 10.0169 2.70072 9.94132 2.75282 9.85829C2.81046 9.77938 2.8583 9.69381 2.89527 9.60338C2.94649 9.50385 2.9843 9.39795 3.00773 9.28849C3.01926 9.19135 3.01926 9.09323 3.00773 8.99609Z" fill="white"/>
				<path d="M3.00783 15.7436C3.01893 15.6465 3.01893 15.5484 3.00783 15.4512C2.98191 15.3521 2.94415 15.2565 2.89537 15.1663C2.85511 15.0776 2.80746 14.9923 2.75292 14.9114C2.70081 14.8284 2.63774 14.7528 2.56548 14.6865C1.98064 14.1051 1.03607 14.1051 0.451225 14.6865C0.37897 14.7528 0.315898 14.8284 0.263791 14.9114C0.209248 14.9923 0.161593 15.0776 0.121341 15.1663C0.0824489 15.2576 0.0547555 15.3533 0.0388704 15.4512C0.0177372 15.5473 0.00770952 15.6453 0.00888098 15.7436C0.0105679 16.1407 0.169653 16.5208 0.451225 16.8008C0.517483 16.873 0.593113 16.9361 0.676146 16.9882C0.755103 17.0458 0.840667 17.0936 0.931057 17.1307C1.02248 17.1691 1.11812 17.1968 1.21596 17.2131C1.31188 17.2346 1.41004 17.2447 1.50835 17.2431C1.60549 17.2543 1.70361 17.2543 1.80075 17.2431C1.89615 17.2268 1.98931 17.1991 2.07815 17.1606C2.17098 17.1238 2.25907 17.076 2.34056 17.0182C2.42359 16.9661 2.49922 16.903 2.56548 16.8308C2.63774 16.7645 2.70081 16.6889 2.75292 16.6058C2.8106 16.527 2.85844 16.4414 2.89537 16.3509C2.94654 16.2514 2.98435 16.1455 3.00783 16.036C3.01935 15.9389 3.01935 15.8408 3.00783 15.7436Z" fill="white"/>
				<path d="M22.5015 7.49658H6.00728C5.17915 7.49658 4.50781 8.16792 4.50781 8.99606C4.50781 9.82419 5.17915 10.4955 6.00728 10.4955H22.5015C23.3296 10.4955 24.001 9.82419 24.001 8.99606C24.001 8.16792 23.3296 7.49658 22.5015 7.49658Z" fill="white"/>
				<path d="M15.7539 14.2441H6.00729C5.17915 14.2441 4.50781 14.9155 4.50781 15.7436C4.50781 16.5717 5.17915 17.2431 6.00729 17.2431H15.7539C16.582 17.2431 17.2533 16.5717 17.2533 15.7436C17.2533 14.9155 16.582 14.2441 15.7539 14.2441Z" fill="white"/>
				</svg>
                
			</span></div>
			
             <div className="icon_border" />
			  <div className="main_icons">
		    <span className="left_icons">
					<svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.8068 6.07383L13.9477 0.2146C13.8125 0.0793945 13.6252 0 13.4297 0H3.17578C1.96421 0 0.978516 0.985693 0.978516 2.19727V22.8027C0.978516 24.0143 1.96421 25 3.17578 25H17.8242C19.0358 25 20.0215 24.0143 20.0215 22.8027V6.5918C20.0215 6.39248 19.9368 6.20366 19.8068 6.07383ZM14.1621 2.50063L17.5208 5.85938H14.8945C14.4907 5.85938 14.1621 5.53081 14.1621 5.12695V2.50063ZM17.8242 23.5352H3.17578C2.77192 23.5352 2.44336 23.2066 2.44336 22.8027V2.19727C2.44336 1.79341 2.77192 1.46484 3.17578 1.46484H12.6973V5.12695C12.6973 6.33853 13.683 7.32422 14.8945 7.32422H18.5566V22.8027C18.5566 23.2066 18.2281 23.5352 17.8242 23.5352Z" fill="#A0A0A0"/>
					<path d="M14.8945 10.3516H6.10547C5.70098 10.3516 5.37305 10.6795 5.37305 11.084V19.873C5.37305 20.2775 5.70098 20.6055 6.10547 20.6055H14.8945C15.299 20.6055 15.627 20.2775 15.627 19.873V11.084C15.627 10.6795 15.299 10.3516 14.8945 10.3516ZM6.83789 14.7461H9.76758V16.2109H6.83789V14.7461ZM11.2324 14.7461H14.1621V16.2109H11.2324V14.7461ZM14.1621 13.2812H11.2324V11.8164H14.1621V13.2812ZM9.76758 11.8164V13.2812H6.83789V11.8164H9.76758ZM6.83789 17.6758H9.76758V19.1406H6.83789V17.6758ZM11.2324 19.1406V17.6758H14.1621V19.1406H11.2324Z" fill="#A0A0A0"/>
					</svg>


			</span>	</div>		
		</div>
		</div>
		
		
		
		</div>
		
	    <div className="col-md-9">
	    <span className="icon_heading_blue"> Uploaded File Summary </span>
		<div className="row">
		<div className="col-md-6"> 
		  <div className="mapping_data">  <span> Total Mailing Addresses: </span> <span className="count">{totalMailingAddress}</span> </div> 
		  <div className="mapping_data">  <span> Unique Mailing Addresses: </span> <span className="count">{uniqueMmailingAddress}</span> </div>
		</div>
		<div className="col-md-6"> 
		  <div className="mapping_data">  <span> Duplicates Mailing Addresses: </span> <span className="count">{duplicateMmailingAddress}</span> </div> 

		</div>
		</div>
		{ mergeCsv!= null &&
	    <div><span className="icon_heading_blue"> Matching Rows Summary </span>
		<div className="row">    
		<div className="col-md-4 merge"> 
		    <span> Total Match Rows : </span> <span className="count">{TotalMatchRows}</span>
		 
		</div>
		<div className="col-md-8 merge">
		    <span> New Rows Found : </span> <span className="count">{NewRowsFound}</span>
		</div>
		<div className="col-md-12 merge"> 
		   <span> Mailing Address Already Exists in Previous Records : </span> <span className="count">{MailingAlreadyExists}</span> 

		</div>
		
		<div className="col-md-12 merge"> 
		  <span> New Phone Number Found (Existing Mailing Address) : </span> <span className="count">{NewPhoneNumberFound}</span> 

		</div>	

		<div className="col-md-12 merge"> 
		  <span> New Address Found (Existing Mailing Address) : </span> <span className="count">{Newaddressfound}</span>  

		</div>			
		</div></div>	 }

        <div className="row form">		
		 <div className="col-md-6"> 
		 <div className="fields"> <label>Owner 1 First  Name</label> <Owner1firstNameSelect/></div>
		 <div className="fields"> <label>Owner 2 First  Name</label> <Owner2firstNameSelect/></div>
		 <div className="fields"> <label>Full name of Owner</label> <OwnerfullNameSelect/></div>
		 <div className="fields"> <label>Mailing City</label> <MailingcitySelect/></div>
		 <div className="fields"> <label> Mailing Zip</label> <MailingzipSelect/></div>
		 <div className="fields"> <label> City</label> <CitySelect/></div>
		 </div>
		 
		 <div className="col-md-6"> 
		  <div className="fields"> <label>Owner 1 Last  Name</label> <Owner1lastNameSelect/></div>
		  <div className="fields"> <label>Owner 2 Last  Name</label> <Owner2lastNameSelect/></div>
		  <div className="fields"> <label> Mailing Address </label> <MailingAddressSelect/></div>
		  <div className="fields"> <label> Mailing  State </label> <MailingstateSelect/></div>
		  <div className="fields"> <label> Address </label> <AddressSelect/></div>
		  <div className="fields"> <label> State</label> <StateSelect/></div>
		 </div>
		 <div className="col-md-12"><label> Zip</label> <ZipSelect/></div>
		 <div className="col-md-6"> 
		   <div className="fields"> <label> Phone </label> <PhoneSelect/></div>
		   <div className="fields"> <label> Carrier </label> <CarrierSelect/></div>
		   <div className="fields"> <label> Caller ID Match </label> <CallerIDMatchSelect/></div>
		   <div className="fields"> <label> Connected (yes /no) </label> <ConnectedSelect/></div>
		   <div className="fields"> <label> Disposition</label> <LastDisposistionSelect/></div>
		   
		   
		 </div>
		 <div className="col-md-6"> 
		  <div className="fields"> <label> Phone Position </label> <PhonePositionSelect/></div>
		  <div className="fields"> <label> Caller ID Name </label> <CallerIDSelect/></div>
		  <div className="fields"> <label> Line Type </label> <LineTypeSelect/></div>
		  <div className="fields"> <label> Time called </label> <TimecalledSelect/></div>
		  <div className="fields"> <label> Tag </label> <TagSelect/></div>
		   
		 </div>	

  <div className="main_status_bar">
			    <span className="Status">Status</span>
			    <div id="progresscount" >0%</div></div>
				<div id="UploadProgress">
				  <div id="UploadBar" />
				</div>		 
		</div>
		         
		<div className="row button">
		<div className="btn_main">
		<Button text="Back" style="btn-primary back " onClick={()=>backBtn() }> Back</Button> <Button text="Update" style="btn-primary"  onClick={()=>saveMappingData() }> Next</Button> 
		</div>
		</div>
		</div>		
	  </div>
	  
    </Wrapper>
  )
}

const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding:20px 0px 45px 0px;
  
`

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
  float:left;
  padding: 10px 0px 0px 20px;
`
const Label = styled.label`
  margin-left: 10px;
  font-size: 18px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: 'Montserrat';
  float:left;
`

const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 172px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
`
export default MappingFields
