import React, { useState, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { login, ActiveAccount } from "../../services/auth"
import Loading from "../../components/loading"
import { useHistory } from "react-router"
import {useLocation} from "react-router-dom";

// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';

import TextInput from "../../components/textInput"
import Button from "../../components/button"
import Text from "../../components/text"
import Image from "../../components/image"
import Logo from "../../assets/images/logo.png"

import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
const Login = () => {
  let history = useHistory()
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const ActivatingEmail = new URLSearchParams(search).get('email');
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isLoading, setisLoading] = useState(false)
 const [isActive, setisActive] = useState(true)
  document.title = "Login | AnalyzeREI"
    useEffect(() => {
    ActiveAccountCall() })
  
  function onChange(event) {
    if (event.target.name === "email") {
      setEmail(event.target.value)
    }
    if (event.target.name === "password") {
      setPassword(event.target.value)
    }
  }
  function ActiveAccountCall () {
	//localStorage.removeItem("isLoggedIn");
	//localStorage.removeItem("userData");
	if(isActive){
	if(token!=null){  
    ActiveAccount({
      token: token,
	  email:ActivatingEmail
    })
      .then(function (response) {
		   setisActive(false);
		  if(response.status==true){
			 toast.success(response.message); } else { toast.error(response.message); }
      })
      .catch((error) => {
        console.log("error", error)
      })
	}}
  }
  function onSubmit() {
	setisActive(false);
    setisLoading(true)
    login({
      email: email,
      password: password,
    })
      .then(function (response) {
        setisLoading(false)
				  if(response.status==true){
			 toast.success(response.message); } else { toast.error(response.message); }
        if (response["data"][0].status === "Active") {
          console.log(response["data"][0]);
          localStorage.setItem("userData", JSON.stringify(response["data"][0]))
          localStorage.setItem("isLoggedIn", "true")
          if (response["data"][0].role === "ADMIN") {
            history.push("/phone-validation")

            // this.router
            //   .navigate([this.routernavigate.adminDashboard])
            //   .then(() => {
            //     location.reload();
            //   });
          } else {
            history.push("/phone-validation")
            // this.router.navigate([this.routernavigate.dashboard]).then(() => {
            //   location.reload();
            // });
          }
        } else {
          //   this.SpinnerService.hide();
          //   if (res["data"][0].status === "Pending") {
          //     $(".toast").toast("show");
          //     this.text = "Your account is pending";
          //   }
          //   if (res["data"][0].status === "Cancelled") {
          //     $(".toast").toast("show");
          //     this.text = "Your account is cancelled";
          //   }
          //   if (res["data"][0].status === "Blocked") {
          //     $(".toast").toast("show");
          //     this.text = "Your account is blocked";
          //   }
        }
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  return (
    <Wrapper>
      <Loading show={isLoading} />
      <FormWrapper>
        <Icon src={Logo} width='50px' height='50px' />
        <Text>Sign in to your account</Text>
        <TextInput
          label={"Email"}
          value={email}
          placeholder={"Email"}
          onChange={onChange}
          name='email'
        />

        <TextInput
          type={"password"}
          label={"Password"}
          value={password}
          placeholder={"Password"}
          onChange={onChange}
          name='password'
        />

        <Button text='Login' onClick={onSubmit} style='btn-primary'>
          Login
        </Button>
      </FormWrapper>
    </Wrapper>
  )
}
export default Login
const Wrapper = styled.div`
  text-align: center;
  width: 100%;
`

const Icon = styled(Image)`
  img {
    margin: auto;
  }
`
const FormWrapper = styled.div`
  ${tw`
        w-2/5
        rounded
        shadow-2xl
        border-2
    `}
  padding: 50px 50px 100px;
  margin: auto;
  margin-top: 10%;
  border: ;
`
