import React, { useEffect, useState } from "react";
import Autosuggest from 'react-autosuggest';
import FilterComponent from "./../filter";
import Loading from "./../../../../components/loading";
import $ from 'jquery';
import { getCrmActions} from "./../../../../services/crm";
localStorage.removeItem("filterParam");
// Imagine you have a list of languages that you'd like to autosuggest.
// const Filters = [ { label: 'Owner 1'}, { label: 'Owner 2'}, { label: 'Full name of owner'}, { label: 'Mailing Address'}, { label: 'Mailing City'}, { label: 'Mailing State'}, { label: 'Mailing Zip'}, { label: 'Address'}, { label: 'Phone Number'}, { label: 'Times Called'}, { label: 'Tag'}, { label: 'Phone Position'}, { label: 'Carrier'}, { label: 'Caller ID Name'}, { label: 'Line Type'}, { label: 'Active'}, { label: 'Caller ID Match'}];
 let fileID= localStorage.getItem( "file_id");
// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, Filters) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : Filters.filter(lang =>
    lang.label.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.label;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.label}
  </div>
);

class FilterAutosuggest extends React.Component {
  constructor(props) {
    super(props);
this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value:this.props.value,
      suggestions: [],
	  tableListFields:[],
	  newItem:false,
	  isLoading:false
    };
  }
  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
	  let self = this;
	  self.setState({isLoading:true});
			getCrmActions(
			  {
				file_id: fileID,
				action: 'get-dropdown',
				dropdown_type : self.props.name,
				search : value
			  }
			).then(function (response) {
				 self.setState({isLoading:false});
			if(response.data){			            
                 self.setState({
					  suggestions: getSuggestions(value, response.data.option)
			}); }
			  }); 
			  
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
	 this.setState({newItem:true});
	 this.setState({tableListFields:JSON.parse(localStorage.getItem("filterParam"))}); 
  }  
  render() {
	$( ".row.filters-2-main .col-md-4 .filtervalue label" ).on( "click", function( event, salutation, name ) {
	  var className=$(this).attr("class");
	  $('.'+className).remove();
	});	  
    const { value, suggestions, tableListFields, newItem } = this.state;
 fileID= localStorage.getItem( "file_id");
 let tableListFieldsItems  = [];
 if(tableListFields){
 let tableListFieldsItems = tableListFields.length ? tableListFields: [];
 }
  if(this.props.presetType==true && newItem==false) {
	tableListFieldsItems = this.props.data;
 }

 
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'keyword',
      value,
      onChange: this.onChange,
	  name: this.props.name,
	  id: this.props.id
    };
    // Finally, render it!
    return (<div><Loading show={this.state.isLoading} />
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
		onSuggestionSelected = {this.onSuggestionSelected}
        inputProps={inputProps}
		
      />
</div>
    );
  }
}

export default FilterAutosuggest;