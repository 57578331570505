import { BASE_URL, API_URL } from "../config"
import { getAuthorizationToken } from "../utils/helper"

export async function getAdminUsers(requestObj) {
  const url = BASE_URL + "adminUsers"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getUsersExcelData(requestObj, page) {
  const url = BASE_URL + "getUserExcelData?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}
export async function uploadExcelFile(requestObj) {
  let formData = new FormData()
  formData.append("user_id", requestObj.user_id)
  formData.append("file_name", requestObj.file_name, requestObj.file_name.name)

  const url = BASE_URL + "uploadExcelFiles"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: formData,
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function downloadLeads(requestObj) {
  const url = BASE_URL + "download-file-post"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function phonedownloadLeads(requestObj) {
  const url = BASE_URL + "dv-download-post"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}
export async function downloadLeadsPhone(requestObj) {
  const url = API_URL + "dv-download-post"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function mnipulator(requestObj, page) {
  const url = BASE_URL + "manipulator-files-list?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function mnipulatorMappingFields(requestObj) {
  const url = BASE_URL + "manipulator-mapping";
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function uploadManipulatorFile(requestObj) {
  const url = BASE_URL + "upload-mnp-file";
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function downloadLeadslisting(requestObj, page) {
  const url = BASE_URL + "download-list?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function downloadPhonelisting(requestObj, page) {
  const url = BASE_URL + "dv-download-list?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function downloadSkipTracinglisting(requestObj, page) {
  const url = BASE_URL + "skip-tracing-download?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getAvailbleCreadit(requestObj, page) {
  const url = BASE_URL + "getUserCreditDetails"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function deleteSelectedFile(requestObj, page) {
  const url = BASE_URL + "deleteSelectedUserFiles"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}



export async function mappingFileData(requestObj, page) {
  const url = BASE_URL + "createTableMapping"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getUploadedFileDetails(requestObj, page) {
  const url = BASE_URL + "getUploadedFileDetails"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getUserNotifications(requestObj, page) {
  const url = BASE_URL + "getUserNotifications"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}



export async function deleteManipulatorItem(requestObj) {
  const url = BASE_URL + "data-manipulator-actions"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function getManipulatorHistory(requestObj, page) {
  const url = BASE_URL + "data-manipulator-filehistory?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getManipulatorContacts(requestObj, page) {
  const url = BASE_URL + "data-manipulator-report?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }
  console.log("sending request");
  let response = await fetch(url, requestOptions)
	.catch(function (error) {
		console.log("error: ", error) 
		return error
    })
  console.log("received request");
  let data = await response.text();    
  console.log("parsed json");
  return JSON.parse(data);
    
}

export async function getFreeSearchData(requestObj, page) {
  const url = BASE_URL + "data-manipulator-report?page=" + page
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function getActionData(requestObj) {
  const url = BASE_URL + "data-manipulator-actions"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function uploadRevampFile(requestObj) {
  const url = API_URL + "dv-file-upload"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function phoneMappingPost(requestObj) {
  const url = API_URL +"dv-phone-mapping"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function contactMappingPost(requestObj) {
  const url = API_URL +"dv-contact-mapping"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function revampReportSubmission(requestObj) {
  const url = API_URL +"dv-submit"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function clearAllnotification(requestObj) {
  const url = BASE_URL + "data-manipulator-actions"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}


export async function DeleteDownloadsFiles(requestObj) {
  const url = BASE_URL + "delete-download-files"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}

export async function ManipulatorActions(requestObj) {
  const url = BASE_URL + "data-manipulator-actions"
  const token = getAuthorizationToken()
  const requestOptions = {
    method: "POST",
    headers: {
	  "Content-Type": "application/json",	
      Authorization: token,
    },
    body: JSON.stringify(requestObj),
  }

  return fetch(url, requestOptions)
    .then((response) => response.json())
    .catch(function (error) {
      console.log("error: ", error)
      return error
    })
}