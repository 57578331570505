import React from "react"
import styled from "styled-components"
import Image from "../image"
import Logo from "../../assets/images/Mask-Group.png"
import SidebarItem from "./sidebarItem"
import COLORS from "../../utils/colors"
import Dashboard from "../../assets/images/dashboard1.png"
import DataBase from "../../assets/images/database1.png"
import Exclamation from "../../assets/images/exclamation1.png"
import Logout from "../../assets/images/logout1.png"
import NounList from "../../assets/images/noun-List1.png"
import Dashbord2 from "../../assets/images/dashboard1.svg"
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom"
const SideBar = (props) => {
  const location = useLocation()
  var pathName = window.location.pathname.substr(1)	
  let history = useHistory()
  function onLogout() {
    localStorage.clear()
    history.push("/")
    window.location.reload()
  } 
  return (

    <div>
    <div className="side-bar-for-main-div">
        <div className="wrapper-side-bar-for-main-div">
          <div class="s-layout">
            <div class="s-layout__sidebar">
              <Link to='/' class="s-sidebar__trigger" >
                <i class="fa fa-bars"></i>
              </Link>
              <nav class="s-sidebar__nav">
                <ul>
                <li>
                    <Link to='/' class="s-sidebar__nav-link"  style={{opacity: 1, marginBottom:'30px', cursor:'pointer'}}>
                      <img src={Logo} alt="image" className="logo" />
                    </Link>
                  </li>
				
                  <li className="dashboard" id={pathName == 'dashboard'? 'selected': ''}>
                    <Link to='/' class="s-sidebar__nav-link" style={{cursor:'pointer'}}>
                      <i><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.70828 0H1.45828C0.654144 0 0 0.654144 0 1.45828V5.20828C0 6.01257 0.654144 6.66672 1.45828 6.66672H7.70828C8.51257 6.66672 9.16672 6.01257 9.16672 5.20828V1.45828C9.16672 0.654144 8.51257 0 7.70828 0Z" fill="#959595"/>
<path d="M7.70828 8.3335H1.45828C0.654144 8.3335 0 8.98764 0 9.79193V18.5419C0 19.3461 0.654144 20.0002 1.45828 20.0002H7.70828C8.51257 20.0002 9.16672 19.3461 9.16672 18.5419V9.79193C9.16672 8.98764 8.51257 8.3335 7.70828 8.3335Z" fill="#959595"/>
<path d="M18.5417 13.3335H12.2917C11.4874 13.3335 10.8333 13.9876 10.8333 14.7919V18.5419C10.8333 19.3461 11.4874 20.0002 12.2917 20.0002H18.5417C19.3458 20.0002 20 19.3461 20 18.5419V14.7919C20 13.9876 19.3458 13.3335 18.5417 13.3335Z" fill="#959595"/>
<path d="M18.5417 0H12.2917C11.4874 0 10.8333 0.654144 10.8333 1.45828V10.2083C10.8333 11.0126 11.4874 11.6667 12.2917 11.6667H18.5417C19.3458 11.6667 20 11.0126 20 10.2083V1.45828C20 0.654144 19.3458 0 18.5417 0V0Z" fill="#959595"/>
</svg>
</i><em>Dashboard</em>
                    </Link>
                  </li> 
                  <li id={pathName == 'tools'? 'selected': ''}>
                    <Link to='/tools' class="s-sidebar__nav-link" style={{cursor:'pointer'}}>
                      <i><svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99755 13.0862C5.49119 13.0862 1.71484 11.7624 0.68381 9.97754C0.502539 10.2917 0.396729 10.6181 0.396729 10.9569V13.8777C0.396729 16.1342 4.69639 17.965 9.99755 17.965C15.302 17.965 19.6033 16.1342 19.6033 13.8777V10.9569C19.6033 10.6181 19.4958 10.2917 19.3129 9.97754C18.2819 11.7615 14.5088 13.0862 9.99755 13.0862Z" fill="#047CFF"/>
<path d="M9.99755 19.1197C5.49119 19.1197 1.71484 17.7942 0.68381 16.0127C0.502539 16.3252 0.396729 16.6517 0.396729 16.9896V19.9129C0.396729 22.1702 4.69639 24.0001 9.99755 24.0001C15.302 24.0001 19.6033 22.171 19.6033 19.9129V16.9896C19.6033 16.6517 19.4958 16.3252 19.3129 16.0135C18.2819 17.7942 14.5088 19.1197 9.99755 19.1197Z" fill="#047CFF"/>
<path d="M19.3408 4.13574C19.1522 5.78933 15.0494 7.11237 9.99755 7.11237C4.9482 7.11237 0.845396 5.78933 0.657562 4.13656C0.492696 4.43677 0.396729 4.7501 0.396729 5.07245V7.99329C0.396729 10.2506 4.69639 12.0805 9.99755 12.0805C15.302 12.0805 19.6033 10.2514 19.6033 7.99329V5.07245C19.6033 4.7501 19.5057 4.43677 19.3408 4.13574Z" fill="#047CFF"/>
<path d="M9.99759 6.18537C15.1675 6.18537 19.3564 4.80164 19.3564 3.09228C19.3564 1.38455 15.1675 0 9.99759 0C4.83095 0.000820232 0.640381 1.38537 0.640381 3.0931C0.640381 4.80164 4.83095 6.18537 9.99759 6.18537Z" fill="#047CFF"/>
</svg>
</i><em>Tools</em>
                    </Link>
                  </li> 
                  <li>
                    <Link to='/' className="s-sidebar__nav-link" style={{cursor:'pointer'}}>
                      <i><svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.08053 2.27979H7.12639C7.43001 0.973806 8.60167 0 10 0C11.3984 0 12.5697 0.973806 12.8733 2.27979H16.9212C19.2284 2.27979 19.9709 3.26438 20 4.99024V19.273C20 21.0601 19.0979 22.2035 16.9212 22.2035H3.08051C0.905035 22.2035 0 21.0601 0 19.273V4.99024C0.0300127 3.26438 0.77275 2.27979 3.08051 2.27979H3.08053ZM4.36787 7.05004H15.6322V9.34877H4.36787V7.05004ZM4.36787 15.939H12.9503V18.238H4.36787V15.939ZM4.36787 11.4945H15.6322V13.7932H4.36787V11.4945ZM10 1.37942C10.8675 1.37942 11.5709 2.08282 11.5709 2.95027C11.5709 3.81772 10.8675 4.52112 10 4.52112C9.13257 4.52112 8.42918 3.81772 8.42918 2.95027C8.42918 2.08282 9.13257 1.37942 10 1.37942Z" fill="#959595"/>
</svg>
</i><em>Manage List</em>
                    </Link>
                  </li> 
				  
                  <li>
                    <Link to='/' className="s-sidebar__nav-link" style={{cursor:'pointer'}}>
                      <i><svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 0C4.275 0 0 4.275 0 9.5C0 14.725 4.275 19 9.5 19C14.725 19 19 14.725 19 9.5C19 4.275 14.725 0 9.5 0ZM9.5 1.9C10.545 1.9 11.305 2.755 11.21 3.8L10.45 11.4H8.55L7.79 3.8C7.695 2.755 8.455 1.9 9.5 1.9ZM9.5 17.1C8.455 17.1 7.6 16.245 7.6 15.2C7.6 14.155 8.455 13.3 9.5 13.3C10.545 13.3 11.4 14.155 11.4 15.2C11.4 16.245 10.545 17.1 9.5 17.1Z" fill="#959595"/>
</svg>
</i><em>Reports</em>
                    </Link>
                  </li> 
                  <li>
                    <Link to='/downloads' className="s-sidebar__nav-link" style={{cursor:'pointer'}} id={pathName == 'downloads'? 'selected': ''}>
                      <i><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-download" viewBox="0 0 19 19">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg>

</i><em>Downloads</em>
                    </Link>
                  </li> 
				  
                  <li>
                    <Link to='/' className="s-sidebar__nav-link" onClick={onLogout} style={{cursor:'pointer'}}>
                      <i><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.4999 10.8332C12.0391 10.8332 11.6666 11.2066 11.6666 11.6665V14.9999C11.6666 15.459 11.2932 15.8331 10.8332 15.8331H8.3332V3.33325C8.3332 2.62159 7.87987 1.98576 7.19826 1.7491L6.95153 1.66655H10.8332C11.2932 1.66655 11.6666 2.04069 11.6666 2.49998V4.99995C11.6666 5.45985 12.0391 5.83323 12.4999 5.83323C12.9607 5.83323 13.3332 5.45985 13.3332 4.99995V2.49998C13.3332 1.12166 12.2115 0 10.8332 0H1.87498C1.84324 0 1.81669 0.0141906 1.78587 0.0183104C1.74574 0.0149535 1.70744 0 1.6667 0C0.747521 0 0 0.747368 0 1.66655V16.6664C0 17.3781 0.453334 18.0139 1.13494 18.2506L6.15 19.9223C6.31998 19.9748 6.48904 19.9998 6.66665 19.9998C7.58583 19.9998 8.3332 19.2523 8.3332 18.3331V17.4998H10.8332C12.2115 17.4998 13.3332 16.3782 13.3332 14.9999V11.6665C13.3332 11.2066 12.9607 10.8332 12.4999 10.8332Z" fill="#959595"/>
<path d="M19.7556 7.74415L16.4222 4.41089C16.184 4.17255 15.8256 4.10084 15.514 4.22993C15.2032 4.35917 14.9998 4.66327 14.9998 5.00003V7.50001H11.6666C11.2065 7.50001 10.8331 7.87323 10.8331 8.33328C10.8331 8.79333 11.2065 9.16656 11.6666 9.16656H14.9998V11.6665C14.9998 12.0033 15.2032 12.3074 15.514 12.4366C15.8256 12.5657 16.184 12.494 16.4222 12.2558L19.7556 8.92242C20.0814 8.59665 20.0814 8.06992 19.7556 7.74415Z" fill="#959595"/>
</svg>
</i><em>Logout</em>
                    </Link>
					<Link to='/' className="s-sidebar__nav-link" onClick={onLogout} style={{cursor:'pointer'}}></Link>
                  </li>
                  <li><Link to='/' className="s-sidebar__nav-link" onClick={onLogout} style={{cursor:'pointer'}}></Link><em style={{fontSize:'small'}}> V 0.4.24</em></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  )
}

export default SideBar


