import React, { useState,useEffect } from "react";
import Select from 'react-select';
import styled from "styled-components";
import FilterAutosuggest from "./../auto-suggest2";
import Autocomplete2 from "./../auto-suggest3";
import $ from 'jquery';
const FilterComponent = (props) => {
 const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      height: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
    }),
  };
  
  const ConditionOption = [
    { value: "contain", label: "Contain" },
    { value: "greater_than", label: "Greater Than" },
    { value: "less_than", label: "Less Than" },
	{ value: "equal", label: "Equal" },
	{ value: "not_equal", label: "Not Equal" },
  ];
   const SelectClass =  props.id ? props.id : ''+''; 
  const ConditionSelect = () => (
    <Select
      name={props.data.value}
      options={props.Option}
      className="ConditionOption"
      classNamePrefix="select"
      styles={customStyles}
	  id={'condition_'+SelectClass}
	  onChange={onInputchange}
    />
  );

 const onInputchange =(event) => {
	 $("#condition_"+SelectClass).val(event.target.value);
  }  
  

  let preselectedvalue = "";
  if(props.presetType==true){
    preselectedvalue = props.data.value;
	if(props.newItem==true){ preselectedvalue = ""; } 
  if($("#condition_"+SelectClass+" input").val()=='') {
	   $("#condition_"+SelectClass+ " input").val(props.data.value);
	   $('.lead-input-container input[type="text"]').val(props.data.value);	   
  } 
 $("#condition_"+SelectClass).val(props.data.value);
  $('.filterSelect.condition_'+SelectClass+' option[value='+props.data.condition+']').attr('selected','selected');
  if(props.data.label=="Caller ID Match" || props.data.label=="Line Type" || props.data.label=="Connected" ){
	if(props.newItem==false){  
    $('.staticfields.condition_'+SelectClass+' option[value='+props.data.value+']').attr('selected','selected');   }
  }
  
  }
let staticFields = false;
let staticOption = [{ value: '', label: '' }];
if(props.data.label=="Caller ID Match" || props.data.label=="Line Type" || props.data.label=="Connected" ){
	staticFields = true;
	if(props.data.label=="Caller ID Match") {
		staticOption = [{ value: 'Yes', label: 'Yes' },{ value: 'No', label: 'No' }];
	}
	
	if(props.data.label=="Line Type") {
		staticOption = [{ value: 'Mobile', label: 'Mobile' },{ value: 'VOIP_Landline', label: 'VOIP or Landline' }];
	}
	
	if(props.data.label=="Connected") {
		staticOption = [{ value: 'Yes', label: 'Yes' },{ value: 'No', label: 'No' }];
	}		
}

let defaultOption = [{ value: '', label: '' }];
let totalFields = false;
if(props.data.label=="Property Address (Total)" || props.data.label=="Times Called (Total)" || props.data.label=="Phone Number (Total)" ){
	defaultOption=[{ value: 'contain', label: 'Contain' },{ value: 'greater_than', label: 'Greater Than' },{ value: 'less_than', label: 'Less Than' },{ value: 'equal', label: 'Equal' },{ value: 'not_equal', label: 'Not Equal' }];		

	totalFields=true;
} else {
	defaultOption=[{ value: 'contain', label: 'Contain' },{ value: 'equal', label: 'Equal' },{ value: 'not_equal', label: 'Not Equal' }];
	totalFields=false;
}
  return (
<React.Fragment>
         <div className={'col-md-3 item'+props.id}>
		   <div className="filterName">{props.data.label}</div>
		  </div>
		  <div className={'col-md-4 item'+props.id}>
		  <div>
			  <select name="test" className={'filterSelect condition_'+SelectClass}>
				 {defaultOption.map(({ value, label }, index) => <option value={value} >{label}</option>)} 
			  </select>
		  </div>
		  </div>
		  <div className={'col-md-5 item'+props.id}>
		   <div className="filtervalue">
	

		{staticFields ==false && totalFields== false  &&
         <Autocomplete2  name={props.data.label} id={'condition_'+SelectClass} value={preselectedvalue} presetType={props.presetType}/> 	
		 
		} 	
		 {staticFields ==true && 
               <select name={props.data.label} className={'staticfields condition_'+SelectClass} id={'condition_'+SelectClass}>
                       {staticOption.map(({ value, label }, index) => <option value={value} >{label}</option>)} 
			  </select>			 
		}
		{staticFields ==false && totalFields== true  && 
		 <Input type="text" name={props.data.label} id={'condition_'+SelectClass} onChange={onInputchange} />
		}
		
		<label className={'item'+props.id}>×</label></div>
		  </div></React.Fragment> )
		
		
	
}
export default FilterComponent

const Input = styled.input`
  width: 85%;
  height: 45px;
  float: left;
  margin-right: 10px;
  border: #d2d2d2 solid 1px;
  border-radius: 5px;
  padding-left: 40px;
  ::placeholder,
  ::-webkit-input-placeholder {
    position: absolute;
    padding: 10px;
    padding-top: 15px;
    padding-left: 5px;
  }
  &:focus {
    border-style: solid;
    outline: none;
  }
`;