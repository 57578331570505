import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { mnipulator, uploadManipulatorFile } from "../../services/dataValidation"
import { getUserID } from "../../utils/helper"
import TopNav from "../../components/navbar/topNav"
import Table from "../../components/navbar/tableItems/data-manipulator/table"
import styled from "styled-components"
import Search from "../../components/search"
import Pagination from "../../components/pagination"
import Loading from "../../components/loading"
import "../../assets/css/style.css"
import FavIcon from "./../../components/favicon"
import COLORS from "../../utils/colors"
import tw from "twin.macro"
import { useHistory } from "react-router-dom"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../components/manipulator-modal";
const DataManipulation = () => {
  
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [show, setShow] = useState(false)
  let history = useHistory()
  document.title = "Data Manipulator | AnalyzeREI"
  useEffect(() => {
    mnipulatorData(noOfPages, recordsPerPage)
  }, [])  
  
    const submitFile = (format, e) => {
         localStorage.removeItem("mappedData");
		 localStorage.removeItem("mappingFields");
		 localStorage.removeItem("mappingData");
		 localStorage.removeItem("reportData");
         setisLoading(true);
		  const file = document.querySelector('input[type=file]').files[0];
		  var fileName = file.name;
		  const reader = new FileReader();

		  reader.addEventListener("load", function () {
			// convert image file to base64 string
			var csv_file = reader.result;
			
			uploadManipulatorFile(
				  {
					file_id: '0',
					csv_file: csv_file,
					file_name: fileName,
					unique_column_match: 'mailing_address',
					update_behavior: 'not_blank',
					format:localStorage.getItem("file_format")
				  },
				  noOfPages
				)
				  .then(function (response) {
					setisLoading(false)
					if(response.status==false) { toast.error(response.message);} else {
				    const fields = response.fileFelds;
				    let optionsSelect = []
				    for (let i = 0; i < fields.length; i++) { optionsSelect.push({ value: fields[i], label: fields[i] }) }
					var data = JSON.stringify(response);
					localStorage.setItem("mappingData", data);
					localStorage.setItem("fileName", fileName);
					localStorage.setItem("mappingFields", JSON.stringify(optionsSelect));
				    localStorage.removeItem("mergeCsv");
					history.push("/mapping-fields"); }
				  })
				  .catch((error) => {
					console.log("error", error)
				  })
			
		  }, false);

		  if (file) {
			reader.readAsDataURL(file);
		  }
	   
  }
  
  function FileFormat(){
	  setShow(true);
  }
  function mnipulatorData(noOfPages, recordsPerPage) {
    setisLoading(true)
    const user = getUserID()
    mnipulator(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setFilteredUserExcelData(response.data)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }  
  
  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    mnipulatorData(1, perPageValue)
  }
  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setShow(false);
        }}
        show={show}
		submitFile={(e)=>submitFile()}
      />	
      <Loading show={isLoading} />
      <TopNav />
      <RightButton>
        <UploadIcon>
          <FavIcon class='fas fa-upload'></FavIcon>
        </UploadIcon>
        <Label>
          Upload CSV
          <Upload
            id='file-selecter'
            type='file'
            size='80'
            onChange={FileFormat}
          />
        </Label>
      </RightButton>	  
	 <Table files={filteredUserExcelData}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={mnipulatorData}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}>
          </Pagination>
      </WrapperPagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
  float:left;
  padding: 10px 0px 0px 20px;
`
const Label = styled.label`
    font-size: 18px;
    display: table;
    cursor: pointer;
    color: #fff;
    padding: 11px 2px 7px 48px;   
	font-family: 'Montserrat';
    float: left; 
	min-width: 172px;
    margin: 0px 0px 0px -38px;
`

const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 172px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
`
export default DataManipulation
