import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {mnipulatorMappingFields } from "../../services/dataValidation"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';


const Modal=(props)=> {
  const [isLoading, setisLoading] = useState(false)
  if(!props.show){ return null; }
  const PropsData =  props.data;
  let filelist_id = PropsData.filelist_id ? PropsData.filelist_id:0;
  let version_id =  PropsData.version_id ? PropsData.version_id :0;
  let data =  props.data.manipulator_fieldsArr ? props.data.manipulator_fieldsArr : [];
	const AutosuggetsLineSelect = (e) => {
	 const items = [];
	 for (const key in data) {
	 if(key!='error_code'){ items.push(<MultiSelect name={key} value={data[key]} key={key}/>); }
	 }
	  return items;
	}
	const DefaultOption=(props)=> {
		$("select[name='"+props.Selected+"'] option[value='"+props.Selected+"']").attr('selected','selected');
		 let items2 =[];
		 let selected='';
		 for (const key in data) {
         if(props.Selected==key) { selected =' selected = "selected'; } else { selected =""; }			 
		 items2.push(<React.Fragment><option value={key} selected={selected}>{data[key]}</option></React.Fragment>);
		 }	
		return items2;
		
	}	
	const MultiSelect=(props)=> {	
	return(<React.Fragment><div className="col-md-6">{props.value}</div> <div className="col-md-6"><select name={props.name}><DefaultOption Selected={props.name}/></select></div></React.Fragment>)
	}
    const saveMapping=(e)=> {
	setisLoading(true);	
    let mappedData = [];
	let i = 0;
    $('.mapping_popup  option:selected').each(function() {
		let key = $(this).val();
		let value = $(this).text();
        mappedData[i] = {[key]:value}
		i++;
    });
	mnipulatorMappingFields({
		action:'update',
		file_id:filelist_id,
		file_version_id:version_id,
		update_column_match:'mailing_address',
		update_column_behavior:'not_blank',
		mapping:mappedData
	}) .then(function (response) {
		if(response.status==true) {
			toast.success(response.message); 
		} else {  toast.error(response.message); }
      })
      .catch((error) => {
		 setisLoading(false);
        //console.log("error", error)
      })
	}	
  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="lead-close"  onClick={props.onClose}>×</button>
      <WrapperContent className="mapping_popup">
        <WrapperHeader>
          <ModalTitle>
          Mapping Fileds
          </ModalTitle>
          <WrapperBody>
		    <div className=" row lead-input-container">
				<AutosuggetsLineSelect/>
			</div>
          </WrapperBody>
          <WrapperFooter>
			<ModalButtonSubmit onClick={(e)=> saveMapping()}>Submit</ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:567px;
min-height:200px;
left:420px;
top:40px;
z-index:1;
display:flex;

`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width: 520px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 20px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
     text-align: center !important;
    float: none;
    width: 100%;
    display: block
`
const WrapperBody = styled.div`
  padding: 10px;
`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
  margin-left: 10px;
`

const ModalButton = styled.button`
  width: 222px;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;

  top:28px;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;
`;