import React, { Component } from "react";
import ReactDOM from "react-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null
    };
	
  }
  handleChange = (selected) => {
	console.log("checked", selected);
    this.setState({
      optionSelected: selected
    });
  };
  
  
  render() {
   var colourOptions = this.props.list.length ? this.props.list : [];

  var customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      minHeight: "35px",
    }),
  };   
    return (
      <span
        class="d-inline-block"
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
      >
        <ReactSelect
		  className="tableFields"
          options={colourOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          onChange={this.handleChange}
          allowSelectAll={true}
          value={this.state.optionSelected}
		  styles={customStyles}
        />
      </span>
    );
  }
}
