import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import COLORS from '../../utils/colors';
import Select from 'react-select'
import {getActionData, ManipulatorActions } from "../../services/dataValidation"
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Component from './../creatable-select';
import $ from 'jquery';
import {useParams} from "react-router-dom";
const Modal=(props)=> {
  const [selected, setselected] = useState(false);
  const [lselected, setlselected] = useState(false);
  const [LineValue, setLineValue] = useState(false);
  const [CallerValue, setCallerValue] = useState(false);
  const [downloadValue, setdownloadValue] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  let { fileID } = useParams();
  if(!props.show){ return null;}
  const files = props.data ? props.data : [];
  const record_id = props.selectedRecord ? props.selectedRecord : '';
 
  let defaultItems = [];
  for (let i = 0; i < files.length; i++) {
      defaultItems.push({label: files[i], value: files[i]})
  }
  
  function addTags() {
	  let record_ids =[];
	  const tags = JSON.parse(localStorage.getItem('tags'));
	   	   setisLoading(true);
			getActionData(
			  {
				file_id: fileID,
				action: 'add_tag',
				tags:tags,
				record_ids:[record_id]
			  }
			)
			  .then(function (response) {
				setisLoading(false);
				if(response.status==false){
			     toast.error(response.message);
				}else {
				toast.success(response.message); 
                //setTimeout(function () { window.location.reload(false); }, 1000);
				}
			  })
			  .catch((error) => {
				toast.error(error.message);
			  });
					  
  }

  return (
    <WrapperModal>
	<button _ngcontent-hex-c78="" type="button" data-dismiss="modal" className="tag-close"  onClick={props.onClose}>×</button>
      <WrapperContent>
        <WrapperHeader>
          <ModalTitle>
            <Para2>Tags</Para2>
          </ModalTitle>
          <WrapperBody>
		    

		    <div className="lead-input-container">
				<Component preselectedItems={defaultItems}/>
			</div>
          </WrapperBody>
          <WrapperFooter>
			<ModalButtonSubmit onClick={(e) => addTags()}>Submit</ModalButtonSubmit>
          </WrapperFooter>
        </WrapperHeader>
      </WrapperContent>
    </WrapperModal>
  )
}

export default Modal;

const WrapperModal=styled.div`
position:fixed;
width:367px;
min-height:200px;
left:535px;
top:40px;
z-index:1;
display:flex;
z-index:999;
`;
const WrapperHeader=styled.div`
`;
const WrapperContent = styled.div`
    width: 367px;
    background-color: #ffffff;
    border-radius: 24px;
    padding: 20px 20px;
    border: #d1d1d1 solid 1px;
`
const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 367px;
  height: 60px;
`
const WrapperBody = styled.div`
  padding: 10px;


`
const WrapperFooter = styled.div`
  padding: 10px;
  text-align:center;
`

const ModalButtonSubmit = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#89DE58;
  color:#FFF;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;
`

const ModalButton = styled.button`
  width: 100%;
  height: 40px;
  color: blue;
  font-size: 15px;
  font-weight: bold;
  background-color:#D4D4D4;
  color:#6D6E6E;
  margin-top:30px;
  box-shadow: 0px 13px 24px rgba(160, 174, 188, 0.34);
  &:hover {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
  }
  border-radius: 5px;

`
const Para = styled.p`
  color: #047CFF;
  font-weight: bold;
  font:size:10px;
  position: relative;
  left:20px;
  top: 28px;
`
const Para2 = styled.p`
  color: 000000;
  font:size:10px;
  font-weight: bold;
  position:relative;
  width:100%;
  top:28px;
  text-align:center;
  max-width:325px;
`
const Para3=styled.p`
`;
const Selected=styled.select`
width:500px;
margin-right:30px;
background-color:white;
border-radius:5px;

`;