import React, { useEffect, useState } from "react"
import { getUsersExcelData, getAvailbleCreadit } from "../../services/dataValidation"
import { getUserID } from "../../utils/helper"
import TopNav from "../../components/navbar/topNav"
import Table from "../../components/navbar/tableItems/table"
import styled from "styled-components"
import Search from "../../components/search"
import Pagination from "../../components/pagination"
import Loading from "../../components/loading"
import "../../assets/css/style.css"
import $ from 'jquery';
const DataValidation = () => {
  const [userExcelData, setExcelUserData] = useState([])
  const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
  const [noOfPages, setNoofPages] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(10)
  const [totalRecords, setTotalRecords] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [getTotalExcelData, setTotalExcelData] = useState(false)
  const [availableCreadit, setAvailableCreadit] = useState();
document.title = "Phone validation | AnalyzeREI"
  useEffect(() => {
    GetAvailbleCreaditdata()
  }, [])
  useEffect(() => {
    getFiles(noOfPages, recordsPerPage)
  }, [])
  
 useEffect(() => {
  const interval = setInterval(() => {
   getFiles2(noOfPages, recordsPerPage)
  }, 5000);
  return () => clearInterval(interval);
}, []); 

  const GetAvailbleCreaditdata  = (searchTerm) => { 
  getAvailbleCreadit({ user_id: getUserID()})
      .then(function (response) {
		  setAvailableCreadit(state => (response.balance_credit));
      })
      .catch((error) => {
        alert("Some error occured")
        console.log("file upload error: ", error)
      }) 
  }
	  
  function ViewAll() {
    setisLoading(true)
    const user = getUserID()
    getUsersExcelData({
      user_id: user,view: 'yes',
    })
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function getFiles(noOfPages, recordsPerPage) {
    setisLoading(true)
    const user = getUserID()
    getUsersExcelData(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setisLoading(false)
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  function getFiles2(noOfPages, recordsPerPage) {
	noOfPages = $("li.paginationActive a").text();  
	recordsPerPage = $("select.PerPageSelect option").filter(":selected").val();
    const user = getUserID()
    getUsersExcelData(
      {
        user_id: user,
        per_page: recordsPerPage,
      },
      noOfPages
    )
      .then(function (response) {
        setTotalRecords(response.total_records)
        setExcelUserData(response.data)
        setFilteredUserExcelData(response.data)
        setTotalExcelData(response.data.length)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  function onChangeRecordPerPage(perPageValue, pageCount) {
    setRecordsPerPage(perPageValue)
    setNoofPages(pageCount)
    getFiles(pageCount, perPageValue)
  }

  const search = (searchTerm) => {
    if (searchTerm !== "") {
      let filteredList = []
      userExcelData.forEach((file) => {
        if (file.file_name.toLowerCase().includes(searchTerm.toLowerCase())) {
          filteredList.push(file)
        }
      })
      setFilteredUserExcelData(filteredList)
    } else {
      setFilteredUserExcelData(userExcelData)
    }
  }
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav />
      <SearchWrapper>
        <Search search={search} availableCreadit={availableCreadit}/>
      </SearchWrapper>
      <Table files={filteredUserExcelData} allData={ViewAll}></Table>
      <WrapperPagination>
        <Pagination
          getPageData={getFiles}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onChangeRecordPerpage={onChangeRecordPerPage}>
          </Pagination>
      </WrapperPagination>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`

export default DataValidation
