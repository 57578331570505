import React, { useEffect, useState } from "react"
import { Link, useParams} from 'react-router-dom';
import { uploadManipulatorFile, getAvailbleCreadit } from "./../../../../services/dataValidation";
import TopNav from "./../../../../components/navbar/topNav"
import FavIcon from "./../../../../components/favicon"
import styled from "styled-components"
import Loading from "./../../../../components/loading"
import "./../../../../assets/css/style.css";
import RangeSlider from "./../../../../components/rangslider"
import COLORS from "./../../../../utils/colors"
import tw from "twin.macro"
import { getUserID } from "./../../../../utils/helper"
import Button from "./../../../../components/button";
import { useHistory } from "react-router-dom"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

const MergeCsv = () => {
const [isLoading, setisLoading] = useState(false)
const [availableCreadit, setAvailableCreadit] = useState();
let history = useHistory();
 let { fileID } = useParams();

 document.title = "Merge Csv | AnalyzeREI";
  useEffect(() => {
    getAvailbleCreadit2()  }, []);
    function getAvailbleCreadit2() {
		const userID = getUserID()
		getAvailbleCreadit({ user_id: userID})
		  .then(function (response) {
			  setAvailableCreadit(state => (response.balance_credit));
		  })
		  .catch((error) => {
			alert("Some error occured")
			console.log("file upload error: ", error)
		  })
	}

	const getFileName = (event) =>{
		  const file = document.querySelector('input[type=file]').files[0];
		  var fileName = file.name;
		  $(".fileNamelabel").text(fileName);
	}  
    const onFileChange = (event) => {
         //setisLoading(true);
		  const file = document.querySelector('input[type=file]').files[0];
		  if(file==undefined) {
			   toast.error("Please upload a CSV file before continuing"); 
			   return false;
		  }		  
		  var fileName = file.name;
		  const reader = new FileReader();
		 var i = 0;
		  if (i == 0) {
			i = 1;
			var elem = document.getElementById("UploadBar");
			var width = 1;
			var id = setInterval(frame, 10);
			function frame() {
			  if (width >= 80) {
				clearInterval(id);
				i = 0;
			  } else {
			  if(width<=80){
				width++;
				elem.style.width = width + "%";
				}
				$("#progresscount").html(width+"%");
			  }
			}
		  }
		  reader.addEventListener("load", function () {
			// convert image file to base64 string
			var csv_file = reader.result;
			var matchColumn = $('.matchColumn:checked').val();
			var match_behaviour = $('.match_behaviour:checked').val();
			uploadManipulatorFile(
				  {
					file_id: fileID,
					csv_file: csv_file,
					file_name: fileName,
					unique_column_match: 'mailing_address',
					update_behavior: 'not_blank',
					matchColumn:matchColumn,
					match_behaviour:match_behaviour,
					format:localStorage.getItem("file_format")
					
				  }
				)
				  .then(function (response) {
						var j = 1;
						var elem2 = document.getElementById("UploadBar");
						var width1 = 81;
						var id = setInterval(frame2, 10);
						function frame2() {
						  if (width1 >= 100) { $("#count").html("100%");
							clearInterval(id);
							j = 0;		
						} }							
					setisLoading(false);
					if(response.status==false) { toast.error(response.message);} else {
				    const fields = response.fileFelds;
				    let optionsSelect = []
				    for (let i = 0; i < fields.length; i++) { optionsSelect.push({ value: fields[i], label: fields[i] }) }
					var data = JSON.stringify(response);
					localStorage.removeItem("mappingData");
					localStorage.removeItem("mappingFields");
					localStorage.removeItem("mappedData");
					localStorage.removeItem("reportData");
					localStorage.setItem("mappingData", data);
					localStorage.setItem("fileName", fileName);
					localStorage.setItem("mappingFields", JSON.stringify(optionsSelect));
					history.push("/mapping-fields");
					localStorage.setItem("mergeCsv", fileID); }
				  })
				  .catch((error) => {
					console.log("error", error)
				  })
			
		  }, false);

		  if (file) {
			reader.readAsDataURL(file);
		  }
	   
  }

const [input, progress, label] = [
  document.querySelector("input")
  , document.querySelector("progress")
  , document.querySelector("label")
];
  return (
    <Wrapper>
      <Loading show={isLoading} />
      <TopNav /> 
	  <div className="row mapping_fields merge top">
	    <div className="col-md-3">
		<div className="row left_side"> 
		<div className="col-md-8">
        <Link to='/data-manipulator'><span className="icon_heading"> Upload File </span></Link>
		<Link to='/mapping-fields'> <span className="icon_heading"> Map Fields </span></Link>
        <Link to='/report'><span className="icon_heading"> Report  </span></Link>
		</div> <div className="col-md-4">
		   <div className="main_icons">
		    <span className="left_icons active">
	<svg width="21" height="21" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.238 6.94117C14.4731 6.94117 14.6979 7.03753 14.8597 7.20729L20.8817 13.5161C21.4036 14.0621 21.0159 14.9706 20.26 14.9706L17.1056 14.9706L17.1056 21.5662C17.1056 22.3565 16.4621 23 15.6718 23L12.8041 23C12.0138 23 11.3703 22.3565 11.3703 21.5662L11.3703 14.9706L8.2159 14.9706C7.45999 14.9706 7.07229 14.0621 7.5942 13.5161L13.6163 7.20729C13.778 7.03753 14.0028 6.94117 14.238 6.94117Z" fill="white"/>
<path d="M2.47794 0.058735L25.9926 0.0587371C27.0996 0.0587372 28 0.959178 28 2.06609L28 2.63962C28 3.74653 27.0996 4.64697 25.9926 4.64697L2.47794 4.64697C1.37103 4.64697 0.470589 3.74653 0.470589 2.63962L0.470589 2.06609C0.470589 0.959176 1.37103 0.058735 2.47794 0.058735Z" fill="white"/>
</svg>

			
			</span>
			</div>		
             <div className="icon_border" />
			  <div className="main_icons">
		    <span className="left_icons">
<svg width="18" height="18" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.001 0H6.00631C5.17818 0 4.50684 0.671342 4.50684 1.49947C4.50684 2.3276 5.17818 2.99895 6.00631 2.99895H21.001C21.8292 2.99895 22.5005 2.3276 22.5005 1.49947C22.5005 0.671342 21.8292 0 21.001 0Z" fill="#A0A0A0"/>
<path d="M3.00734 1.49951C3.00566 1.10247 2.84657 0.722311 2.565 0.442378C1.98016 -0.138996 1.03559 -0.138996 0.450744 0.442378C0.169124 0.722311 0.0100394 1.10247 0.00839939 1.49951C-0.0027998 1.59664 -0.0027998 1.69477 0.00839939 1.7919C0.0253622 1.8896 0.0530087 1.98515 0.0908704 2.0768C0.131122 2.1656 0.178777 2.25088 0.23332 2.33171C0.287161 2.41578 0.349998 2.4937 0.420754 2.56413C0.48898 2.63208 0.564376 2.69243 0.645675 2.74407C0.724632 2.80156 0.810242 2.84941 0.900586 2.88652C0.999973 2.93806 1.10592 2.97593 1.21547 2.99898C1.31261 3.00985 1.41073 3.00985 1.50787 2.99898C1.90383 2.99931 2.28385 2.84299 2.565 2.56413C2.63576 2.4937 2.69859 2.41578 2.75243 2.33171C2.80698 2.25088 2.85463 2.1656 2.89488 2.0768C2.94273 1.98627 2.98045 1.89073 3.00734 1.7919C3.01854 1.69477 3.01854 1.59664 3.00734 1.49951Z" fill="#A0A0A0"/>
<path d="M3.00773 8.24707C3.01889 8.14993 3.01889 8.05181 3.00773 7.95467C2.98182 7.8579 2.94405 7.76475 2.89527 7.67727C2.85666 7.58528 2.80891 7.49738 2.75282 7.41486C2.70086 7.33173 2.63779 7.25605 2.56539 7.18994C1.98055 6.60856 1.03597 6.60856 0.451133 7.18994C0.169514 7.46987 0.0104291 7.85003 0.00878906 8.24707C0.0116943 8.44467 0.0497903 8.64012 0.121249 8.82436C0.15883 8.91307 0.204002 8.99835 0.256202 9.07927C0.313229 9.16076 0.37855 9.23616 0.451133 9.30419C0.517391 9.37645 0.593021 9.43952 0.676054 9.49163C0.755011 9.54917 0.840575 9.59701 0.930965 9.63408C1.02239 9.67255 1.11802 9.70024 1.21586 9.71655C1.31178 9.73806 1.40995 9.74813 1.50826 9.74654C1.6054 9.75774 1.70352 9.75774 1.80066 9.74654C1.89606 9.73019 1.98922 9.70249 2.07806 9.66407C2.17089 9.62724 2.25898 9.57939 2.34047 9.52162C2.4235 9.46951 2.49913 9.40644 2.56539 9.33418C2.63765 9.26793 2.70072 9.1923 2.75282 9.10926C2.81046 9.03035 2.8583 8.94479 2.89527 8.85435C2.94649 8.75482 2.9843 8.64892 3.00773 8.53946C3.01926 8.44232 3.01926 8.3442 3.00773 8.24707Z" fill="#A0A0A0"/>
<path d="M3.00783 14.9946C3.01893 14.8975 3.01893 14.7994 3.00783 14.7022C2.98191 14.6031 2.94415 14.5074 2.89537 14.4173C2.85511 14.3285 2.80746 14.2432 2.75292 14.1624C2.70081 14.0794 2.63774 14.0038 2.56548 13.9375C1.98064 13.3561 1.03607 13.3561 0.451225 13.9375C0.37897 14.0038 0.315898 14.0794 0.263791 14.1624C0.209248 14.2432 0.161593 14.3285 0.121341 14.4173C0.0824489 14.5086 0.0547555 14.6043 0.0388704 14.7022C0.0177372 14.7982 0.00770952 14.8963 0.00888098 14.9946C0.0105679 15.3917 0.169653 15.7718 0.451225 16.0518C0.517483 16.124 0.593113 16.1871 0.676146 16.2392C0.755103 16.2967 0.840667 16.3446 0.931057 16.3816C1.02248 16.4201 1.11812 16.4478 1.21596 16.4641C1.31188 16.4856 1.41004 16.4957 1.50835 16.4941C1.60549 16.5053 1.70361 16.5053 1.80075 16.4941C1.89615 16.4777 1.98931 16.45 2.07815 16.4116C2.17098 16.3748 2.25907 16.327 2.34056 16.2692C2.42359 16.2171 2.49922 16.154 2.56548 16.0817C2.63774 16.0155 2.70081 15.9399 2.75292 15.8568C2.8106 15.778 2.85844 15.6923 2.89537 15.6019C2.94654 15.5023 2.98435 15.3965 3.00783 15.287C3.01935 15.1899 3.01935 15.0918 3.00783 14.9946Z" fill="#A0A0A0"/>
<path d="M22.5005 6.74756H6.00631C5.17818 6.74756 4.50684 7.4189 4.50684 8.24703C4.50684 9.07516 5.17818 9.74651 6.00631 9.74651H22.5005C23.3286 9.74651 24 9.07516 24 8.24703C24 7.4189 23.3286 6.74756 22.5005 6.74756Z" fill="#A0A0A0"/>
<path d="M15.7529 13.4951H6.00631C5.17818 13.4951 4.50684 14.1665 4.50684 14.9946C4.50684 15.8227 5.17818 16.4941 6.00631 16.4941H15.7529C16.581 16.4941 17.2524 15.8227 17.2524 14.9946C17.2524 14.1665 16.581 13.4951 15.7529 13.4951Z" fill="#A0A0A0"/>
</svg>




			</span>	</div>		
			
           <div className="icon_border" />
			  <div className="main_icons">
		    <span className="left_icons">
			<svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3333 7.08333C10.5527 7.08333 9.91667 6.44725 9.91667 5.66667V0H1.41667C0.636083 0 0 0.636083 0 1.41667V21.25C0 22.032 0.636083 22.6667 1.41667 22.6667H15.5833C16.3653 22.6667 17 22.032 17 21.25V7.08333H11.3333ZM5.66667 19.8333H2.83333V15.5833H5.66667V19.8333ZM9.91667 19.8333H7.08333V12.75H9.91667V19.8333ZM14.1667 19.8333H11.3333V9.91667H14.1667V19.8333Z" fill="#A0A0A0"/>
</svg>

			</span>	</div>				
		</div>
		
		
		</div>
		
		
		
		</div>
		
	    <div className="col-md-9">
	    <span className="icon_heading_black"> Match Column </span>
		{/*<Credit>
        <Inner>
          <Icon>
            <FavIcon class='far fa-star'></FavIcon>
          </Icon>
          <AvailableCredit>Available Credit</AvailableCredit>
        </Inner>
        <Data>{availableCreadit}</Data>
		</Credit>	*/}
		<div className="row matchColumn">
		<div className="col-md-3"><input type="Radio" name="match_column" value="Phone Number" className="matchColumn"/>Phone Number</div> <div className="col-md-9"><input type="Radio" name="match_column" value="Mailing Address" className="matchColumn"/>Mailing Address</div>
		</div>
		
		<span className="icon_heading_black"> Data Behaviour </span>
		
		<div className="row matchColumn">
		<div className="col-md-4"><input type="Radio" name="match_behaviour" value="Overwrite existing data" className="match_behaviour"/>Overwrite existing data</div> <div className="col-md-8"><input type="Radio" name="match_behaviour" value="Update blank cells" className="match_behaviour"/>Update blank cells</div>
		</div>
        <div className="row form upload-process-revamp">		
	

	 <MainButton>
              <UploadIcon>
                <svg
                  width="21"
                  height="18"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.38728 6.3087H7.41836V12.3339C7.41836 12.9452 7.91401 13.4402 8.52469 13.4402H11.5445C12.1551 13.4402 12.6508 12.9452 12.6508 12.3339V6.3087H14.6812C15.0176 6.3087 15.3199 6.10617 15.4493 5.79533C15.5773 5.48576 15.5061 5.12788 15.2684 4.89022L10.6217 0.242915C10.2971 -0.0809717 9.77136 -0.0809717 9.44612 0.242915L4.80016 4.89022C4.56246 5.12788 4.49125 5.48515 4.61994 5.79533C4.74919 6.10556 5.05091 6.3087 5.38728 6.3087Z"
                    fill="white"
                  />
                  <path
                    d="M19.0717 8.56689C18.5211 8.56689 18.0745 9.01362 18.0745 9.56478V13.7373C18.0745 14.9872 17.0569 16.0041 15.807 16.0041H4.26259C3.0126 16.0041 1.99577 14.9872 1.99577 13.7373V9.56478C1.99577 9.01362 1.54843 8.56689 0.997887 8.56689C0.446686 8.56689 0 9.01362 0 9.56483V13.7373C0 16.0877 1.91216 17.9999 4.26259 17.9999H15.807C18.1574 17.9999 20.0696 16.0877 20.0696 13.7373V9.56483C20.0696 9.01362 19.6229 8.56689 19.0717 8.56689Z"
                    fill="white"
                  />
                </svg>
              </UploadIcon>
              <Label>
                Upload New File
                <Upload
                  id="file-selecter"
                  type="file"
                  size="80"
                  onChange={getFileName}
                />
              </Label>
			  
            </MainButton>
			<div className="fileNamelabel"/>
						<div className="main_status_bar">
			    <span className="Status">Status</span>
			    <div id="progresscount" >0%</div></div>
				<div id="UploadProgress">
				  <div id="UploadBar" />
				</div>

  	        <div className="submitBtnMain mergeCsv"> 
			<MainButton className="merge-csv-btn">
              <Label>
                Next
                <Upload
                  id="merge-upload-selecter"
                  type="button"
                  size="80"
				  onClick={onFileChange}
                />
              </Label>
            </MainButton>
			
			</div>
			<div></div>
		
		</div>
		</div>		
	  </div>
	  
    </Wrapper>
  )
}
const MainButton = styled.div`
  float: left;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 220px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height: 44px;
  cursor: pointer;
  margin-left: 12px;

  ${tw`
    shadow-lg
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative
`
const SearchWrapper = styled.div`
  margin-top: 2%;
`
const WrapperPagination = styled.div`
  width: 98%;

`

const Upload = styled.input`
  display: none;
  cursor: pointer;
`
const UploadIcon = styled.span`
  color: white;
  cursor: pointer;
  float:left;
  padding: 10px 0px 0px 20px;
`
const Label = styled.label`
  margin-left: 10px;
  font-size: 16px;
  display: table;
  cursor: pointer;
  color: #fff;
  padding: 10px 0px 10px 0px;
  font-family: 'Montserrat';
  float:left;
`
const Credit = styled.div`
  display: block;
  width:50%;
  float:left;
`
const Inner = styled.div`
  display: inline-flex;
  text-align:right;
  float:right;
`
const Icon = styled.p`
  color: ${COLORS.grey};
  margin-right: 10px;
  margin-top: -2px;
  border: solid 1px #959595;
  padding: 2px;
  border-radius: 50%;
  line-height: 1;
`
const Data = styled.h6`
  color: ${COLORS.blue};
  dispaly: block;
  text-align:center;
  font-weight: 800;
  font-family: Montserrat;
  font-size:24px;
  padding-left:15px;
  float:left;
  text-align:right;
  width:100%;
`

const AvailableCredit = styled.h6`
  color: ${COLORS.grey};
  font-weight: 500;
`
const RightButton = styled.div`
  float:right;
  background-color: ${COLORS.lightGreen};
  align-items: center;
  border-radius: 7px;
  width: 172px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  max-height:44px;
  cursor: pointer;
  margin-right: 38px;

  ${tw`
    shadow-lg
  `}
`
export default MergeCsv
