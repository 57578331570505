import React, { useEffect, useState  } from "react"
import { getUsersTransactionData } from "../../services/paymentDeatil"
import { makeStripeDefultCard } from "../../services/paymentDeatil"
import { getUserCreditCardsAPI } from "../../services/paymentDeatil"
import { deleteStripeCardAPI } from "../../services/paymentDeatil"
import TopNavProfile from "../../components/navbar/TopNavProfile"
import styled from "styled-components"
import { Link } from "react-router-dom"
import PaymentLogo from "../../assets/images/credit-card.svg"
import CreaditLogo from "../../assets/images/favorite.svg"
import ImageCard from "../../assets/images/credit-card 1.png"
import Cardone from "../../assets/images/card1.png"
import Cardtwo from "../../assets/images/card2.png"
import visa from "../../assets/images/visa.png"
import "../../assets/css/style.css"
import { STRIPE_KEY } from "../../config"
import { BASE_URL } from "../../config"
import { getUserID } from "../../utils/helper"
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import {CardElement} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import axios from 'axios';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import ReactPaginate from 'react-paginate'; 
import Loading from "../../components/loading"
import Pagination from "react-js-pagination";
import {paybutton} from "../../services/paymentDeatil"
import {Credits} from "../credits"
import { render } from "@testing-library/react"
import  useQuery  from './useQuery';
import { useHistory } from "react-router"

const stripePromise = loadStripe(STRIPE_KEY);
var i = [];

const CreditPaymet = (props) => {
    let history = useHistory()
    const [getValue , setValue] = useState(7600)
    const getCards = Math.floor(Math.random() * (2500 - 7500 +1 )) + 7500;
    const [loading, setLoading] = useState(false);
    const [userExcelData, setExcelUserData] = useState([])
    const [filteredUserExcelData, setFilteredUserExcelData] = useState([])
    const [noOfPages, setNoofPages] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(1)
    const [isLoading, setisLoading] = useState(false)
    const [getPayment, setPaymentData] = useState([]);
    const [getMyCardData, setMyCardData] = useState([]);
    const [activePage, setactivePage] = useState(5);
    const [gettotalItemsCount, setTotalItemsCount] = useState();
    const recordPerPage = 5;
    const totalRecords = gettotalItemsCount;
    const pageRange = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(2);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = getPayment.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const[pageNumber , setPageNumber] = useState(0);
    const[getCreditCardId , setCreditCardId] = useState([]);
    const usersPerPage = 5;
    const pageVisited = pageNumber * usersPerPage;
    const currentUserPageData = getPayment.slice(pageVisited,pageVisited + usersPerPage);
    const pageCount = Math.ceil(getPayment.length/usersPerPage);

    const query = useQuery();
    const getCardPrice = query.get("p") || "";
    const getRangeValueData = query.get("rangevalue") || "";
    const myInt = parseInt(getCardPrice); 
    const myStringValue = getCardPrice.toString();

    let priceObject = localStorage.getItem("purchasePrice");
    var obj = JSON.parse(priceObject);
    let getPurchasePrice = obj;
    const myCardPrice = parseInt(getPurchasePrice); 

    let rangeceObject = localStorage.getItem("rangevalue");
    var obj2 = JSON.parse(rangeceObject);
    let getRangevalue = obj2;

    const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
    }
  
    const changePage = ({selected}) => {
        setPageNumber(selected);
    }

    useEffect(() => {
        setLoading(true)
        getUserCreditCardsDetails()
        console.log(props.message)
    }, [])

    function getUserCreditCardsDetails() {
        setisLoading(true)
        const userId = getUserID()
        getUserCreditCardsAPI({
            user_id: userId,
        })
        .then(function (response) {
            setisLoading(false)
            setMyCardData(response);
        })
        .catch((error) => {
            console.log("error", error)
        })
    } 

    function makeDefaultCardAPI (id) {  
        setisLoading(true)
        const userId = getUserID()
        makeStripeDefultCard({
            stripe_card_id: id, 
            user_id: userId
        })
        .then(function (response) {
            if(response.status === true){
                setisLoading(false)
                console.log(response)
                toast.success(response['message'])  
                getUserCreditCardsDetails()
              }else{
                console.log(response)
                toast.error(response['message'])    
              }  
        })
        .catch((error) => {
            console.log("error", error)
        })
    }   
    
    function deleteStripeCardData (id) {  
        setisLoading(true)
        const userId = getUserID()
        deleteStripeCardAPI({
            stripe_card_id: id, 
            user_id: userId
        })
        .then(function (response) {
            if(response.status === true){
                setisLoading(false)
                console.log(response)
                toast.success(response['message'])  
                getUserCreditCardsDetails()
              }else{
                console.log(response)
                toast.error(response['message'])    
              }  
        })
        .catch((error) => {
            console.log("error", error)
        })
    }   
    const amount = Math.round(getPurchasePrice*100);

    //default card payment
    function payButtonApi () {
        var creditCardId;
        for (var i=0; i < getMyCardData.length; i++) {
            if(getMyCardData[i]['default_card'] === true){
                creditCardId = getMyCardData[i].id
            }
        }
        setisLoading(true)
        const userId = getUserID()
        console.log(creditCardId)
        console.log({
            card_id: creditCardId, 
            user_id : userId,
            credit : getPurchasePrice,
            save_card: "no",
        })
        paybutton({
            card_id: creditCardId, 
            user_id : userId,
            credit : getRangevalue.replace(/,/g, ''),
			amount:amount,
            save_card: "no",
        })
        .then(function (response) {
            if(response.status === true){
                setisLoading(false)
                console.log(response)
                toast.success(response['message'])  
                history.push('/data-validation') 
              }else{
                setisLoading(false)
                console.log(response)
                toast.error(response['message'])    
              }  
        })
        .catch((error) => {
            console.log("error", error)
        })
    }

    const backToCredit = () => {
        localStorage.setItem("purchasePrice", JSON.stringify(''))
        localStorage.setItem("rangevalue", JSON.stringify(''))
        history.push('/credits')
    }

    return (
        <Wrapper>
            <Loading show={isLoading} />
            <TopNavProfile />
            <div className="payments-method-page width-inpayment-set">
                <div className="wrapper-payments-method-page">
                    <div className="container-fluid">
	  <div className="MainDownloadTabs">
      <ul><li  className="active"><Link to='/payments'>Payments</Link></li> <li> <Link to='/credits'> Credits</Link></li></ul>
	  </div>
                        <div className="main-div-payments-card">
                            <div className="second-div-for-option">
                                <div className="headding-payment">
                                    <h1>Add Credit</h1>
                                </div>
                                <div className="my-card-payments my-radio-cards">
                                    <div className="card">
                                        <div className="row">
                                            <div className="col-md-6">
                                            <div className="card-headding-payment">
                                                <h3>My Cards</h3>
                                                <p>Select card for all Purchases</p>
                                            </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="right-side-div-credit-payment">
                                                    <div className="icon-in-add-pay">
                                                        <i class='far fa-star'></i>
                                                        <span className="purchese-text-ap">Purchase {getRangevalue} Credits for</span>
                                                        <span className="amount-text-ap"> {"$" + getPurchasePrice}</span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="row">
                                        { getMyCardData.length>0 &&
                                                getMyCardData.map(paymentData => (
                                                    <div className="col-md-6">
                                                        <div className="my-radio-button-payment">
                                                            <div className="card">
                                                                {
                                                                    (paymentData['default_card'] === true )
                                                                    ?<input type="radio" id="card1" name="fav_language" value="card1"  
                                                                    checked/>

                                                                    : <input type="radio" id="card1" name="fav_language" value="card1" disabled/>
                                                                }
                                                                
                                                                <label htmlFor="card1">
                                                                { 
                                                                    (paymentData['type'] === 'Visa' )
                                                                    ?<span> <img src={visa} className="img-hw-payment" alt="img" /></span>

                                                                    : <span> <img src={Cardone} className="img-hw-payment" alt="img" /></span> 
                                                                }
                                                                    <span className="card-name-text-paym"><p id="getCardlast4">{paymentData.card_number}</p></span>
                                                                    <span className="d-flex links-for-default-card">
                                                                        <button type="submit" className="defaule-card-payment" onClick={() => makeDefaultCardAPI(paymentData.id)} >Make default</button>
                                                                        <button type="submit" className="remove-card-payment" onClick={() => deleteStripeCardData(paymentData.id)} >Remove</button>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className="button-in-credit-two">
                                            <button className="btn-pay"  onClick={() =>payButtonApi()}>Pay</button>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div className="my-card-payments">
                                    <div className="card">
                                        <div className="card-headding-payment">
                                            <h3>Add Card</h3>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <Elements stripe={stripePromise}>
                                                    <CheckoutForm />
                                                </Elements>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
    
}


const Wrapper = styled.div`
  width: 100%;
  position: relative  
`



export default CreditPaymet
