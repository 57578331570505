import React, { useState,useEffect } from "react"
import styled from "styled-components"
import COLORS from "../../../../utils/colors"
import Item from "./tableItem"
import { getUserID } from "../../../../utils/helper"
import $ from 'jquery';
const Table = (props) => {
  const [selectedExceldata,setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [getExcelTotle, setExcelTotle] = useState([]);
  const [selectedExceldataSingle, setselectedExceldataSingle] = useState([]);
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);  
  const files = props.files ? props.files : []
  console.log("check xx conta here", files);
  const items = []
  for (let i = 0; i < files.length; i++) {   
 
    items.push(
      <Item
        phone={files[i].phone}
        phone_position={files[i].phone_position}
        carrier={files[i].carrier}
        caller_id_name={files[i].caller_id_name}
        caller_id_match={files[i].caller_id_match}
        key={i}
        connected={files[i].connected}
		original_disposition={files[i].original_disposition}
		times_called = {files[i].times_called}
		id ={files[i].id}
		>
        </Item>
    )
  }
  function checkAllCheckbox(event){
    const allCheckboxChecked = event.target.checked
    var checkboxes = document.getElementsByName('slectedCheckBox[]')
    if(allCheckboxChecked){
      setslectedBoxTrue(true);
      for(var i in checkboxes){
        if(checkboxes[i].checked == false){
          checkboxes[i].checked = true;
          var inputElems = document.getElementsByName('slectedCheckBox[]'),
          count = 0;
          for (var i=0; i<inputElems.length; i++) {
            if (inputElems[i].type === "checkbox" && inputElems[i].checked === true){
              count++;
              // alert(count);             
              setSelectedExceldata(count);
            }
			$(".DeleteAction").show();
          }
        }
      }
    }else{ $(".DeleteAction").hide();
      setslectedBoxTrue(false);
      for(var i in checkboxes){
        if(checkboxes[i].checked == true){
          count--;
          setSelectedExceldata(null);
          checkboxes[i].checked = false;
        }
      }
    }
  } 
  //select single checkbox
  function checkboxes(){
    var inputElems = document.getElementsByTagName("input"),
    count = 0;
    for (var i=0; i<inputElems.length; i++) {
      if (inputElems[i].type === "checkbox" && inputElems[i].checked === true){
        count++;
        setSelectedExceldata(count);
      }
    }
  }


  return (
    <Wrapper>
      <WrapperTitle>
      </WrapperTitle>
      <div className="scroll-in-mobile dowloads">
	  {items.length >0 &&
	  <table className="listing crm-phone">
	 <tr className="heading">
	<th>Phone Number</th>
	<th>Phone Position</th>
	<th>Carrier</th>
	<th>Caller ID Name</th>
	<th>Caller ID Match</th>
	<th>Line Type</th>
	<th>Conntected</th>
	<th>Disposition</th>
	<th>Time Called</th>

	</tr>
      {items}
	  </table> }
      </div>
    </Wrapper>
  )
}
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top:14px;
`
const HeadingDelete = styled.h2`
  color: ${COLORS.red};
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`
const View = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.lightblue};
`
export default Table
