import React, { useState, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../../utils/colors";
import Item from "./tableItem";
import ReactTooltip from "react-tooltip";
import $ from 'jquery';
const Table = (props) => {
  const [selectedExceldata, setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [getExcelTotle, setExcelTotle] = useState([]);
  const [selectedExceldataSingle, setselectedExceldataSingle] = useState([]);
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);
  const files = props.files ? props.files : [];
 
  const items = [];
  for (let i = 0; i < files.length; i++) {
	const Owner1 = files[i].owner_1_first_name+' '+files[i].owner_1_last_name;
    const Owner2 = files[i].owner_2_first_name+' '+files[i].owner_2_last_name; 	
    items.push(
      <Item
        id={files[i].id}
        Owner1={Owner1}
        Owner2={Owner2}
        mailing_address={files[i].mailing_address}
		mailing_city={files[i].mailing_city}
		mailing_state={files[i].mailing_state}
		mailing_zip={files[i].mailing_zip}
		addreses={files[i].addreses}
		phone_number={files[i].phone_number}
		tag={files[i].tag}
		times_called={files[i].times_called}
        key={i}
      ></Item>
    );
  }

    function checkAll(){
		if ($("#Checkbox").is(":checked")) {
         $(".checkboxContacts").prop("checked",true);
	    let fileID = $('input[name="slectedCheckBox"]:checked').map(function () {  
        return this.value;
        }).get().join(",");	
        $("#fileIds").val(fileID);
        $(".SelectAllMain.row .hidden-action").show();		
		} else {  $(".checkboxContacts").prop("checked",false);   $("#fileIds").val("");
        $(".SelectAllMain.row .hidden-action").hide();
		}
	}
    function showHiddenColumn() {
		$('.row.contacts_hidden_data').toggleClass( "showHidden" );
	}	

	function removeRecords(){
		
	}
  return (
    <Wrapper>

      <div className="scroll-in-mobile contacts">
	  <div className="SelectAllMain row"> 
        <div className="col-md-5 hidden-action">
          <span className="add_tags">
            {" "}
            <a href="javascript:void(0)" onClick={ (e) => props.addTags() }>+ Add Tag</a>
          </span>{" "}
          <span className="add_tags">
            {" "}
             <a href="javascript:void(0)" onClick={ (e) => props.removeTags() }> × Remove Tag </a>{" "}
          </span>
          {/*<span className="add_tags">
            {" "}
             <a href="javascript:void(0)" onClick={ (e) => removeRecords() } className="manipulatorDeleteRecords"> × Delete </a>{" "}
          </span>*/}		  
        </div>
	  </div>
	  <div className="row reports_listing">
	    <input type="hidden" name ="fileIds" id ="fileIds" value=""/>
	    <div className="listing_heading owner1"> <input type="Checkbox" id ="Checkbox" onClick ={checkAll} /> <span> Owner 1 </span> </div>
		<div className="listing_heading owner2"> <span> Owner 2 </span> </div>
		<div className="listing_heading addreses"> <span> Mailing Address </span> </div>
		<div className="listing_heading city"> <span> Mailing City </span> </div>
		<div className="listing_heading state"> <span> Mailing State </span> </div>
		<div className="listing_heading zip"> <span> Mailing Zip </span> 
			<ReactTooltip id={"expand_icon"} aria-haspopup='true' role='example'>
			  Expand
            </ReactTooltip>

		</div>
        <div className="action">			
		<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <a href="javascript:void(0)" onClick={(e)=>showHiddenColumn()} data-for={"expand_icon"}> <path d="M13.1558 0.177495C12.9192 -0.0591515 12.5354 -0.0591919 12.2987 0.177535L6.66681 5.80957L1.03461 0.177495C0.79796 -0.0591515 0.414202 -0.0591919 0.177515 0.177535C-0.0591717 0.414222 -0.0591717 0.797939 0.177515 1.03463L6.23828 7.09523C6.35194 7.20889 6.50608 7.27273 6.66681 7.27273C6.82753 7.27273 6.98172 7.20885 7.09533 7.09519L13.1558 1.03459C13.3925 0.797939 13.3925 0.414182 13.1558 0.177495Z" fill="black"></path></a></svg>	
		</div>		
	  </div>
	  {items}</div>
    </Wrapper>
  );
};
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`;
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`;
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
`;
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top: 14px;
`;
const HeadingDelete = styled.h2`
  color: ${COLORS.red};
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`;
const View = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.lightblue};
`;
export default Table;
