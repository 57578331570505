import React from "react";
import { CardElement,   } from "@stripe/react-stripe-js";
import {CardNumberElement, CardExpiryElement, CardCvcElement} from "@stripe/react-stripe-js"

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      boxShadow: "0px 4px 24px 0px #0000000d",
      height: "60px",
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.8)"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

function CardSection() {
  return (
    <label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />

      {/* <CardNumberElement options={CARD_ELEMENT_OPTIONS}/>
      <CardExpiryElement options={CARD_ELEMENT_OPTIONS}/>
      <CardCvcElement options={CARD_ELEMENT_OPTIONS}/> */}
    </label>
  );
}

export default CardSection;
