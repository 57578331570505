import React, { useState,useEffect } from "react"
import styled from "styled-components"
import COLORS from "../../../../utils/colors"
import Item from "./tableItem"
import { getUserID } from "../../../../utils/helper"
import { getUsersExcelData, deleteSelectedFile, downloadsFiles } from "../../../../services/dataValidation"
const Table = (props) => {
  const [selectedExceldata,setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [getExcelTotle, setExcelTotle] = useState([]);
  const [selectedExceldataSingle, setselectedExceldataSingle] = useState([]);
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);

  useEffect(() => {
    getFiles()
  }, [])

  function getFiles() {
    const user = getUserID()
    getUsersExcelData(
      {
        user_id: user,
      },
    )
      .then(function (response) {
        setExcelTotle(response.data.length)
      })
      .catch((error) => {
        console.log("error", error)
      })
  }
  
  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {  
    items.push(
      <Item
	    id ={files[i].id}
        name={files[i].file_name}
        updated_at={files[i].updated_at}
        created_at={files[i].created_at}
        key={i}
		>
        </Item>
    )
  }

  return (
    <Wrapper>
      <WrapperTitle>
        <HeadingFiles> <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.2" cx="18.5" cy="18.5" r="18" fill="#047CFF" stroke="#047CFF"/>
<path d="M11.3102 19.0362L19.9635 27.6894C20.1636 27.8897 20.4308 28 20.7157 28C21.0006 28 21.2677 27.8897 21.4679 27.6894L22.1051 27.0523C22.5198 26.6371 22.5198 25.9624 22.1051 25.5479L14.8387 18.2815L22.1132 11.007C22.3133 10.8067 22.4238 10.5397 22.4238 10.255C22.4238 9.96995 22.3133 9.70294 22.1132 9.50248L21.4759 8.86554C21.2756 8.66525 21.0086 8.5549 20.7237 8.5549C20.4389 8.5549 20.1717 8.66525 19.9715 8.86554L11.3102 17.5266C11.1096 17.7275 10.9994 17.9958 11 18.281C10.9994 18.5673 11.1096 18.8354 11.3102 19.0362Z" fill="#047CFF"/>
</svg>
Data Manipulator</HeadingFiles>
      </WrapperTitle>
      <div className="scroll-in-mobile">
	  <input type="hidden" name ="fileIds" id ="fileIds" value=""/>
      {items}
      </div>
    </Wrapper>
  )
}
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 20px;
`
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top:14px;
`
const HeadingDelete = styled.h2`
  color: ${COLORS.red};
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`
const View = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.lightblue};
`
export default Table
