import React, { useState,useEffect } from "react"
import styled from "styled-components"
import COLORS from "../../../../utils/colors"
import Item from "./tableItem"
import { getUserID } from "../../../../utils/helper"
import $ from 'jquery';
const Table = (props) => {
  const [selectedExceldata,setSelectedExceldata] = useState([]);
  const [selected, setSelected] = useState([]);
  const [getExcelTotle, setExcelTotle] = useState([]);
  const [selectedExceldataSingle, setselectedExceldataSingle] = useState([]);
  const [slectedBoxTrue, setslectedBoxTrue] = useState(false);  
  const files = props.files ? props.files : []
  const items = []
  for (let i = 0; i < files.length; i++) {   
 
    items.push(
      <Item
        address={files[i].address}
        city={files[i].city}
        state={files[i].state}
        zip={files[i].zip}
        key={i}
		id ={files[i].id}
		>
        </Item>
    )
  }

  return (
    <Wrapper>
      <WrapperTitle>
      </WrapperTitle>
      <div className="scroll-in-mobile dowloads">
	  {items.length >0 &&
	  <table className="listing download">
	 <tr className="heading">
	<th>Address</th>
	<th>City</th>
	<th>State</th>
	<th>Zip Code</th>
	</tr>
      {items}
	  </table> }
      </div>
    </Wrapper>
  )
}
const Delete = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.red};
  padding-left: 10px;
`
const Wrapper = styled.div`
  height: 100%;
  margin-top: 20px;
  z-index: 0;
`
const WrapperTitle = styled.div`
  display: flex;
  width: 96%;
  margin: auto;
  justify-content: space-between;
  position: relative;
  padding-bottom: 10px;
`
const HeadingFiles = styled.h2`
  font-weight: bold;
  color: #15342e;
  margin-left: 13px;
  font-family: roboto;
  font-weight: 700;
  font-size: 18px;
  opacity: 0.7;
  padding-top:14px;
`
const HeadingDelete = styled.h2`
  color: ${COLORS.red};
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
`
const View = styled.span`
  padding-right: 6px;
  text-decoration: underline;
  color: ${COLORS.lightblue};
`
export default Table
