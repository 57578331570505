import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { getCrmActions} from "./../../../../services/crm";
import Loading from "./../../../../components/loading";
import $ from 'jquery';
class Autocomplete2 extends Component {
  constructor(props) {
    super(props);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.state = {
      value:this.props.value,
      suggestions: [],
	  isLoading:false,
	  val:this.props.value?this.props.value:''
    };
  }
 


componentDidMount(){  
	this.onSuggestionsFetchRequested();
}
  renderTitle(state, val) {
	if(state.title!=null){  
    return (
        state.title.toLowerCase().indexOf(val.toLowerCase()) !== -1
    ); }
  }
  
onSuggestionsFetchRequested = () => {
	  let fileID= localStorage.getItem( "file_id"); 
	  let self = this;
	  self.setState({isLoading:true});
			getCrmActions(
			  {
				file_id: fileID,
				action: 'get-dropdown',
				dropdown_type : self.props.name,
				search : ""
			  }
			).then(function (response) {
				 self.setState({isLoading:false});
			if(response.data){			            
                 self.setState({
					  suggestions: response.data.option
			}); }
			  }); 
			  
  };
  
  render() {
let value = this.props.value?this.props.value:'';
    return (
      <div className="autocomplete-wrapper" id={this.props.id} data={this.props.name}>
	  <Loading show={this.state.isLoading} />
        <Autocomplete
          value={this.state.val}
          items={this.state.suggestions}
          getItemValue={item => item.title}
          shouldItemRender={this.renderTitle}
		  id={this.props.id}
		  className={this.props.id}
		  name={this.props.id}
          renderMenu={item => (
            <div className="dropdown">
              {item}
            </div>
          )}
          renderItem={(item, isHighlighted) =>
            <div className={`item ${isHighlighted ? 'selected-item' : ''}`}>
              {item.title}
            </div>
          }
          onChange={(event, val) => this.setState({ val })}
          onSelect={val => this.setState({ val })}
        />
      </div>
    );
  }
}

export default Autocomplete2;